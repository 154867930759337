export default function setTopData(total, type) {
  const data = total.sort((a, b) => {
    if (a.total > b.total) { return -1; }
    if (a.total < b.total) { return 1; }
    return 0;
  }).map((item) => item);

  const result = [];

  if (type) for (let i = 0; i < 10; i++) result.push(data[i]);
  else for (let i = data.length - 10; i < data.length; i++) result.push(data[i]);

  return result;
}
