import { decode } from 'string-encode-decode';

export default function userAuth() {
  let auth;

  if (!sessionStorage.getItem('app-web-user-access-token')) {
    return null;
  }

  const data = decode(sessionStorage.getItem('app-web-user-access-token'));

  auth = JSON.parse(data);

  return auth;
}
