import axios from 'axios';
import FileDownload from 'js-file-download';
import userAuth from '../../helpers/auth/userAuth';
import decodeCache from '../../helpers/cache/decode';

// configuration
const config = {
  method: '',
  url: '',
  data: '',
  headers: { Authorization: '' },
};

// consts
const initData = {
  results: [],
  loading: false,
};

const cors = process.env.REACT_APP_CORS;
const back_end_export = process.env.REACT_APP_BACK_END_EXPORT;

// types
const GET_B4B_EXPORT_SUCCESS = 'GET_B4B_EXPORT_SUCCESS';
const GET_B4B_EXPORT_ERROR = 'GET_B4B_EXPORT_ERROR';
const LOADING_B4B_EXPORT = 'LOADING_B4B_EXPORT';

// reducers
export default function b4bExportReducer(state = initData, action) {
  switch (action.type) {
    case GET_B4B_EXPORT_SUCCESS:
      return { ...state, results: action.payload, loading: false };
    case GET_B4B_EXPORT_ERROR:
      return { ...state, loading: false };
    case LOADING_B4B_EXPORT:
      return { ...state, loading: action.payload };
    default:
      return state;
  }
}

// actions
export const exportSectorsAction = (b4b, scope) => async (dispatch) => {
  const auth = userAuth();

  if (!auth) return;

  const analisis = decodeCache('app-web-analisis-id');

  config.method = 'get';
  config.headers.Authorization = `Bearer ${auth.access_token}`;

  const result = [];

  dispatch({ type: LOADING_B4B_EXPORT, payload: true });

  try {
    for (let i = 0; i < b4b.length; i++) {
      config.url = `${cors}${back_end_export}/wise-export/analysis/count/b4b/sector?analysisId=${analisis}&scope=${scope}&sectorId=${b4b[i].b4BSectorId}`;
      result.push({ ...b4b[i], data: await (await axios(config)).data });
    }

    dispatch({
      type: GET_B4B_EXPORT_SUCCESS,
      payload: result,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: GET_B4B_EXPORT_ERROR,
    });
  }
};

export const exportCSVAction = (id, name, scope) => async (dispatch) => {
  const auth = userAuth();

  if (!auth) return;

  const analisis = decodeCache('app-web-analisis-id');

  config.method = 'get';
  config.headers.Authorization = `Bearer ${auth.access_token}`;
  config.url = `${cors}${back_end_export}/wise-export/analysis/b4b/sector?analysisId=${analisis}&scope=${scope}&sectorId=${id}`;

  dispatch({ type: LOADING_B4B_EXPORT, payload: true });

  axios(config).then(
    (success) => {
      FileDownload(success.data, `${name}.csv`);
      dispatch({ type: LOADING_B4B_EXPORT, payload: false });
    }, (error) => {
      dispatch({ type: LOADING_B4B_EXPORT, payload: false });
    },
  );
};
