import React from 'react';
import './FilterCustomIdComponent.css';

const FilterCustomIdComponent = (props) => (
  <>
    <h3>Este filtro estará disponible en la siguiente versión</h3>
  </>
);

export default FilterCustomIdComponent;
