/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import './HistoricalComponent.css';
import { Modal } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { useSelector, useDispatch } from 'react-redux';
import { deleteHistoricalAnalysisById, getHistoricalAnalysisAction } from '../../../services/ducks/historical-analysis.duck';
import { resetAnalizerAction, setAnalyzerById } from '../../../services/ducks/analizer.duck';
import { changeOverLayersByAnalizer } from '../../../services/ducks/overlayers.duck';

const HistoricalComponent = () => {
  const dispatch = useDispatch();
  const [modalShow, setModalShow] = useState(false);
  const [deleteButton, setDeleteButton] = useState(false);
  const [deleteItems, setDeleteItems] = useState([]);

  const historical = useSelector((store) => store.historicalAnalysis.results);
  const general = useSelector((store) => store.historicalAnalysis.general);
  const result = useSelector((store) => store.analizer.results);
  const auth = useSelector((store) => store.auth.authorities);

  useEffect(() => {
    if (result.length > 0) {
      dispatch(changeOverLayersByAnalizer(result));
      dispatch(resetAnalizerAction());
    }
  }, [auth, dispatch, result]);

  const openHistoricals = () => {
    dispatch(getHistoricalAnalysisAction(auth));
    setModalShow(true);
  };

  const columns = [
    {
      name: 'Análisis',
      selector: 'analysisName',
      sortable: true,
    },
    {
      right: true,
      cell: (row) => action(row.wiseAnalysisId),
    },
  ];

  const action = (e) => (
    <div className="actions-datable">
      <button onClick={() => change(e)}><i className="ion-eye" /></button>
    </div>
  );

  const closeModal = () => {
    setModalShow(false);
    setDeleteItems([]);
    setDeleteButton(false);
  };

  const change = (id) => {
    dispatch(setAnalyzerById(id));
    const timer = setTimeout(() => {
      setModalShow(false);
    }, 1500);
    return () => clearTimeout(timer);
  };

  const remove = () => {
    if (deleteItems.length === 0) return;
    const count = deleteItems.selectedRows.length;
    for (let i = 0; i < count; i++) {
      dispatch(deleteHistoricalAnalysisById(deleteItems.selectedRows[i].wiseAnalysisId));
      if (i === count - 1) {
        const timer = setTimeout(() => {
          dispatch(setAnalyzerById(general));
          dispatch(getHistoricalAnalysisAction(auth));
          setDeleteButton(false);
        }, 3000);
        return () => clearTimeout(timer);
      }
    }
  };

  const paginationOptions = {
    rowsPerPageText: 'Resultados por página:',
    rangeSeparatorText: 'de',
    noRowsPerPage: false,
    selectAllRowsItem: false,
    selectAllRowsItemText: 'Todo',
  };

  const selectedRows = (e) => {
    if (e.selectedCount > 0) {
      setDeleteButton(true);
      setDeleteItems(e);
    } else setDeleteButton(false);
  };

  return (
    <>
      <li className="activities-item">
        <a onClick={() => openHistoricals()}>
          {' '}
          <i className="ion-android-time" />
          {' '}
        </a>
      </li>
      <Modal className="ewisemaps-lg-modal modal-historical-analisis" size="lg" show={modalShow} onHide={() => closeModal()} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <i className="ion-android-time" />
            {' '}
            Historico de Análisis
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="datatable-actions">
            {
                            general && (<button onClick={() => change(general)}>Restaurar</button>)
                        }
            {
                            deleteButton && (<button onClick={() => remove()}>Eliminar</button>)
                        }
          </div>
          <div className="datatable-historical-analisis">
            <DataTable
              columns={columns}
              data={historical}
              pagination
              striped
              noDataComponent="No hay ningún análisis hasta ahora"
              paginationRowsPerPageOptions={[5, 10]}
              paginationComponentOptions={paginationOptions}
              noHeader
              selectableRows
              selectableRowsHighlight
              onSelectedRowsChange={(e) => selectedRows(e)}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default HistoricalComponent;
