import axios from 'axios';

const cors = process.env.REACT_APP_CORS;
const canceled = process.env.REACT_APP_CANCELED_ID;

const config = {
  method: '',
  url: '',
  data: '',
  headers: { Authorization: '' },
};

export default async function getAnalisisGeneral(token, username, layer, role) {
  let res;
  let validate;
  config.method = 'get';
  config.headers.Authorization = `Bearer ${token}`;
  config.url = `${cors}${process.env.REACT_APP_BACK_END_ANALIZER}/wise-analyzer/analysis/layer/${layer.layerId}/${username}`;

  res = await (await axios(config)).data;
  validate = getGeneral(res);

  if (!validate) {
    config.url = `${cors}${process.env.REACT_APP_BACK_END_ANALIZER}/wise-analyzer/analysis/shared/${layer.layerId}/${username}`;
    res = await (await axios(config)).data;
    return getId(res, role);
  }
  return getId(res, role);
}

const getGeneral = (analisis) => {
  const validate = analisis.filter((item) => item.general);
  if (validate.length > 0) return true;
  return false;
};

const getId = (analisis, role) => {
  if (role === 'ROLE_SALES') {
    return canceled;
  }
  const id = analisis.filter((item) => item.general);
  return id[0].wiseAnalysisId;
};
