import React, { useEffect } from 'react';
import './ReportComponent.css';
import { useSelector } from 'react-redux';
import { Route, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import MenuComponent from '../../templates/MenuComponent/MenuComponent';
import ChartCustomStringComponent from '../../templates/ChartCustomStringComponent/ChartCustomStringComponent';
import ChartCustomDateComponent from '../../templates/ChartCustomDateComponent/ChartCustomDateComponent';
import InitComponent from '../../templates/InitComponent/InitComponent';
import ChartsCustomSocioeconomicalsComponent from '../../templates/ChartCustomSocioeconomicalsComponent/ChartCustomSocioeconomicalsComponent';
import ChartCustomB4BComponent from '../../templates/ChartCustomB4BComponent/ChartCustomB4BComponent';
import ChartCustomDemographicComponent from '../../templates/ChartCustomDemographicComponent/ChartCustomDemographicComponent';
import decodeCache from '../../../helpers/cache/decode';
import userAuth from '../../../helpers/auth/userAuth';

const ReportComponent = (props) => {
  const params = useSelector((store) => store.paramsByOrganization.results);
  const socioeconomicals = useSelector(
    (store) => store.filtersSocioeconomicalByParams.results,
  );
  const demographics = useSelector((store) => store.demographicMexico.results);
  const authentication = userAuth(userAuth);

  useEffect(() => {
    if (!authentication) props.history.push('/');
    if (!decodeCache('app-web-analisis-id')) props.history.push('/main');
  }, [authentication, props, props.history]);

  return (
    <>
      <InitComponent />
      <MenuComponent />
      <section id="app-analytic-section">
        {params.map((item) => (
          <div key={item.id}>
            <h1 className="title">{item.name}</h1>
            <div className="app-cards">
              {socioeconomicals.map((soc) => (
                <ChartsCustomSocioeconomicalsComponent
                  key={soc.id}
                  data={soc}
                />
              ))}
            </div>
            <div className="app-cards">
              {item.params
                .sort((a, b) => {
                  if (a.name < b.name) {
                    return -1;
                  }
                  if (a.name > b.name) {
                    return 1;
                  }
                  return 0;
                })
                .map(
                  (subitem) => subitem.id !== 'custom_var_one'
                    && subitem.type === 'string' && (
                      <ChartCustomStringComponent
                        key={subitem.id}
                        data={subitem}
                      />
                  ),
                )}
              {item.params
                .sort((a, b) => {
                  if (a.name < b.name) {
                    return -1;
                  }
                  if (a.name > b.name) {
                    return 1;
                  }
                  return 0;
                })
                .map(
                  (subitem) => subitem.id !== 'custom_var_one'
                    && subitem.type === 'timestamp' && (
                      <ChartCustomDateComponent
                        key={subitem.id}
                        data={subitem}
                      />
                  ),
                )}
            </div>
          </div>
        ))}
        <h1 className="title">Población</h1>
        <div className="app-cards">
          {demographics
            .sort((a, b) => {
              if (a.catName < b.catName) {
                return -1;
              }
              if (a.catName > b.catName) {
                return 1;
              }
              return 0;
            })
            .map((item) => (
              <ChartCustomDemographicComponent key={item.id} data={item} />
            ))}
        </div>
        <h1 className="title">Empresas</h1>
        <div className="app-cards">
          <ChartCustomB4BComponent />
        </div>
      </section>
    </>
  );
};

ReportComponent.propTypes = {
  history: PropTypes.objectOf(Route).isRequired,
};

export default withRouter(ReportComponent);
