import axios from 'axios';
import userAuth from '../../helpers/auth/userAuth';

// configuration
const config = {
  method: '',
  url: '',
  data: '',
  headers: { Authorization: '' },
};

// consts
const initData = {
  results: [],
  error: false,
  loading: false,
};

const cors = process.env.REACT_APP_CORS;
const back_end_data = process.env.REACT_APP_BACK_END_DATA;

// types
const GET_B4B_MEXICO_SECTORS_SUCCESS = 'GET_B4B_MEXICO_SECTORS_SUCCESS';
const GET_B4B_MEXICO_SECTORS_ERROR = 'GET_B4B_MEXICO_SECTORS_ERROR';

// reducers
export default function b4bMexicoReducer(state = initData, action) {
  switch (action.type) {
    case GET_B4B_MEXICO_SECTORS_SUCCESS:
      return {
        ...state, results: action.payload, error: false, loading: false,
      };
    case GET_B4B_MEXICO_SECTORS_ERROR:
      return { ...state, error: true, loading: false };
    default:
      return state;
  }
}

// actions
export const getSectorsAction = () => async (dispatch) => {
  const auth = userAuth();

  if (!auth) return;

  config.method = 'get';
  config.headers.Authorization = `Bearer ${auth.access_token}`;
  config.url = `${cors}${back_end_data}/b4b/sectors`;

  try {
    const result = await (await axios(config)).data;
    dispatch({
      type: GET_B4B_MEXICO_SECTORS_SUCCESS,
      payload: result,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: GET_B4B_MEXICO_SECTORS_ERROR,
    });
  }
};
