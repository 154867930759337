/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import './MapPanelMobileComponent.css'
import { Modal } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import { getTabsMapPanelAction } from '../../../services/ducks/featureinfo.duck'
import BasemapsComponent from '../BasemapsComponent/BasemapsComponent'
import OverlayersComponent from '../OverlayersComponent/OverlayersComponent'

const MapPanelMobileComponent = () => {

    const dispatch = useDispatch()

    const key = useSelector(store => store.featureinfo.tab)

    const [modalShow, setModalShow] = useState(false)

    const setModalMapPanel = (x, y) => {
        setModalShow(x)
        dispatch(getTabsMapPanelAction(y))
    }

    return (
        <>
            <li className="activities-item map-panel mobile">
                <a onClick={() => setModalMapPanel(true, 'maps')}> <i className="ion-map"></i> </a>
            </li>
            <li className="activities-item map-panel mobile">
                <a onClick={() => setModalMapPanel(true, 'layers')}> <i className="fas fa-layer-group"></i> </a>
            </li>
            <Modal className="modal-map-panel-mobile" show={modalShow} onHide={() => setModalMapPanel(false, 'layers')} centered>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {key === 'maps' && 'Mapas'}
                        {key === 'layers' && 'Capas'}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                        {key === 'maps' && (<BasemapsComponent></BasemapsComponent>)}
                        {key === 'layers' && (<OverlayersComponent></OverlayersComponent>)}
                </Modal.Body>
            </Modal>
        </>
    )
}

export default MapPanelMobileComponent
