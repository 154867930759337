/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import './ForgotPasswordComponent.css';
import { useDispatch, useSelector } from 'react-redux';
import { signinShowAction } from '../../../services/ducks/home-action.duck';
import { sendEmailForgotPasswordAction } from '../../../services/ducks/forgot-password.duck';
import logo from '../../../assets/oeste_logo.jpg';

const ForgotPasswordComponent = () => {
  const dispatch = useDispatch();
  const message = useSelector((store) => store.forgotPassword.message);

  const [user, setUser] = useState('');

  const forgorForm = (e) => {
    e.preventDefault();
    if (!user.trim()) return;
    dispatch(sendEmailForgotPasswordAction(user));
  };

  return (
    <section id="ewisemaps-forgot-password">
      <div className="form">
        <h1>Recuperar contraseña</h1>
        {
                    message && (
                    <span>
                      {message}
                    </span>
                    )
                }
        <form onSubmit={forgorForm}>
          <div className="form-item">
            <input type="email" placeholder="escribe tu correo electrónico" value={user} onChange={(e) => setUser(e.target.value)} required />
          </div>
          <div className="form-item">
            <button type="submit">Enviar</button>
          </div>
        </form>
        <div className="options">
          <a onClick={() => dispatch(signinShowAction())}>¡Ya la recordé! Quiero iniciar sesión</a>
        </div>
        <div className="legend">
          <img src={logo} alt="Organización de Estudios del Territorio S.A. de C.V." />
          <br />
          <span>
            Desarrollado por
            {' '}
            <a href="https://oeste.xyz/">Organización de Estudios del Territorio S.A. de C.V.</a>
          </span>
        </div>
      </div>
    </section>
  );
};

export default ForgotPasswordComponent;
