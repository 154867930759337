const geoserver = process.env.REACT_APP_GEOSERVER;

export default function res() {
  return [
    {
      name: 'División Política de México',
      layers: [
        {
          server: `${geoserver}`,
          workspace: 'wise',
          service: 'wms',
          id: 'wise_mex_estados',
          layer: 'wise_mex_estados',
          name: 'Estados de México',
          visible: false,
          version: '1.1.1',
          style: 'estados_republica_mexicana',
          viewparams: '',
          srs: 'EPSG:4326',
        },
        {
          server: `${geoserver}`,
          workspace: 'wise',
          service: 'wms',
          id: 'wise_mex_municipios',
          layer: 'wise_mex_municipios',
          name: 'Municipios de México',
          visible: false,
          version: '1.1.1',
          style: 'municipios_republica_mexicana',
          viewparams: '',
          srs: 'EPSG:4326',
        },
        {
          server: `${geoserver}`,
          workspace: 'wise',
          service: 'wms',
          id: 'wise_mex_colonias',
          layer: 'wise_mex_colonias',
          name: 'Colonias de México',
          visible: false,
          version: '1.1.1',
          style: 'colonias_republica_mexicana',
          viewparams: '',
          srs: 'EPSG:4326',
        },
      ],
    },
    {
      name: 'Niveles Socioeconómicos',
      layers: [
        {
          server: `${geoserver}`,
          workspace: 'wise',
          service: 'wms',
          id: 'geotarget',
          layer: 'geotarget',
          name: 'geoTarget360',
          visible: false,
          version: '1.1.1',
          style: 'geotarget360',
          viewparams: '',
          srs: 'EPSG:4326',
        },
        {
          server: `${geoserver}`,
          workspace: 'wise',
          service: 'wms',
          id: 'amai',
          layer: 'amai',
          name: 'NSE/AMAI',
          visible: false,
          version: '1.1.1',
          style: 'nse_amai',
          viewparams: '',
          srs: 'EPSG:4326',
        },
      ],
    },
    {
      name: 'Cobertura ADT',
      layers: [
        {
          server: `${geoserver}`,
          workspace: 'wise',
          service: 'wms',
          id: 'wise_cobertura_adt',
          layer: 'wise_cobertura_adt',
          name: 'Botón de Emergencia',
          visible: false,
          version: '1.1.1',
          style: 'geotarget360',
          viewparams: '',
          srs: 'EPSG:4326',
        },
        {
          server: `${geoserver}`,
          workspace: 'wise',
          service: 'wms',
          id: 'adt_zona_venta_municipio',
          layer: 'adt_zona_venta_municipio',
          name: 'Zonas de Venta ADT',
          visible: false,
          version: '1.1.1',
          style: 'geotarget360',
          viewparams: '',
          srs: 'EPSG:4326',
        }
      ]
    },{
      name: 'Potencial ADT (Mckinsey)',
      layers: [
        {
          server: `${geoserver}`,
          workspace: 'adt',
          service: 'wms',
          id: 'mckinsey_potential',
          layer: 'mckinsey_potential',
          name: 'Potencial ADT (Mckinsey)',
          visible: false,
          version: '1.1.1',
          style: 'geotarget360',
          viewparams: '',
          srs: 'EPSG:4326',
        }
      ]
    },
    {
      name: 'Zonas Potenciales',
      layers: [
        {
          server: `${geoserver}`,
          workspace: 'wise',
          service: 'wms',
          id: 'potential_geo_target',
          layer: 'potential_geo_target',
          name: 'Potencial geoTarget360',
          visible: false,
          version: '1.1.1',
          style: 'geotarget360',
          viewparams: '',
          srs: 'EPSG:4326',
        },
        {
          server: `${geoserver}`,
          workspace: 'wise',
          service: 'wms',
          id: 'potential_amai',
          layer: 'potential_amai',
          name: 'Potencial NSE/AMAI',
          visible: false,
          version: '1.1.1',
          style: 'nse_amai',
          viewparams: '',
          srs: 'EPSG:4326',
        },
      ],
    },
    {
      name: 'Sectores Laborales',
      layers: [
        {
          server: `${geoserver}`,
          workspace: 'wise',
          service: 'wms',
          id: 'geob4b_12',
          layer: 'geob4b_12',
          name: 'Actividades legislativas, gubernamentales, de impartición de justicia y de organismos internacionales y extraterritoriales',
          visible: false,
          version: '1.1.1',
          style: 'geob4b_gubernamentales',
          viewparams: '',
          srs: 'EPSG:4326',
        },
        {
          server: `${geoserver}`,
          workspace: 'wise',
          service: 'wms',
          id: 'geob4b_4',
          layer: 'geob4b_4',
          name: 'Agricultura, cría y explotación de animales, aprovechamiento forestal, pesca y caza',
          visible: false,
          version: '1.1.1',
          style: 'geob4b_agricultura',
          viewparams: '',
          srs: 'EPSG:4326',
        },
        {
          server: `${geoserver}`,
          workspace: 'wise',
          service: 'wms',
          id: 'geob4b_15',
          layer: 'geob4b_15',
          name: 'Comercio al por mayor',
          visible: false,
          version: '1.1.1',
          style: 'geob4b_comercio_mayor',
          viewparams: '',
          srs: 'EPSG:4326',
        },
        {
          server: `${geoserver}`,
          workspace: 'wise',
          service: 'wms',
          id: 'geob4b_18',
          layer: 'geob4b_18',
          name: 'Comercio al por menor',
          visible: false,
          version: '1.1.1',
          style: 'geob4b_comercio_menor',
          viewparams: '',
          srs: 'EPSG:4326',
        },
        {
          server: `${geoserver}`,
          workspace: 'wise',
          service: 'wms',
          id: 'geob4b_11',
          layer: 'geob4b_11',
          name: 'Construcción',
          visible: false,
          version: '1.1.1',
          style: 'geob4b_construccion',
          viewparams: '',
          srs: 'EPSG:4326',
        },
        {
          server: `${geoserver}`,
          workspace: 'wise',
          service: 'wms',
          id: 'geob4b_9',
          layer: 'geob4b_9',
          name: 'Corporativos',
          visible: false,
          version: '1.1.1',
          style: 'geob4b_coorporativos',
          viewparams: '',
          srs: 'EPSG:4326',
        },
        {
          server: `${geoserver}`,
          workspace: 'wise',
          service: 'wms',
          id: 'geob4b_17',
          layer: 'geob4b_17',
          name: 'Generación, transmisión, distribución y comercialización de energía eléctrica, suministro de agua y de gas natural por ductos al consumidor final',
          visible: false,
          version: '1.1.1',
          style: 'geob4b_energia',
          viewparams: '',
          srs: 'EPSG:4326',
        },
        {
          server: `${geoserver}`,
          workspace: 'wise',
          service: 'wms',
          id: 'geob4b_16',
          layer: 'geob4b_16',
          name: 'Industrias manufactureras',
          visible: false,
          version: '1.1.1',
          style: 'geob4b_manufacturera',
          viewparams: '',
          srs: 'EPSG:4326',
        },
        {
          server: `${geoserver}`,
          workspace: 'wise',
          service: 'wms',
          id: 'geob4b_20',
          layer: 'geob4b_20',
          name: 'Información en medios masivos',
          visible: false,
          version: '1.1.1',
          style: 'geob4b_medios_masivos',
          viewparams: '',
          srs: 'EPSG:4326',
        },
        {
          server: `${geoserver}`,
          workspace: 'wise',
          service: 'wms',
          id: 'geob4b_14',
          layer: 'geob4b_14',
          name: 'Minería',
          visible: false,
          version: '1.1.1',
          style: 'geob4b_mineria',
          viewparams: '',
          srs: 'EPSG:4326',
        },
        {
          server: `${geoserver}`,
          workspace: 'wise',
          service: 'wms',
          id: 'geob4b_3',
          layer: 'geob4b_3',
          name: 'Otros servicios excepto actividades gubernamentales',
          visible: false,
          version: '1.1.1',
          style: 'geob4b_otros_servicios',
          viewparams: '',
          srs: 'EPSG:4326',
        },
        {
          server: `${geoserver}`,
          workspace: 'wise',
          service: 'wms',
          id: 'geob4b_21',
          layer: 'geob4b_21',
          name: 'Servicios de alojamiento temporal y de preparación de alimentos y bebidas',
          visible: false,
          version: '1.1.1',
          style: 'geob4b_hosteleria',
          viewparams: '',
          srs: 'EPSG:4326',
        },
        {
          server: `${geoserver}`,
          workspace: 'wise',
          service: 'wms',
          id: 'geob4b_8',
          layer: 'geob4b_8',
          name: 'Servicios de apoyo a los negocios y manejo de residuos, y servicios de remediación',
          visible: false,
          version: '1.1.1',
          style: 'geob4b_manejo_residuos',
          viewparams: '',
          srs: 'EPSG:4326',
        },
        {
          server: `${geoserver}`,
          workspace: 'wise',
          service: 'wms',
          id: 'geob4b_2',
          layer: 'geob4b_2',
          name: 'Servicios de esparcimiento culturales y deportivos, y otros servicios recreativos',
          visible: false,
          version: '1.1.1',
          style: 'geob4b_deportivos',
          viewparams: '',
          srs: 'EPSG:4326',
        },
        {
          server: `${geoserver}`,
          workspace: 'wise',
          service: 'wms',
          id: 'geob4b_1',
          layer: 'geob4b_1',
          name: 'Servicios de salud y de asistencia social',
          visible: false,
          version: '1.1.1',
          style: 'geob4b_salud',
          viewparams: '',
          srs: 'EPSG:4326',
        },
        {
          server: `${geoserver}`,
          workspace: 'wise',
          service: 'wms',
          id: 'geob4b_19',
          layer: 'geob4b_19',
          name: 'Servicios educativos',
          visible: false,
          version: '1.1.1',
          style: 'geob4b_education',
          viewparams: '',
          srs: 'EPSG:4326',
        },
        {
          server: `${geoserver}`,
          workspace: 'wise',
          service: 'wms',
          id: 'geob4b_5',
          layer: 'geob4b_5',
          name: 'Servicios financieros y de seguros',
          visible: false,
          version: '1.1.1',
          style: 'geob4b_financieros',
          viewparams: '',
          srs: 'EPSG:4326',
        },
        {
          server: `${geoserver}`,
          workspace: 'wise',
          service: 'wms',
          id: 'geob4b_10',
          layer: 'geob4b_10',
          name: 'Servicios inmobiliarios y de alquiler de bienes muebles e intangibles',
          visible: false,
          version: '1.1.1',
          style: 'geob4b_inmobiliaria',
          viewparams: '',
          srs: 'EPSG:4326',
        },
        {
          server: `${geoserver}`,
          workspace: 'wise',
          service: 'wms',
          id: 'geob4b_6',
          layer: 'geob4b_6',
          name: 'Servicios profesionales, científicos y técnicos',
          visible: false,
          version: '1.1.1',
          style: 'geob4b_servicios_profesionales',
          viewparams: '',
          srs: 'EPSG:4326',
        },
        {
          server: `${geoserver}`,
          workspace: 'wise',
          service: 'wms',
          id: 'geob4b_13',
          layer: 'geob4b_13',
          name: 'Transportes, correos y almacenamiento',
          visible: false,
          version: '1.1.1',
          style: 'geob4b_correo',
          viewparams: '',
          srs: 'EPSG:4326',
        },
      ],
    },
  ];
}
