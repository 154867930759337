/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import './LoginComponent.css';
import { useDispatch, useSelector } from 'react-redux';
import { signInAction } from '../../../services/ducks/auth.duck';
import { forgotShowAction } from '../../../services/ducks/home-action.duck';
import logo from '../../../assets/oeste_logo.jpg';

const LoginComponent = () => {
  const dispatch = useDispatch();

  const message = useSelector((store) => store.auth.message);

  const [user, setUser] = useState('');
  const [password, setPassword] = useState('');

  const authForm = (e) => {
    e.preventDefault();
    if (!user.trim() || !password.trim()) {
    } else {
      dispatch(signInAction(user, password));
    }
  };

  return (
    <section id="ewisemaps-login">
      <div className="form">
        <h1>Acceder a la Plataforma</h1>
        {message && <span>{message}</span>}
        <form onSubmit={authForm}>
          <div className="form-item">
            <input
              type="text"
              placeholder="username"
              value={user}
              onChange={(e) => setUser(e.target.value)}
              required
            />
          </div>
          <div className="form-item">
            <input
              type="password"
              placeholder="contraseña"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <div className="form-item">
            <button type="submit">Ingresar</button>
          </div>
        </form>
        <div className="options">
          <a onClick={() => dispatch(forgotShowAction())}>
            ¿Olvidaste tu contraseña?
          </a>
        </div>
        <div className="legend">
          <img
            src={logo}
            alt="Organización de Estudios del Territorio S.A. de C.V."
          />
          <br />
          <span>
            Desarrollado por
            <a href="https://oeste.xyz/">
              Organización de Estudios del Territorio S.A. de C.V.
            </a>
          </span>
        </div>
      </div>
    </section>
  );
};

export default LoginComponent;
