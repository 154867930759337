import axios from 'axios';
import encodeCache from '../../helpers/cache/encode';
import decodeCache from '../../helpers/cache/decode';
import userAuth from '../../helpers/auth/userAuth';

// configuration
const config = {
  method: '',
  url: '',
  data: '',
  headers: { Authorization: '' },
};

// consts
const initData = {
  colonies: [],
  colonies_selected: [],
  loading: false,
};

const cors = process.env.REACT_APP_CORS;
const back_end_data = process.env.REACT_APP_BACK_END_DATA;

// types
const GET_COLONIES_BY_STATE_SUCCESS = 'GET_FILTER_COLONIES_SUCCESS';
const GET_COLONIES_BY_STATE_ERROR = 'GET_FILTER_COLONIES_ERROR';
const GET_SELECTED_COLONIES_SUCCESS = 'GET_FILTER_COLONIES_SUCCESS';
const GET_SELECTED_COLONIES_ERROR = 'GET_FILTER_COLONIES_ERROR';
const LOADING_POLITICAL_ENTITIES_COLONIES = 'LOADING_POLITICAL_ENTITIES_COLONIES';
const GET_IDS_BY_COLONIES_SUCCESS = 'GET_IDS_BY_COLONIES_SUCCESS';
const GET_IDS_BY_COLONIES_ERROR = 'GET_IDS_BY_COLONIES_ERROR';
const GET_IDS_BY_STATES_SUCCESS = 'GET_FILTER_STATES_SUCCESS';
const GET_IDS_BY_STATES_ERROR = 'GET_FILTER_STATES_ERROR';
const GET_IDS_BY_MUNICIPALITIES_SUCCESS = 'GET_IDS_BY_MUNICIPALITIES_SUCCESS';
const GET_IDS_BY_MUNICIPALITIES_ERROR = 'GET_IDS_BY_MUNICIPALITIES_ERROR';

// reducers
export default function filtersPolicitalEntitiesMexicoColoniesReducer(state = initData, action) {
  switch (action.type) {
    case GET_COLONIES_BY_STATE_SUCCESS:
      return { ...state, ...action.payload, loading: false };
    case GET_COLONIES_BY_STATE_ERROR:
      return { ...initData };
    case GET_SELECTED_COLONIES_SUCCESS:
      return { ...state, ...action.payload, loading: false };
    case GET_SELECTED_COLONIES_ERROR:
      return { ...initData };
    case GET_IDS_BY_COLONIES_SUCCESS:
      return { ...state, ...action.payload, loading: false };
    case GET_IDS_BY_COLONIES_ERROR:
      return { ...state, loading: false };
    case GET_IDS_BY_STATES_SUCCESS:
      return { ...state, ...action.payload, loading: false };
    case GET_IDS_BY_STATES_ERROR:
      return { ...initData };
    case GET_IDS_BY_MUNICIPALITIES_SUCCESS:
      return { ...state, ...action.payload, loading: false };
    case GET_IDS_BY_MUNICIPALITIES_ERROR:
      return { ...state, loading: false };
    case LOADING_POLITICAL_ENTITIES_COLONIES:
      return { ...state, loading: true };
    default:
      return state;
  }
}

// actions
export const getColoniesByMunicipality = (id) => async (dispatch) => {
  const auth = userAuth();

  if (!auth) return;

  config.method = 'get';
  config.url = `${cors}${back_end_data}/political/neighborhood/${id}`;
  config.headers.Authorization = `Bearer ${auth.access_token}`;

  dispatch({ type: LOADING_POLITICAL_ENTITIES_COLONIES });

  try {
    const res = await (await axios(config)).data;
    const result = { colonies: res };
    dispatch({
      type: GET_COLONIES_BY_STATE_SUCCESS,
      payload: result,
    });
  } catch (error) {
    dispatch({ type: GET_COLONIES_BY_STATE_ERROR });
  }
};

export const getSelectedColoniesAction = () => (dispatch) => {
  if (!decodeCache('app-web-filters-mexico-political-entities-colonies')) return;
  const result = decodeCache('app-web-filters-mexico-political-entities-colonies');
  dispatch({
    type: GET_SELECTED_COLONIES_SUCCESS,
    payload: result,
  });
};

export const removeColoniesAction = (id) => (dispatch) => {
  const resp = decodeCache('app-web-filters-mexico-political-entities-colonies');
  const result = {
    colonies_selected: resp.colonies_selected.filter((item) => item.id !== id),
  };
  dispatch({
    type: GET_SELECTED_COLONIES_SUCCESS,
    payload: result,
  });
  encodeCache('app-web-filters-mexico-political-entities-colonies', result);
};

export const addColoniesAction = (item) => (dispatch) => {
  dispatch({ type: LOADING_POLITICAL_ENTITIES_COLONIES });

  if (decodeCache('app-web-filters-mexico-political-entities-colonies')) {
    const result = decodeCache('app-web-filters-mexico-political-entities-colonies');
    const index = result.colonies_selected.findIndex((data) => data.id === item.objectid);
    if (index !== -1) return;
    result.colonies_selected.push({ id: item.objectid, name: `${item.settName}, ${item.munName}, ${item.stName}` });
    dispatch({
      type: GET_SELECTED_COLONIES_SUCCESS,
      payload: result,
    });
    encodeCache('app-web-filters-mexico-political-entities-colonies', result);
    return;
  }

  const result = { colonies_selected: [] };
  result.colonies_selected.push({ id: item.objectid, name: `${item.settName}, ${item.munName}, ${item.stName}` });
  dispatch({
    type: GET_SELECTED_COLONIES_SUCCESS,
    payload: result,
  });
  encodeCache('app-web-filters-mexico-political-entities-colonies', result);
};
