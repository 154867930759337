/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import './FilterComponent.css';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import FilterActionsComponent from '../FilterActionsComponent/FilterActionsComponent';
import FilterParamsComponent from '../FilterParamsComponent/FilterParamsComponent';
import { resetAnalizerAction } from '../../../services/ducks/analizer.duck';
import { changeOverLayersByAnalizer } from '../../../services/ducks/overlayers.duck';

const FilterComponent = () => {
  const dispatch = useDispatch();
  const [modalShow, setModalShow] = useState(false);

  const result = useSelector((store) => store.analizer.results);

  useEffect(() => {
    if (result.length > 0) {
      dispatch(resetAnalizerAction());
      dispatch(changeOverLayersByAnalizer(result));
      setModalShow(false);
    }
  }, [dispatch, result]);

  return (
    <>
      <li className="activities-item">
        <a onClick={() => setModalShow(true)}>
          {' '}
          <i className="ion-android-funnel" />
          {' '}
        </a>
      </li>
      <Modal className="ewisemaps-lg-modal filter-modal" show={modalShow} onHide={() => setModalShow(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <i className="ion-android-funnel" />
            {' '}
            Filtros
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FilterParamsComponent />
          <FilterActionsComponent />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default (FilterComponent);
