/* eslint-disable no-unused-vars */
import React from 'react'
import { Dropdown } from 'react-bootstrap'
import { jsPDF } from 'jspdf'
import 'jspdf-autotable'
import cover from '../../../assets/images/report/cover-ewisemaps.jpg'
import template from '../../../assets/images/report/page-ewisemaps.jpg'
import hexRgb from 'hex-rgb'
import { useSelector, useDispatch } from 'react-redux'
import chartGetIds from '../../../helpers/charts/chartGetIds'
import chartRender from '../../../helpers/charts/chartRender'
import { loadingReport } from '../../../services/ducks/print-report.duck'
import setSizeChart from '../../../helpers/reports/setSizeChart'
import setTopData from '../../../helpers/reports/setTopData'
import setLocation from '../../../helpers/reports/setLocation'
import { setPlacesPoliticalLevelsAction } from '../../../services/ducks/filters-political-entities-mexico-level.duck'
import decodeCache from '../../../helpers/cache/decode'

const PrintReportBasicComponent = () => {

    const dispatch = useDispatch()

    const dto = useSelector(store => store.analizer.dto)
    const namedto = useSelector(store => store.analizer.name)
    

    const formatToTable = (data) => {
        return data.map(item => ([item.name, item.format]))
    }
    
    const getRgb = (hex) => {
        let rgba = hexRgb(hex, {format: 'array'})
        return [rgba[0], rgba[1], rgba[2]]
    }

    const setPadding = (total) => {
        if(total < 11) return 5
        if(total > 10 && total < 20) return 2
    }

    const generatePDF = async(total) => {

        let places = decodeCache('app-web-places')

        let data = []
        for (var i = 0; i < total.length; i++) {
            data.push(await chartRender(total[i].id))
        }

        let doc = new jsPDF('l', 'mm', [355, 215])
        doc.page=1

        doc.addImage(cover, 'JPEG', 0, 0, 355, 215)
        doc.setFont('helvetica')
        doc.setTextColor(85,85,85)
        doc.setFontSize(14)
        doc.text(210, 70, `${namedto}`)

        doc.addPage()
        doc.addImage(template, 'JPEG', 0, 0, 355, 215)
        doc.setFont('helvetica')
        let verticalOffset = 40
        let lines = doc.setFont('helvetica')
            .setFontSize(14)
            .splitTextToSize(setLocation(places), 300)
        doc.text(30, verticalOffset + 12 / 10, lines)
        verticalOffset += lines.length  * 12
        doc.setFontSize(13)
        doc.setTextColor(255,255,255)
        doc.text(334, 205, `${doc.page}`)
        doc.page ++ 
    

        for(let i = 0; i < data.length; i++) {
            if(data[i] !== '') {
                doc.addPage()
                doc.addImage(template, 'JPEG', 0, 0, 355, 215)
                doc.addImage(data[i], 'JPEG', ...setSizeChart(total[i].size))
                doc.setFont('helvetica')
                doc.setFontSize(13)
                doc.setTextColor(255,255,255)
                doc.text(334, 205, `${doc.page}`)
                doc.page ++ 

                doc.addPage()
                doc.addImage(template, 'JPEG', 0, 0, 355, 215)
                doc.setFont('helvetica')
                doc.setFontSize(16)
                doc.setTextColor(85,85,85)
                doc.text(30, 25, `${total[i].name}`)

                if(total[i].data.length < 21 ) {
                    doc.autoTable({
                        startY: 30,
                        finalY: 60,
                        styles: { cellPadding: 0.5, fontSize: 12 },
                        margin: { left: 50 , right: 50},
                        headStyles: { halign: 'right', fillColor: getRgb(total[i].color), cellPadding: 3 },
                        head: [{id: '', total: 'Total'}],
                        body: formatToTable(total[i].data),
                            columnStyles: {
                            id: { cellPadding : setPadding(total[i].data.length) },
                            total: { halign: 'right', cellPadding : setPadding(total[i].data.length) }
                        }
                    })
                } else {
                    let topMax = setTopData(total[i].data, true)
                    let topMin = setTopData(total[i].data, false)

                    doc.autoTable({
                        startY: 35,
                        finalY: 60,
                        styles: { cellPadding: 0.5, fontSize: 12 },
                        margin: { left: 30 , right: 180},
                        headStyles: { halign: 'right', fillColor: getRgb(total[i].color), cellPadding: 2 },
                        head: [{id: 'Top 10 con más resultados', total: 'Total'}],
                        body: formatToTable(topMax),
                            columnStyles: {
                            id: { cellPadding : 1 },
                            total: { halign: 'right', cellPadding : 3 }
                        }
                    })

                    doc.autoTable({
                        startY: 35,
                        finalY: 60,
                        styles: { cellPadding: 0.5, fontSize: 12 },
                        margin: { left: 180 , right: 30},
                        headStyles: { halign: 'right', fillColor: getRgb(total[i].color), cellPadding: 2 },
                        head: [{id: 'Top 10 con menos resultados', total: 'Total'}],
                        body: formatToTable(topMin),
                            columnStyles: {
                            id: { cellPadding : 1 },
                            total: { halign: 'right', cellPadding : 3 }
                        }
                    })
                }

                doc.setFontSize(13)
                doc.setTextColor(255,255,255)
                doc.text(334, 205, `${doc.page}`)
                doc.page ++ 
                
            }
        }

        dispatch(loadingReport(false))

        doc.save(`Reporte ewisemaps - ${namedto}.pdf`)

    }
    

    const print = () => {
        localStorage.removeItem('app-web-places')
        dispatch(setPlacesPoliticalLevelsAction(dto))
        dispatch(loadingReport(true))
        let ids = chartGetIds()
        let  timer = setTimeout( () => {
            generatePDF(ids)
        }, 3000)
        return () => clearTimeout(timer)
    }

    return (
        <>
            <Dropdown.Item className="ewisemaps-item-actions" eventKey="pdf" onClick={() => print()}>
                <i className="far fa-file-pdf"></i> <span>PDF</span>
            </Dropdown.Item>
        </>
    )
}

export default PrintReportBasicComponent
