import React from 'react';

const PotentialADTComponent = (props) => {

    const setColor = (x) => {
        return {
            backgroundColor: x,
        };
    };

    return (
        <>
            <div className="geotarget-cards">
                {
                    props.features.map((item, keyname) => (
                        <div className="geotarget-card" key={keyname}>
                            <div className="side" style={setColor(item.properties.cs_color)} />
                            <div className="content">
                                <div><span>Estado: </span><p>{item.properties.statetxt}</p></div>
                                <div><span>Municipio: </span><p>{item.properties.munitxt}</p></div>
                                <div><span>Localidad: </span><p>{item.properties.localtxt}</p></div>
                                <div><span>Prioridad: </span><p>{item.properties.priority}</p></div>
                                <div><span>Descripción: </span><p>{item.properties.priority_description}</p></div>
                            </div>
                        </div>
                    ))
                }
            </div>
        </>
    );
}

export default PotentialADTComponent;
