/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import './ChartCustomB4BComponent.css';
import { useSelector } from 'react-redux';
import Axios from 'axios';
import { Radio, RadioGroup } from '@hastom/react-radio-group';
import Circle from 'react-color/es/Circle';
import ChartBarVerticalComponent from '../ChartBarVerticalComponent/ChartBarVerticalComponent';
import ChartBarHorizontalComponent from '../ChartBarHorizontalComponent/ChartBarHorizontalComponent';
import materialColors from '../../../helpers/charts/colors';
import chartProccess from '../../../helpers/charts/chartProccess';
import LoadingComponent from '../LoadingComponent/LoadingComponent';
import encodeCache from '../../../helpers/cache/encode';
import userAuth from '../../../helpers/auth/userAuth';

const config = { method: 'get', url: '', headers: { Authorization: '' } };
const cors = process.env.REACT_APP_CORS;
const back_end_stats = process.env.REACT_APP_BACK_END_STATS;

const ChartCustomB4BComponent = () => {
  const analisis = useSelector((store) => store.analizer.id);
  const auth = userAuth();

  const scopes = [
    { id: 'ANALYSIS', name: 'Resultados en el Análisis' },
    { id: 'POTENTIAL-GEO-TARGET', name: 'Potencial geoTarget360' },
    { id: 'POTENTIAL-AMAI', name: 'Potencial NSE-AMAI' },
  ];

  const [chart, setChart] = useState([]);
  const [size, setSize] = useState('lg');
  const [type, setType] = useState('horizontal');
  const [color, setColor] = useState('#FF9800');
  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useState(false);
  const [scope, setScope] = useState('ANALYSIS');
  const [print, setPrint] = useState(true);
  const load = async (x) => {
    setLoading(true);
    setScope(x);
    config.url = `${cors}${back_end_stats}/wise-stats/b4b/sector/${analisis}/${x}`;
    config.headers.Authorization = `Bearer ${auth.access_token}`;
    const res = await (await Axios(config)).data;
    const result = chartProccess(res);
    const exporter = {
      id: 'chart-4-b4b', size, data: result, color, print, name: `Empresas Sector - ${setName(scope)}`,
    };
    encodeCache('chart-4-b4b', exporter);
    setChart(result);
    setLoading(false);
  };

  useEffect(() => {
    if (!analisis) return;
    load('ANALYSIS');
  }, [analisis]);

  const changeSize = (e) => {
    setSize(e);
    const exporter = {
      id: 'chart-4-b4b', size: e, data: chart, color, print, name: `Empresas Sector - ${setName(scope)}`,
    };
    encodeCache('chart-4-b4b', exporter);
  };

  const changeScope = (e) => {
    setScope(e);
    load(e);
  };

  const changeType = (e) => setType(e);

  const changeColor = (e) => {
    setColor(e.hex);
    const exporter = {
      id: 'chart-4-b4b', size, data: chart, color: e.hex, print, name: `Empresas Sector - ${setName(scope)}`,
    };
    encodeCache('chart-4-b4b', exporter);
  };

  const setName = (e) => {
    const result = scopes.filter((item) => item.id === e);
    return result[0].name;
  };

  const changePrint = (e) => {
    setPrint(e);
    const exporter = {
      id: 'chart-4-b4b', size, data: chart, color: e.hex, print: e, name: `Empresas Sector - ${setName(scope)}`,
    };
    encodeCache('chart-4-b4b', exporter);
  };

  return chart.length > 0 ? (
    <section id="chart-custom-b4b" className={`chart-custom-b4b-size-${size}`}>
      <LoadingComponent data={loading} />
      <div className="actions">
        <div className="size">
          <RadioGroup selectedValue={size} onChange={changeSize}>
            <label>
              <Radio value="lg" />
              <i className={size === 'lg' ? 'ion-arrow-expand active' : 'ion-arrow-expand'} />
            </label>
            <label>
              <Radio value="md" />
              <i className={size === 'md' ? 'ion-arrow-shrink active' : 'ion-arrow-shrink'} />
            </label>
          </RadioGroup>
        </div>
        <div className="print">
          <RadioGroup selectedValue={print} onChange={changePrint}>
            <label>
              <Radio value={!print} />
              <i className={print === true ? 'ion-android-print active' : 'ion-android-print'} />
            </label>
          </RadioGroup>
        </div>
        <div className="scope">
          <RadioGroup selectedValue={scope} onChange={changeScope}>
            <label>
              <Radio value="ANALYSIS" />
              <i className={scope === 'ANALYSIS' ? 'active' : ''}>Análisis</i>
            </label>
            <label>
              <Radio value="POTENTIAL-GEO-TARGET" />
              <i className={scope === 'POTENTIAL-GEO-TARGET' ? 'active' : ''}>Potencial 360</i>
            </label>
            <label>
              <Radio value="POTENTIAL-AMAI" />
              <i className={scope === 'POTENTIAL-AMAI' ? 'active' : ''}>Potencial AMAI</i>
            </label>
          </RadioGroup>
        </div>
        <div className="type">
          <RadioGroup selectedValue={type} onChange={changeType}>
            <label>
              <Radio value="horizontal" />
              <i className={type === 'horizontal' ? 'ion-stats-bars active' : 'ion-stats-bars'} />
            </label>
            <label>
              <Radio value="vertical" />
              <div className="rotate-icon">
                <i className={type === 'vertical' ? 'ion-stats-bars active' : 'ion-stats-bars'} />
              </div>
            </label>
            <a onClick={() => setInfo(!info)} className={info ? 'view-info active' : 'view-info'}>
              <i className="ion-information-circled" />
            </a>
          </RadioGroup>
        </div>
      </div>
      <div id="chart-4-b4b">
        <h3>Empresas Sector</h3>
        <small>{setName(scope)}</small>
        {
                    type === 'vertical' && (<ChartBarVerticalComponent data={chart} color={color} info={info} />)
                }
        {
                    type === 'horizontal' && (<ChartBarHorizontalComponent data={chart} color={color} info={info} />)
                }
      </div>
      <Circle className="color-selector" colors={materialColors()} circleSize={14} onChangeComplete={changeColor} />
    </section>
  ) : null;
};

export default ChartCustomB4BComponent;
