import axios from 'axios';
import userAuth from '../../helpers/auth/userAuth';

// configuration
const config = {
  method: '',
  url: '',
  data: '',
  headers: { Authorization: '' },
};

// consts
const initData = {
  results: [],
  error: false,
  loading: false,
};

const cors = process.env.REACT_APP_CORS;
const back_end_data = process.env.REACT_APP_BACK_END_DATA;

// types
const GET_SEARCH_CUSTOM_SUCCESS = 'GET_SEARCH_CUSTOM_SUCCESS';
const GET_SEARCH_CUSTOM_ERROR = 'GET_SEARCH_CUSTOM_ERROR';

// reducers
export default function searchCustomReducer(state = initData, action) {
  switch (action.type) {
    case GET_SEARCH_CUSTOM_SUCCESS:
      return {
        ...state, results: action.payload, error: false, loading: false,
      };
    case GET_SEARCH_CUSTOM_ERROR:
      return { ...state, error: true, loading: false };
    default:
      return state;
  }
}

// actions
export const getSearchCustomAction = (column, layer, id) => async (dispatch) => {
  const auth = userAuth();

  if (!auth) return;

  config.method = 'get';
  config.headers.Authorization = `Bearer ${auth.access_token}`;
  config.url = `${cors}${back_end_data}/data/string-cat/search?columnName=${column}&layerId=${layer}&value=${id}`;

  try {
    const result = await (await axios(config)).data;
    dispatch({
      type: GET_SEARCH_CUSTOM_SUCCESS,
      payload: result,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: GET_SEARCH_CUSTOM_ERROR,
    });
  }
};
