// eslint-disable-next-line no-unused-vars
export default function getRole(auth) {
  const id = 'ROLE';

  const search = auth.filter((value) => value.toLowerCase().indexOf(id.toLocaleLowerCase()) >= 0);

  const filters = search[0].replace(`${id}|`, '');

  return filters;
}
