import axios from 'axios';
import getAnalisisGeneral from '../../helpers/auth/getAnalisisGeneral';
import getRole from '../../helpers/auth/getRole';
import userAuth from '../../helpers/auth/userAuth';

// configuration
const config = {
  method: 'get',
  url: '',
  data: '',
  headers: { Authorization: '' },
};

// consts
const initData = {
  results: [],
  general: null,
  loading: false,
};

const cors = process.env.REACT_APP_CORS;
const back_end_analizer = process.env.REACT_APP_BACK_END_ANALIZER;

// types
const GET_HISTORICAL_ANALYSIS_SUCCESS = 'GET_HISTORICAL_ANALYSIS_SUCCESS';
const GET_GENERAL_ANALYSIS_SUCCESS = 'GET_GENERAL_ANALYSIS_SUCCESS';
const GET_HISTORICAL_ANALYSIS_ERROR = 'GET_HISTORICAL_ANALYSIS_ERROR';
const DELETE_HISTORICAL_ANALYSIS_SUCCESS = 'DELETE_HISTORICAL_ANALYSIS_SUCCESS';
const DELETE_HISTORICAL_ANALYSIS_ERROR = 'DELETE_HISTORICAL_ANALYSIS_ERROR';
const LOADING_HISTORICAL_ANALYSIS = 'LOADING_HISTORICAL_ANALYSIS';

// reducers
export default function historicalAnalysisReducer(state = initData, action) {
  switch (action.type) {
    case GET_HISTORICAL_ANALYSIS_SUCCESS:
      return { ...state, ...action.payload, loading: false };
    case GET_GENERAL_ANALYSIS_SUCCESS:
      return { ...state, ...action.payload };
    case GET_HISTORICAL_ANALYSIS_ERROR:
      return { ...state, loading: false };
    case DELETE_HISTORICAL_ANALYSIS_SUCCESS:
      return { ...state, loading: false };
    case DELETE_HISTORICAL_ANALYSIS_ERROR:
      return { ...state, loading: false };
    case LOADING_HISTORICAL_ANALYSIS:
      return { ...state, loading: true };
    default:
      return state;
  }
}

// actions
export const getHistoricalAnalysisAction = (authPermission) => async (dispatch) => {
  const role = getRole(authPermission);

  const auth = userAuth();

  if (!auth) return;

  dispatch({ type: LOADING_HISTORICAL_ANALYSIS });

  config.method = 'get';
  config.headers.Authorization = `Bearer ${auth.access_token}`;

  const items = auth.layers;

  try {
    const res = [];

    for (let i = 0; i < items.length; i++) {
      config.url = `${cors}${back_end_analizer}/wise-analyzer/analysis/layer/${items[i].layerId}/${auth.username}`;
      res.push(await (await axios(config)).data);
    }

    const queries = res[0].filter((item) => item.createdBy === auth.username);

    dispatch({
      type: GET_HISTORICAL_ANALYSIS_SUCCESS,
      payload: {
        results: queries.filter((item) => !item.general),
      },
    });

    getAnalisisGeneral(auth.access_token, auth.username, auth.layers[0], role).then((id) => {
      dispatch({
        type: GET_GENERAL_ANALYSIS_SUCCESS,
        payload: {
          general: id,
        },
      });
    });
  } catch (error) {
    dispatch({ type: GET_HISTORICAL_ANALYSIS_ERROR });
  }
};

export const deleteHistoricalAnalysisById = (id) => async (dispatch) => {
  const auth = userAuth();

  if (!auth) return;

  config.method = 'delete';

  config.headers.Authorization = `Bearer ${auth.access_token}`;

  dispatch({ type: LOADING_HISTORICAL_ANALYSIS });

  try {
    config.url = `${cors}${back_end_analizer}/wise-analyzer/analyze/${id}`;
    await (await axios(config)).data;
    dispatch({ type: DELETE_HISTORICAL_ANALYSIS_SUCCESS });
  } catch (error) {
    console.log(error.response.data);
    dispatch({ type: DELETE_HISTORICAL_ANALYSIS_ERROR });
  }
};
