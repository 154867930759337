/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Dropdown } from 'react-bootstrap';
import './ExportB4BComponent.css';
import { exportCSVAction, exportSectorsAction } from '../../../services/ducks/b4b-export.duck';

const ExportB4BComponent = () => {
  const dispatch = useDispatch();
  const b4b = useSelector((state) => state.b4bMexico.results);
  const b4b_export = useSelector((state) => state.b4bExport.results);

  const [modalShow, setModalShow] = useState(false);
  const [scope, setScope] = useState(null);

  const scopes = [
    { id: 'ANALYSIS', name: 'Resultados en el Análisis' },
    { id: 'POTENTIAL-GEO-TARGET', name: 'Potencial geoTarget360' },
    { id: 'POTENTIAL-AMAI', name: 'Potencial NSE-AMAI' },
  ];

  const getFiles = (e) => {
    setModalShow(true);
    setScope(e);
    dispatch(exportSectorsAction(b4b, e));
  };

  const download = (id, name) => dispatch(exportCSVAction(id, name, scope));

  return (
    <>
      <Dropdown.Header>Exportar Empresas</Dropdown.Header>
      {
                scopes.map((item) => (
                  <Dropdown.Item className="ewisemaps-item-actions" eventKey={item.id} key={item.id} onClick={() => getFiles(item.id)}>
                    <i className="far fa-file-excel" />
                    {' '}
                    <span><p>{item.name}</p></span>
                  </Dropdown.Item>
                ))
            }
      <Modal className="ewisemaps-lg-modal export-csv" size="lg" show={modalShow} onHide={() => setModalShow(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <i className="far fa-building" />
            {' '}
            Empresas por Sector
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="csvs">
            {
                            b4b_export.map((item) => (
                              <button key={item.b4BSectorId} onClick={() => download(item.b4BSectorId, item.sector)}>
                                <span>
                                  <p>{item.sector}</p>
                                  <small>
                                    Total:
                                    {item.data.rows.toLocaleString('es-MX')}
                                  </small>
                                  <small>
                                    Tamaño:
                                    {(item.data.size * 1.4).toLocaleString('es-MX', { maximumFractionDigits: 2 })}
                                    {' '}
                                    MB
                                  </small>
                                </span>
                                <i className="fas fa-file-download"> </i>
                              </button>
                            ))
                        }
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ExportB4BComponent;
