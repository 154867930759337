import decodeCache from '../cache/decode';

export default function chartGetIds() {
  const keys = [];
  for (let i = 0; i < localStorage.length; i++) keys.push(localStorage.key(i));
  const charts = keys.filter((item) => item.includes('chart'));

  const data = charts.map((item) => getData(item));

  const result = data.filter((item) => item.print && item.data);

  return result.sort((a, b) => {
    if (a.id < b.id) { return -1; }
    if (a.id > b.id) { return 1; }
    return 0;
  });
}

const getData = (x) => decodeCache(x);
