import React, { useEffect, useState } from 'react';
import './LoadingComponent.css';
import { useSelector } from 'react-redux';

const LoadingComponent = (props) => {
  const loading = props.data;

  const auth = useSelector((store) => store.auth.loading);
  const report = useSelector((store) => store.printReport.loading);
  const feature = useSelector((store) => store.featureinfo.loading);
  const analizer = useSelector((store) => store.analizer.loading);
  const params = useSelector((store) => store.filtersByParams.loading);
  const mxstate = useSelector((store) => store.filtersPolicitalEntitiesMexicoStates.loading);
  const mxmun = useSelector((store) => store.filtersPolicitalEntitiesMexicoMunicipalities.loading);
  const mxcol = useSelector((store) => store.filtersPolicitalEntitiesMexicoColonies.loading);
  const historical = useSelector((store) => store.historicalAnalysis.loading);
  const mxlevel = useSelector((store) => store.filtersPolicitalEntitiesMexicoLevel.loading);
  const csvexport = useSelector((store) => store.b4bExport.loading);
  const forgot = useSelector((store) => store.forgotPassword.loading);
  const recovery = useSelector((store) => store.newPassword.loading);

  const [state, setState] = useState(false);

  const nana = () => {
    const data = [loading, auth, report, feature, analizer, params, mxlevel, mxstate, mxmun, mxcol, historical, csvexport, forgot, recovery];
    const result = data.filter((item) => item);
    if (result.length === 0) {
      const timer = setTimeout(() => {
        setState(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
    setState(true);
  };

  useEffect(() => {
    nana();
  }, [nana]);

  return state ? (
    <section id="app-web-loading">
      <div className="progress-bar">
        <div className="progress-bar-value" />
      </div>
    </section>
  ) : null;
};
export default LoadingComponent;
