import React, { createRef, useEffect, useState } from 'react';
import './MapComponent.css';
import { Toast } from 'react-bootstrap';
import { LatLng } from 'leaflet';
import 'leaflet-contextmenu';
import {
  Map,
  TileLayer,
  ZoomControl,
  Tooltip,
  WMSTileLayer,
  withLeaflet,
} from 'react-leaflet';
import Marker from 'react-leaflet-enhanced-marker';
import { BoxZoomControl } from 'react-leaflet-box-zoom';
import PrintControlDefault from 'react-leaflet-easyprint';
import { useDispatch, useSelector } from 'react-redux';
import pinIcon from '../../../assets/pin.png';
import searchIcon from '../../../assets/search.png';
import centerIcon from '../../../assets/center.png';
import zoomOutIcon from '../../../assets/zoomOut.png';
import zoomInIcon from '../../../assets/zoomIn.png';
import { changeBaseMapsAction } from '../../../services/ducks/basemaps.duck';
import {
  getFeatureInfoAction,
  emptyFeatureInfoAction,
  loadingFeatures,
} from '../../../services/ducks/featureinfo.duck';
import {
  getMapDefaultAction,
  changeMapDefaultAction,
} from '../../../services/ducks/map-default.duck';
import { getCoordinatesAction } from '../../../services/ducks/geocoding.duck';

const MapComponent = () => {
  const dispatch = useDispatch();

  let mapObject = createRef();

  const [show, setShow] = useState(false);

  const basemap = useSelector((store) => store.basemaps.basemapSelected);
  const overlayers = useSelector((store) => store.overlayers.resultsAlt);
  const mapConfiguration = useSelector((store) => store.mapdefault.results);
  const geocoding = useSelector((store) => store.geocoding.coordinates);

  const PrintControl = withLeaflet(PrintControlDefault);

  const getMapObject = (e) => {
    if (!e) return;
    mapObject = e;
  };

  useEffect(() => {
    const fetchData = () => {
      dispatch(changeBaseMapsAction());
      dispatch(getMapDefaultAction());
    };
    fetchData();
  }, [dispatch]);

  const setGeolocation = (e) => {
    const timer = setTimeout(() => {
      if (mapObject) mapObject.leafletElement.flyTo(e.latlng, 17);
    }, 1000);
    return () => clearTimeout(timer);
  };

  if (geocoding) {
    setGeolocation(geocoding);
  }

  const handleClick = (e) => {
    const layers = overlayers.filter((data) => data.visible === true);
    if (layers.length > 0) {
      const featureinfo = {
        e,
        layers,
        bbox: e.target.getBounds(),
      };
      dispatch(getFeatureInfoAction(featureinfo));
      dispatch(loadingFeatures());
    } else {
      dispatch(emptyFeatureInfoAction());
    }
  };

  const handleMoveend = (e) => {
    dispatch(
      changeMapDefaultAction(
        e.target.getZoom(),
        e.target.getCenter().lat,
        e.target.getCenter().lng,
      ),
    );
  };

  const setInZoom = (e) => {
    const timer = setTimeout(() => {
      if (mapObject) {
        const zoom = mapObject.leafletElement.getZoom();
        if (zoom < 19) {
          mapObject.leafletElement.flyTo(
            e.latlng,
            mapObject.leafletElement.getZoom() + 1,
          );
        }
      }
    }, 1000);
    return () => clearTimeout(timer);
  };

  const setOutZoom = (e) => {
    const timer = setTimeout(() => {
      if (mapObject) {
        const zoom = mapObject.leafletElement.getZoom();
        if (zoom > 3) {
          mapObject.leafletElement.flyTo(
            e.latlng,
            mapObject.leafletElement.getZoom() - 1,
          );
        }
      }
    }, 1000);
    return () => clearTimeout(timer);
  };

  const setCenterMap = (e) => {
    const timer = setTimeout(() => {
      if (mapObject) mapObject.leafletElement.panTo(e.latlng);
    }, 1000);
    return () => clearTimeout(timer);
  };

  const getDataByBounds = (e) => {
    const timer = setTimeout(() => {
      if (mapObject) console.log(mapObject.leafletElement.getBounds());
      setShow(true);
    }, 1000);
    return () => clearTimeout(timer);
  };

  const contextMenu = [
    {
      text: 'Acercar',
      callback: setInZoom,
      icon: zoomInIcon,
    },
    {
      text: 'Alejar',
      callback: setOutZoom,
      icon: zoomOutIcon,
    },
    {
      text: 'Centrar',
      callback: setCenterMap,
      icon: centerIcon,
    },
    {
      text: '¿Qué hay en esta zona?',
      callback: getDataByBounds,
      icon: searchIcon,
    },
  ];

  return basemap ? (
    <>
      <Toast
        className="toast-message-ewisemaps"
        onClose={() => setShow(false)}
        show={show}
        delay={5000}
        autohide
      >
        <Toast.Header>
          <strong className="mr-auto">Aún no disponible</strong>
        </Toast.Header>
        <Toast.Body>Esta función estará en la siguiente versión</Toast.Body>
      </Toast>
      <Map
        id="ewisemaps-map"
        ref={(ref) => {
          getMapObject(ref);
        }}
        animate
        useFlyTo
        onClick={handleClick}
        onMoveend={handleMoveend}
        center={new LatLng(mapConfiguration.lat, mapConfiguration.lng)}
        zoom={mapConfiguration.zoom}
        zoomControl={mapConfiguration.zoomControl}
        minZoom={mapConfiguration.minZoom}
        maxZoom={mapConfiguration.maxZoom}
        contextmenu
        contextmenuWidth={130}
        contextmenuItems={contextMenu}
      >
        <ZoomControl position="topright" />
        <BoxZoomControl position="bottomright" sticky />
        <PrintControl
          position="bottomleft"
          sizeModes={['Current']}
          hideControlContainer={false}
          title="Imprimir Mapa"
          exportOnly
        />
        {geocoding ? (
          <Marker
            position={geocoding.latlng}
            // eslint-disable-next-line jsx-a11y/alt-text
            icon={<img src={pinIcon} style={{ width: '50px' }} />}
            onClick={() => dispatch(getCoordinatesAction())}
          >
            <Tooltip permanent>
              <span>{geocoding.name}</span>
            </Tooltip>
          </Marker>
        ) : null}
        <TileLayer attribution={basemap.attribution} url={basemap.url} />
        {overlayers
          ? overlayers.map((item, keyname) => (item.visible ? (
            <WMSTileLayer
              key={keyname}
              layers={`${item.workspace}:${item.layer}`}
              url={`${item.server}/${item.workspace}/${item.service}?`}
              version={item.version}
              viewparams={item.viewparams}
              styles={item.style}
              opacity={1}
              transparent
              format="image/png"
              detectRetina
            />
          ) : null))
          : null}
      </Map>
    </>
  ) : null;
};

export default MapComponent;
