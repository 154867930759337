import React from 'react';
import './MenuVerticalComponent.css';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link, withRouter } from 'react-router-dom';
import logo from '../../../assets/ewisemaps_logo.png';

const MenuVerticalComponent = () => (
  <>
    <nav id="ewisemaps-nav-vertical">
      <div className="logo">
        <img src={logo} alt="ADT" />
      </div>
      <div className="navigation">
        <ul>
          <Link to="/main">
            <li>
              <OverlayTrigger
                placement="right"
                overlay={(
                  <Tooltip>
                          Mapa inteligente
                                </Tooltip>
                              )}
              >
                <i className="ion-map" />
              </OverlayTrigger>
            </li>
          </Link>
          <Link to="/report">
            <li>
              <OverlayTrigger
                placement="right"
                overlay={(
                  <Tooltip>
                          Reportes
                                </Tooltip>
                              )}
              >
                <i className="ion-stats-bars" />
              </OverlayTrigger>
            </li>
          </Link>
        </ul>
      </div>
    </nav>
  </>
);

export default withRouter(MenuVerticalComponent);
