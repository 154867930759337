import React from 'react';
import './LayerMunicipalityMexicoComponent.css';

const LayerMunicipalityMexicoComponent = (props) => (
  <div className="municipality-mexico-cards">
    {
                props.features.map((item, keyname) => (
                  <div className="municipality-mexico-card" key={keyname}>
                    <h5>
                      {' '}
                      {item.properties.nomgeo}
                      {' '}
                    </h5>
                  </div>
                ))
            }
  </div>
);
export default LayerMunicipalityMexicoComponent;
