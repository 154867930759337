const geoserver = process.env.REACT_APP_GEOSERVER

export default function layersByOrganization(layers)  {

    if(layers.length === 0) return []

    let result = []

    for(let i = 0; i < layers.length; i++) {
        result.push(search(layers[i].layerId))
    }

    return result;

}

const search = (id) => {
    return data.find(item => item.id === id)
}


const data = [
    {
        "id": 1,
        "name" : "Datos ADT",
        "layers" : [
            {
                "server": `${geoserver}`,
                "workspace": "wise",
                "service": "wms",
                "id": "layer_point_data",
                "layer": "layer_point_data",
                "name": "Clientes",
                "visible" : true,
                "version" : "1.1.1",
                "style": "clientes_adt",
                "viewparams": "",
                "srs" : "EPSG:4326"
            }
        ]
    }
]
