import React from 'react'
import './LayerNseAmaiComponent.css'

const LayerNseAmaiComponent = (props) => {

    const setColor = (x) => {
        let style = {
            backgroundColor: x
        }
        return style
    }


    return (
        <div className="nseamai-cards">
            {
                props.features.map((item, keyname)=> (
                    <div className="nseamai-card" key={keyname}>
                        <div className="side" style={setColor(item.properties.color)}>
                            {item.properties.cat_nse_id === 1 && (<h5>A/B</h5>) }
                            {item.properties.cat_nse_id === 2 && (<h5>C+</h5>) }
                            {item.properties.cat_nse_id === 3 && (<h5>C</h5>) }
                            {item.properties.cat_nse_id === 4 && (<h5>C-</h5>) }
                            {item.properties.cat_nse_id === 5 && (<h5>D+</h5>) }
                            {item.properties.cat_nse_id === 6 && (<h5>D</h5>) }
                            {item.properties.cat_nse_id === 7 && (<h5>E</h5>) }
                        </div>
                        <div className="content">
                            <p>{item.properties.descri}</p>
                        </div>
                    </div>
                ))
            }
        </div>   
    )
    
}

export default LayerNseAmaiComponent