import axios from 'axios';
import userAuth from '../../helpers/auth/userAuth';

const cors = process.env.REACT_APP_CORS;
const signup = process.env.REACT_APP_BACK_END_SIGNUP;

// configuration
const config = {
  method: '',
  url: '',
  data: '',
  headers: { Authorization: '' },
};
// model
const initData = {
  loading: false,
  passwordChangeStatus: 0,
};

// types
const CHANGE_PASSWORD_LOADING = 'CHANGE_PASSWORD_LOADING';
const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
const CHANGE_PASSWORD_ERROR = 'CHANGE_PASSWORD_ERROR';
const HIDE_NOTIFICATION = 'HIDE_NOTIFICATION';

// reducers
export default function resetPasswordReducer(state = initData, action) {
  switch (action.type) {
    case CHANGE_PASSWORD_LOADING:
      return { ...state, loading: true };
    case CHANGE_PASSWORD_SUCCESS:
      return { ...state, loading: false, passwordChangeStatus: 1 };
    case CHANGE_PASSWORD_ERROR:
      return { ...state, loading: false, passwordChangeStatus: 2 };
    case HIDE_NOTIFICATION:
      return { ...state, loading: false, passwordChangeStatus: 0 };
    default:
      return state;
  }
}

// actions
export const changePasswordAction = (newPassword) => async (dispatch) => {
  const auth = userAuth();
  if (!auth) return;

  const data = {
    userName: auth.username,
    newPassword,
  };

  dispatch({ type: CHANGE_PASSWORD_LOADING });

  config.method = 'post';
  config.url = `${cors}${signup}/password/user/password`;
  config.data = JSON.stringify(data);
  config.headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${auth.access_token}`,
  };

  try {
    const res = await (await axios(config)).data;
    console.log(res);
    dispatch({ type: CHANGE_PASSWORD_SUCCESS });
  } catch (error) {
    console.log(error.response.data);
    dispatch({ type: CHANGE_PASSWORD_ERROR });
  }
};

export const hideNotificationAction = () => async (dispatch) => {
  dispatch({ type: HIDE_NOTIFICATION });
};
