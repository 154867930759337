import axios from 'axios';
import userAuth from '../../helpers/auth/userAuth';

// configuration
const config = {
  method: '',
  url: '',
  data: '',
  headers: { Authorization: '' },
};

// consts
const initData = {
  results: [],
  error: false,
  loading: false,
};

const cors = process.env.REACT_APP_CORS;
const back_end_data = process.env.REACT_APP_BACK_END_DATA;

// types
const GET_DEMOGRAPHIC_MEXICO_SUCCESS = 'GET_DEMOGRAPHIC_MEXICO_SUCCESS';
const GET_DEMOGRAPHIC_MEXICO_ERROR = 'GET_DEMOGRAPHIC_MEXICO_ERROR';

// reducers
export default function demographicMexicoReducer(state = initData, action) {
  switch (action.type) {
    case GET_DEMOGRAPHIC_MEXICO_SUCCESS:
      return {
        ...state, results: action.payload, error: false, loading: false,
      };
    case GET_DEMOGRAPHIC_MEXICO_ERROR:
      return { ...state, error: true, loading: false };
    default:
      return state;
  }
}

// actions
export const getDemographicCategoriesAction = () => async (dispatch) => {
  const auth = userAuth();

  if (!auth) return;

  config.method = 'get';
  config.headers.Authorization = `Bearer ${auth.access_token}`;
  config.url = `${cors}${back_end_data}/ageb/categories`;

  try {
    getCategories(await (await axios(config)).data, auth).then(
      (success) => {
        dispatch({
          type: GET_DEMOGRAPHIC_MEXICO_SUCCESS,
          payload: success,
        });
      },
    );
  } catch (error) {
    dispatch({
      type: GET_DEMOGRAPHIC_MEXICO_ERROR,
    });
  }
};

const getCategories = async (data, auth) => {
  config.method = 'get';
  config.headers.Authorization = `Bearer ${auth.access_token}`;

  const result = [];

  for (let i = 0; i < data.length; i++) {
    config.url = `${cors}${back_end_data}/ageb/indicators/${data[i].id}`;
    result.push({
      categories: await (await axios(config)).data,
      ...data[i],
    });
  }

  return result;
};
