import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getStatesAction } from '../../../services/ducks/filters-political-entities-mexico-states.duck';
import { getMunicipalitiesByState } from '../../../services/ducks/filters-political-entities-mexico-municipalities.duck';
import {
  addColoniesAction, getColoniesByMunicipality, getSelectedColoniesAction, removeColoniesAction,
} from '../../../services/ducks/filters-political-entities-mexico-colonies.duck';
import { getIdsByColoniesAction } from '../../../services/ducks/filters-political-entities-mexico-level.duck';

const FilterColonyComponent = () => {
  const dispatch = useDispatch();

  const state = useSelector((store) => store.filtersPolicitalEntitiesMexicoStates.states, []);
  const municipality = useSelector((store) => store.filtersPolicitalEntitiesMexicoMunicipalities.municipalities, []);
  const colony = useSelector((store) => store.filtersPolicitalEntitiesMexicoColonies.colonies, []);
  const selected = useSelector((store) => store.filtersPolicitalEntitiesMexicoColonies.colonies_selected, []);

  const [selectListStates, setSelectListStates] = useState('*');
  const [selectListMunicipalities, setSelectListMunicipalities] = useState('*');
  const [selectListColonies, setSelectListColonies] = useState('*');

  useEffect(() => {
    dispatch(getStatesAction);
    dispatch(getSelectedColoniesAction());
    dispatch(getIdsByColoniesAction());
  }, [dispatch]);

  const stateChange = (e) => {
    if (e.target.value === '*') return;
    const item = JSON.parse(e.target.value);
    setSelectListStates(item.name);
    setSelectListMunicipalities('*');
    dispatch(getMunicipalitiesByState(item.id));
  };

  const municipalityChange = (e) => {
    if (e.target.value === '*') return;
    const item = JSON.parse(e.target.value);
    setSelectListMunicipalities(item.nomgeo);
    dispatch(getColoniesByMunicipality(item.cvegeo));
  };

  const colonyChange = (e) => {
    if (e.target.value === '*') return;
    const item = JSON.parse(e.target.value);
    setSelectListColonies(item.settName);
    dispatch(addColoniesAction(item));
    dispatch(getIdsByColoniesAction());
  };

  const removeColony = (e) => {
    setSelectListStates('*');
    setSelectListMunicipalities('*');
    setSelectListColonies('*');
    dispatch(removeColoniesAction(e));
    dispatch(getIdsByColoniesAction());
  };

  return (
    <section className="filter-location">
      <div className="selects-list">
        <select value={selectListStates} onChange={stateChange}>
          <option value="*">Escoge algún estado</option>
          {
                        state
                          .sort((a, b) => {
                            if (a.name < b.name) { return -1; }
                            if (a.name > b.name) { return 1; }
                            return 0;
                          })
                          .map((item, keyname) => (
                            <option key={keyname} value={JSON.stringify(item)}>{item.name}</option>
                          ))
                    }
        </select>
        <select value={selectListMunicipalities} onChange={municipalityChange}>
          <option value="*">
            Escoge algún municipio
            { selectListStates !== '*' && ` de ${selectListStates}`}
          </option>
          {
                        municipality.length > 0 ? (
                          municipality
                            .sort((a, b) => {
                              if (a.nomgeo < b.nomgeo) { return -1; }
                              if (a.nomgeo > b.nomgeo) { return 1; }
                              return 0;
                            })
                            .map((item, keyname) => (
                              <option key={keyname} value={JSON.stringify(item)}>{item.nomgeo}</option>
                            ))
                        ) : null
                    }
        </select>
        <select value={selectListColonies} onChange={colonyChange}>
          <option value="*">
            Escoge alguna colonia
            { selectListMunicipalities !== '*' && ` de ${selectListMunicipalities}`}
          </option>
          {
                        colony.length > 0 ? (
                          colony
                            .sort((a, b) => {
                              if (a.settName < b.settName) { return -1; }
                              if (a.settName > b.settName) { return 1; }
                              return 0;
                            })
                            .map((item, keyname) => (
                              <option key={keyname} value={JSON.stringify(item)}>{item.settName}</option>
                            ))
                        ) : null
                    }
        </select>
      </div>
      <div className="chips-location">
        <ul>
          {
                        selected ? (
                          selected
                            .sort((a, b) => {
                              if (a.name < b.name) { return -1; }
                              if (a.name > b.name) { return 1; }
                              return 0;
                            })
                            .map((item, keyname) => (
                              <li key={keyname}>
                                <div className="action">
                                  <button onClick={() => removeColony(item.id)}><i className="fas fa-times" /></button>
                                </div>
                                <div className="name">
                                  {item.name}
                                </div>
                              </li>
                            ))
                        ) : null
                    }
        </ul>
      </div>
    </section>
  );
};

export default FilterColonyComponent;
