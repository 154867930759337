/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import './NewPasswordComponent.css'
import {withRouter} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { newPasswordAction } from '../../../services/ducks/new-password.duck'
import logo from '../../../assets/oeste_logo.jpg'


const NewPasswordComponent = (props) => {

    const dispatch = useDispatch()

    const message = useSelector(store => store.newPassword.message)
    const success = useSelector(store => store.newPassword.success)

    const [new_password, setNew_password] = useState('')
    const [repeat_password, setRepeat_password] = useState('')
    const [alert, setAlert] = useState(null)

    const forgotForm = e => {
        e.preventDefault()
        if(!new_password.trim() || !repeat_password.trim()) {setAlert('Ambos campos son obligatorios');return}
        if(new_password !== repeat_password) {setAlert('Las contraseñas no coinciden');return}
        if(new_password.length < 6) {setAlert('La contraseña debe tener mínimo 6 caracteres');return}
        dispatch(newPasswordAction(new_password, props.token))
    }

    useEffect(() => {
        setAlert(message)
        if(success) {
            let  timer = setTimeout( () => {
                props.history.push('/')
            }, 5000)
            return () => clearTimeout(timer)
        }
    }, [message, props.history, success])

    const goToHome = () => {
        props.history.push('/')
    }

    return (
        <section id="ewisemaps-new-password">
            <div className="form">
                <h1>Nueva contraseña</h1>
                {
                    alert && (
                        <span>
                            {alert}
                        </span>
                    )
                }
                <form onSubmit={forgotForm}>
                    <div className="form-item">
                        <input type="password" placeholder="escribe tu nueva contraseña" value={new_password} onChange={ e => setNew_password(e.target.value) } required/>
                    </div>
                    <div className="form-item">
                        <input type="password" placeholder="escribe tu nueva contraseña" value={repeat_password} onChange={ e => setRepeat_password(e.target.value) } required/>
                    </div>
                    <div className="form-item">
                        <button type="submit">Cambiar</button>
                    </div>
                </form>
                <div className="options">
                    <a onClick={() => goToHome()}>Quiero iniciar sesión</a>
                </div>
                <div className="legend">
                    <img src={logo} alt="Organización de Estudios del Territorio S.A. de C.V."/>
                    <br/>
                    <span>
                        Desarrollado por <a href="https://oeste.xyz/">Organización de Estudios del Territorio S.A. de C.V.</a>  
                    </span>
                </div>
            </div>
        </section>
    )
}

export default withRouter(NewPasswordComponent)
