import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import ScrollIntoViewComponent from './components/templates/ScrollIntoViewComponent/ScrollIntoViewComponent';
import LoadingComponent from './components/templates/LoadingComponent/LoadingComponent';
import FilterMessageComponent from './components/templates/FilterMessageComponent/FilterMessageComponent';
import { refreshToken } from './services/ducks/auth.duck';
import withClearCache from './withClearCache';
import Routes from './Routes';

const ClearCacheComponent = withClearCache(MainApp);

function App() {
  return <ClearCacheComponent />;
}

function MainApp() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(refreshToken());
  }, [dispatch]);

  const handleAction = () => dispatch(refreshToken());

  document.addEventListener('click', handleAction);
  document.addEventListener('mouseover', handleAction);

  return (
    <div ref={(e) => { handleAction(e); }}>
      <FilterMessageComponent />
      <LoadingComponent />
      <Router>
        <ScrollIntoViewComponent>
          <Routes />
        </ScrollIntoViewComponent>
      </Router>
    </div>
  );
}

export default App;
