import React, { useState } from 'react';
import './FilterCustomSocioeconomicalComponent.css';
import { useDispatch } from 'react-redux';
import SearchInput, { createFilter } from 'react-search-input';
import { changeFilterCustomStringAction } from '../../../services/ducks/filters-socioeconomical-by-params.duck';

const FilterCustomSocioeconomicalComponent = (props) => {
  const dispatch = useDispatch();

  const [items, setItems] = useState(props.data.data);
  const [state, setState] = useState('');
  const [check, setCheck] = useState(false);

  const KEYS_TO_FILTERS = ['name'];

  const searchUpdated = (term) => {
    setState(term);
  };

  const filteredCustomString = items.filter(createFilter(state, KEYS_TO_FILTERS));

  const changeSelected = (data, id, name, active) => {
    const timer = setTimeout(() => {
      const index = data.findIndex((item) => item.name === name);
      data[index].active = active;
      setItems([]);
      setItems(data);
      dispatch(changeFilterCustomStringAction(id, items));
    }, 500);
    return () => clearTimeout(timer);
  };

  const checkAll = (id, active) => {
    const timer = setTimeout(() => {
      const resp = items.map((item) => ({ name: item.name, active }));
      setCheck(!active);
      setItems([]);
      setItems(resp);
      dispatch(changeFilterCustomStringAction(id, resp));
    }, 500);
    return () => clearTimeout(timer);
  };

  const setColor = (color) => {
    const style = { borderLeft: `3px solid ${color}` };
    return style;
  };

  return (
    <section className="filter-custom-socioeconomical">
      <h5>{props.data.name}</h5>
      <SearchInput className="search-input" onChange={searchUpdated} placeholder="Buscar" />
      <button onClick={() => checkAll(props.data.id, check)}>{check ? 'Seleccionar todo' : 'Desactivar todo'}</button>
      <ul>
        {
                    filteredCustomString
                      .sort((a, b) => {
                        if (a.id < b.id) { return -1; }
                        if (a.id > b.id) { return 1; }
                        return 0;
                      })
                      .map((item, keyname) => (
                        <li key={keyname} style={setColor(item.color)}>
                          <a onClick={() => changeSelected(items, props.data.id, item.name, !item.active)}>
                            <span>
                              {item.name}
                            </span>
                            <div className="switch_box box_1">
                              <input
                                type="checkbox"
                                className="switch_1"
                                checked={item.active}
                                onChange={() => changeSelected(items, props.data.id, item.name, !item.active)}
                              />
                            </div>
                          </a>
                        </li>
                      ))
                }
      </ul>
    </section>
  );
};

export default FilterCustomSocioeconomicalComponent;
