/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { useSelector } from 'react-redux'
import { Dropdown } from 'react-bootstrap'
import ExportB4BComponent from '../ExportB4BComponent/ExportB4BComponent'
import PrintReportBasicComponent from '../PrintReportBasicComponent/PrintReportBasicComponent'
import permission from '../../../helpers/reports/permission'

const PrintReportComponent = () => {

    const username = useSelector(store => store.auth.username)

    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <a href="#" ref={ref} onClick={(e) => { e.preventDefault(); onClick(e); }} >
          {children}
        </a>
    ))

    return permission(username) ? (
        <li className="activities-item">
            <Dropdown alignRight>
                <Dropdown.Toggle as={CustomToggle}  id="ewisemaps-print-report-actions">
                    <i className="fas fa-print"></i>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Header>Exportar Reporte</Dropdown.Header>
                    <PrintReportBasicComponent></PrintReportBasicComponent>
                    <ExportB4BComponent></ExportB4BComponent>
                </Dropdown.Menu>
            </Dropdown>
        </li>
    ): null
}

export default PrintReportComponent
