import React, { useEffect, useState } from 'react';
import './ChartCustomStringComponent.css';
import { useSelector } from 'react-redux';
import Axios from 'axios';
import { Radio, RadioGroup } from '@hastom/react-radio-group';
import Circle from 'react-color/es/Circle';
import ChartBarVerticalComponent from '../ChartBarVerticalComponent/ChartBarVerticalComponent';
import ChartBarHorizontalComponent from '../ChartBarHorizontalComponent/ChartBarHorizontalComponent';
import materialColors from '../../../helpers/charts/colors';
import chartProccess from '../../../helpers/charts/chartProccess';
import LoadingComponent from '../LoadingComponent/LoadingComponent';
import encodeCache from '../../../helpers/cache/encode';
import userAuth from '../../../helpers/auth/userAuth';

const config = { method: 'get', url: '', headers: { Authorization: '' } };
const cors = process.env.REACT_APP_CORS;
const back_end_stats = process.env.REACT_APP_BACK_END_STATS;

const ChartCustomStringComponent = (props) => {
  const { name } = props.data;
  const { id } = props.data;
  const analisis = useSelector((store) => store.analizer.id);
  const auth = userAuth();

  const [chart, setChart] = useState([]);
  const [size, setSize] = useState('md');
  const [type, setType] = useState('horizontal');
  const [color, setColor] = useState('#FF9800');
  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useState(false);
  const [print, setPrint] = useState(true);

  const load = async () => {
    setLoading(true);
    config.url = `${cors}${back_end_stats}/wise-stats/string/${id}/${analisis}`;
    config.headers.Authorization = `Bearer ${auth.access_token}`;
    const res = await (await Axios(config)).data;
    const result = chartProccess(res);
    const exporter = {
      id: `chart-1-${id}`, size, data: result, color, print, name,
    };
    encodeCache(`chart-1-${id}`, exporter);
    setChart(result);
    setLoading(false);
  };

  useEffect(() => {
    if (!analisis) return;
    load();
  }, [analisis]);

  const changeSize = (e) => {
    setSize(e);
    const exporter = {
      id: `chart-1-${id}`, size: e, data: chart, color, print, name,
    };
    encodeCache(`chart-1-${id}`, exporter);
  };

  const changeType = (e) => setType(e);

  const changeColor = (e) => {
    setColor(e.hex);
    const exporter = {
      id: `chart-1-${id}`, size, data: chart, color: e.hex, print, name,
    };
    encodeCache(`chart-1-${id}`, exporter);
  };

  const changePrint = (e) => {
    setPrint(e);
    const exporter = {
      id: `chart-1-${id}`, size, data: chart, color, print: e, name,
    };
    encodeCache(`chart-1-${id}`, exporter);
  };

  return chart.length > 0 ? (
    <section id="chart-custom-string" className={`chart-custom-string-size-${size}`}>
      <LoadingComponent data={loading} />
      <div className="actions">
        <div className="size">
          <RadioGroup selectedValue={size} onChange={changeSize}>
            <label>
              <Radio value="lg" />
              <i className={size === 'lg' ? 'ion-arrow-expand active' : 'ion-arrow-expand'} />
            </label>
            <label>
              <Radio value="md" />
              <i className={size === 'md' ? 'ion-arrow-shrink active' : 'ion-arrow-shrink'} />
            </label>
          </RadioGroup>
        </div>
        <div className="print">
          <RadioGroup selectedValue={print} onChange={changePrint}>
            <label>
              <Radio value={!print} />
              <i className={print === true ? 'ion-android-print active' : 'ion-android-print'} />
            </label>
          </RadioGroup>
        </div>
        <div className="type">
          <RadioGroup selectedValue={type} onChange={changeType}>
            <label>
              <Radio value="horizontal" />
              <i className={type === 'horizontal' ? 'ion-stats-bars active' : 'ion-stats-bars'} />
            </label>
            <label>
              <Radio value="vertical" />
              <div className="rotate-icon">
                <i className={type === 'vertical' ? 'ion-stats-bars active' : 'ion-stats-bars'} />
              </div>
            </label>
            <a onClick={() => setInfo(!info)} className={info ? 'view-info active' : 'view-info'}>
              <i className="ion-information-circled" />
            </a>
          </RadioGroup>
        </div>
      </div>
      <div id={`chart-1-${id}`}>
        <h3>{name}</h3>
        {
                    type === 'vertical' && (<ChartBarVerticalComponent data={chart} color={color} info={info} />)
                }
        {
                    type === 'horizontal' && (<ChartBarHorizontalComponent data={chart} color={color} info={info} />)
                }
      </div>
      <Circle className="color-selector" colors={materialColors()} circleSize={14} onChangeComplete={changeColor} />
    </section>
  ) : null;
};

export default ChartCustomStringComponent;
