import React, { useState } from "react";
import "./LayerGeotargetComponent.css";
import { useDispatch } from "react-redux";
import PdfModalComponent from "../PdfModalComponent/PdfModalComponent";
import { getPDFViewerSelectedAction } from "../../../services/ducks/filter-pdf-viewer.duck";

const LayerGeotargetComponent = (props) => {
  console.log("geoTarget props::", props);

  const dispatch = useDispatch();
  const [modalShow, setModalShow] = useState(false);

  const setColor = (x) => {
    return {
      backgroundColor: x,
    };
  };

  const setModalPdf = (x, y) => {
    setModalShow(x);
    dispatch(getPDFViewerSelectedAction(y));
  };

  return (
    <>
      <div className="geotarget-cards">
        {props.features.map((item, keyname) => (
          <div className="geotarget-card" key={keyname}>
            <div className="side" style={setColor(item.properties.cs_color)} />
            <div className="content">
              <p>{item.properties.clase_tipo}</p>
              {item.properties.tipology_id !== 18 && (
                <button
                  style={setColor(item.properties.cs_color)}
                  onClick={() => setModalPdf(true, item.properties)}
                >
                  Saber más
                </button>
              )}
            </div>
          </div>
        ))}
      </div>
      <PdfModalComponent show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
};

export default LayerGeotargetComponent;
