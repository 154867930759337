import domtoimage from 'dom-to-image';

export default function chartRender(id) {
  return new Promise((resolve) => {
    setTimeout(() => {
      domtoimage.toPng(document.getElementById(id)).then(
        (success) => {
          resolve(success);
        },
        (error) => {
          resolve('');
        },
      );
    }, 3000);
  });
}
