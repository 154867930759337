import React from 'react';
import './FeaturesComponent.css';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { getTabsMapPanelAction } from '../../../services/ducks/featureinfo.duck';
import FeaturesMobileComponent from '../FeaturesMobileComponent/FeaturesMobileComponent';
import FeaturesDesktopComponent from '../FeaturesDesktopComponent/FeaturesDesktopComponent';

const FeaturesComponent = () => {
  const dispatch = useDispatch();

  const key = useSelector((store) => store.featureinfo.tab);

  const setModalMapPanel = (x, y) => {
    dispatch(getTabsMapPanelAction(y));
  };

  return (
    <>
      {
                key === 'features' && (
                <Modal className="ewisemaps-lg-modal features-modal" show onHide={() => setModalMapPanel(false, 'layers')} centered>
                  <Modal.Header closeButton>
                    <Modal.Title>
                      <i className="fas fa-layer-group" />
                      {' '}
                      Información
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <FeaturesMobileComponent />
                    <FeaturesDesktopComponent />
                  </Modal.Body>
                </Modal>
                )
            }
    </>
  );
};

export default FeaturesComponent;
