import axios from 'axios';
import getLayers from './getLayers';
import removeCache from '../cache/remove';
import getRole from './getRole';

const cors = process.env.REACT_APP_CORS;
const back_end_auth = process.env.REACT_APP_BACK_END_AUTH;
const client_id = process.env.REACT_APP_CLIENT_ID;

const config = {
  method: '',
  url: '',
  data: '',
  headers: { Authorization: '' },
};

export default async function getUserData(auth) {
  const form = new FormData();
  form.append('token', auth.access_token);

  config.method = 'post';
  config.url = `${cors}${back_end_auth}/check_token`;
  config.headers.Authorization = `${client_id}`;
  config.data = form;

  try {
    const res = await (await axios(config)).data;
    const result = {
      ...auth, active: res.active, expiration: res.exp, username: res.user_name, layers: null, authorities: res.authorities,
    };
    if (!result.active) return null;
    getLayers(result.username, result.access_token, getRole(result.authorities)).then((success) => result.layers = success);
    return result;
  } catch (error) {
    console.log(error.response.data);
    sessionStorage.removeItem('app-web-user-access-token');
    removeCache();
  }
}
