// eslint-disable-next-line no-unused-vars
export default function filterParamsByPermissions(id, auth, data) {
  const search = auth.filter((value) => value.toLowerCase().indexOf(id.toLocaleLowerCase()) >= 0);

  const filters = search[0].replace(`${id}|`, '');

  if (filters !== 'all') {
    const array = filters.split(',');
    return array;
  } return data;
}
