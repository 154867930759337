import React from 'react';
import './LayerZonasVentaDTComponent.css';

const LayerZonasVentaDTComponent = (props) => {

    const setColor = (x) => {
        return {
            backgroundColor: x,
        };
    };

    return (
        <>
            <div className="geotarget-cards">
                {
                    props.features.map((item, keyname) => (
                        <div className="geotarget-card" key={keyname}>
                            <div className="side" style={setColor(item.properties.cs_color)} />
                            <div className="content">
                                <div><span>Estado: </span><p>{item.properties.estado}</p></div>
                                <div><span>Municipio: </span><p>{item.properties.municipio}</p></div>
                                <div><span>Zona de venta ADT: </span><p>{item.properties.name}</p></div>
                            </div>
                        </div>
                    ))
                }
            </div>
        </>
    );
}

export default LayerZonasVentaDTComponent;