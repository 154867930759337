export default function chartProcess(res) {
  const result = [];
  for (const key in res) {
    if (res.hasOwnProperty(key)) {
      result.push({
        name: key,
        total: res[key],
        format: res[key].toLocaleString('es-MX', { maximumFractionDigits: 0 }),
      });
    }
  }

  return result;
}
