import axios from 'axios';
import FormData from 'form-data';
import removeCache from '../../helpers/cache/remove';
import getUserData from '../../helpers/auth/getUserData';
import removeAuth from '../../helpers/auth/removeAuth';
import userAuth from '../../helpers/auth/userAuth';
import updateToken from '../../helpers/auth/updateToken';
import setUserAuth from '../../helpers/auth/setUserAuth';

// configuration
const config = {
  method: '',
  url: '',
  data: '',
  headers: { Authorization: '' },
};

const cors = process.env.REACT_APP_CORS;
const back_end_auth = process.env.REACT_APP_BACK_END_AUTH;
const client_id = process.env.REACT_APP_CLIENT_ID;

// consts
const initData = {
  active: false,
  message: null,
  username: null,
  expiration: null,
  organization: 0,
  auth: false,
  loading: false,
  layers: null,
};

// types
const SIGNIN_SUCCESS = 'SIGNIN_SUCCESS';
const SIGNIN_ERROR = 'SIGNIN_ERROR';
const USER_AUTH_SUCESS = 'USER_AUTH_SUCESS';
const USER_AUTH_ERROR = 'USER_AUTH_ERROR';
const SIGNOUT_SUCCESS = 'SIGNOUT_SUCCESS';
const AUTH_LOADING = 'AUTH_LOADING';

// reducers
export default function authReducer(state = initData, action) {
  switch (action.type) {
    case SIGNIN_SUCCESS:
      return {
        ...state, auth: true, ...action.payload, loading: false,
      };
    case SIGNIN_ERROR:
      return { ...state, message: action.payload, loading: false };
    case USER_AUTH_SUCESS:
      return {
        ...state, auth: true, ...action.payload, loading: false,
      };
    case USER_AUTH_ERROR:
      return { ...initData };
    case SIGNOUT_SUCCESS:
      return { ...initData };
    case AUTH_LOADING:
      return { ...state, loading: true };
    default:
      return state;
  }
}

// actions
export const signInAction = (user, password) => async (dispatch) => {
  const form = new FormData();
  form.append('grant_type', 'password');
  form.append('username', user);
  form.append('password', password);

  removeCache();

  dispatch({ type: AUTH_LOADING });

  config.method = 'post';
  config.url = `${cors}${back_end_auth}/token`;
  config.headers.Authorization = `${client_id}`;
  config.data = form;

  try {
    const res = await (await axios(config)).data;
    updateToken(res.refresh_token).then(
      (success) => {
        getUserData(success).then(
          (completed) => {
            setTimeout(() => {
              if (!completed) {
                dispatch({
                  type: SIGNIN_ERROR,
                  payload: 'Lo lamento, tu cuenta está desactivada',
                });
                return;
              }

              if (!completed.layers) {
                dispatch({
                  type: SIGNIN_ERROR,
                  payload: 'Lo lamento, no tienes permisos',
                });
                return;
              }

              dispatch({
                type: USER_AUTH_SUCESS,
                payload: completed,
              });

              setUserAuth(completed);
            }, 2500);
          },
        );
      },
    );
  } catch (error) {
    console.log(error.response.data);
    if (error.response.data.error_description === 'Bad credentials') {
      dispatch({
        type: SIGNIN_ERROR,
        payload: '¡Oops!, comprueba tu información',
      });
    }
  }
};

export const readActiveUserAction = () => async (dispatch) => {
  const auth = userAuth();

  if (!auth) {
    dispatch({
      type: USER_AUTH_ERROR,
    });
    return;
  }

  getUserData(auth).then(
    (sucess) => {
      if (sucess) {
        dispatch({
          type: USER_AUTH_SUCESS,
          payload: sucess,
        });
      }
    },
  );
};

export const refreshToken = () => async (dispatch) => {
  const auth = userAuth();

  if (!auth) { dispatch({ type: USER_AUTH_ERROR }); return; }

  const time = auth.expiration;
  const actual = new Date().getTime() / 1000;
  const rest = time - actual;

  if (rest > 1000) return;

  if (rest < 0) { removeAuth(); return; }

  updateToken(auth.refreshToken);
};

export const logoutUserAction = () => (dispatch) => {
  dispatch({ type: AUTH_LOADING });
  dispatch({ type: SIGNOUT_SUCCESS });
  removeAuth();
  removeCache();
};
