import React from 'react'
import './MapPanelComponent.css'
import { Tabs, Tab} from 'react-bootstrap'
import BasemapsComponent from '../BasemapsComponent/BasemapsComponent'
import OverlayersComponent from '../OverlayersComponent/OverlayersComponent'

const MapPanelComponent = () => {

    return (
        <section id="ewisemaps-map-panel">
            <h4>Panel de Acción</h4>
            <Tabs defaultActiveKey="maps"  id="ewisemaps-map-panel-tabs">
                <Tab eventKey="maps" title="Mapas">
                    <BasemapsComponent></BasemapsComponent>
                </Tab>
                <Tab eventKey="layers" title="Capas">
                    <OverlayersComponent></OverlayersComponent>
                </Tab>
            </Tabs>
        </section>
    )
}

export default MapPanelComponent

