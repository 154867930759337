import axios from 'axios';
import FormData from 'form-data';

// configuration
const config = {
  method: '',
  url: '',
  data: '',
  headers: { Authorization: '' },
};

const cors = process.env.REACT_APP_CORS;
const client_id = process.env.REACT_APP_CLIENT_ID;
const back_end_signup = process.env.REACT_APP_BACK_END_SIGNUP;
const back_end_auth = process.env.REACT_APP_BACK_END_AUTH;

// consts
const initData = {
  error: false,
  success: false,
  message: null,
  loading: false,
};

// types
const NEW_PASSWORD_SUCCESS = 'NEW_PASSWORD_SUCCESS';
const NEW_PASSWORD_ERROR = 'NEW_PASSWORD_ERROR';
const NEW_PASSWORD_LOADING = 'NEW_PASSWORD_LOADING';

// reducers
export default function newPasswordReducer(state = initData, action) {
  switch (action.type) {
    case NEW_PASSWORD_SUCCESS:
      return {
        ...state, success: true, error: false, message: action.payload, loading: false,
      };
    case NEW_PASSWORD_ERROR:
      return {
        ...state, error: true, success: false, message: action.payload, loading: false,
      };
    case NEW_PASSWORD_LOADING:
      return { ...state, loading: true };
    default:
      return state;
  }
}

// actions
export const newPasswordAction = (password, token) => async (dispatch) => {
  dispatch({ type: NEW_PASSWORD_LOADING });

  getToken().then(
    (success) => {
      resetPassword(password, token, success.access_token).then(
        (completed) => {
          if (completed.code === 'password.updated.successfully') {
            dispatch({
              type: NEW_PASSWORD_SUCCESS,
              payload: 'Se ha cambiado tu contraseña, ya puedes iniciar sesión',
            });
          }
          if (completed.code === 'token.not.found') {
            dispatch({
              type: NEW_PASSWORD_ERROR,
              payload: 'Este link ha caducado, solicita uno nuevo',
            });
          }
        },
        (error) => {
          console.log(error.response.data);
          dispatch({
            type: NEW_PASSWORD_ERROR,
            payload: 'Este link ha caducado, solicita uno nuevo',
          });
        },
      );
    },
    (error) => console.log(error.response.data),
  );
};

const resetPassword = async (password, token, auth) => {
  config.method = 'post';
  config.url = `${cors}${back_end_signup}/password/reset`;
  config.headers.Authorization = `Bearer ${auth}`;
  config.data = { newPassword: password, token };
  return await (await axios(config)).data;
};

const getToken = async () => {
  const form = new FormData();
  form.append('grant_type', 'client_credentials');
  form.append('client_id', 'front');

  config.method = 'post';
  config.url = `${cors}${back_end_auth}/token`;
  config.headers.Authorization = `${client_id}`;
  config.data = form;

  try {
    return await (await axios(config)).data;
  } catch (error) {
    console.log(error.response.data);
    return null;
  }
};
