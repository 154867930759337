import axios from 'axios';
import encodeCache from '../../helpers/cache/encode';
import decodeCache from '../../helpers/cache/decode';
import userAuth from '../../helpers/auth/userAuth';

const socio = ['amai', 'geotarget'];
// consts
const initData = {
  results: [],
  error: false,
  loading: false,
};

const cors = process.env.REACT_APP_CORS;
const back_end_data = process.env.REACT_APP_BACK_END_DATA;

// types
const GET_FILTER_SOCIOECONOMICALS_SOCIOECONOMICAL_BY_PARAMS_SUCCESS = 'GET_FILTER_SOCIOECONOMICALS_SOCIOECONOMICAL_BY_PARAMS_SUCCESS';
const GET_FILTER_SOCIOECONOMICALS_SOCIOECONOMICAL_BY_PARAMS_ERROR = 'GET_FILTER_SOCIOECONOMICALS_SOCIOECONOMICAL_BY_PARAMS_ERROR';
const LOADING_FILTER_SOCIOECONOMICALS_SOCIOECONOMICAL_BY_PARAMS = 'LOADING_FILTER_SOCIOECONOMICALS_SOCIOECONOMICAL_BY_PARAMS';
const CHANGE_FILTER_SOCIOECONOMICAL_CUSTOM_STRING_SUCCESS = 'CHANGE_FILTER_SOCIOECONOMICAL_CUSTOM_STRING_SUCCESS';

// reducers
export default function filtersSocioeconomicalByParamsReducer(state = initData, action) {
  switch (action.type) {
    case GET_FILTER_SOCIOECONOMICALS_SOCIOECONOMICAL_BY_PARAMS_SUCCESS:
      return {
        ...state, ...action.payload, error: false, loading: false,
      };
    case GET_FILTER_SOCIOECONOMICALS_SOCIOECONOMICAL_BY_PARAMS_ERROR:
      return { ...state, error: true, loading: false };
    case LOADING_FILTER_SOCIOECONOMICALS_SOCIOECONOMICAL_BY_PARAMS:
      return { ...state, loading: true };
    case CHANGE_FILTER_SOCIOECONOMICAL_CUSTOM_STRING_SUCCESS:
      return {
        ...state, ...action.payload, error: false, loading: false,
      };
    default:
      return state;
  }
}

// actions
export const getFiltersSocioeconomicalByParamsAction = (params = socio) => async (dispatch) => {
  const auth = userAuth();

  if (!auth) return;

  if (decodeCache('app-web-filters-socioeconomical-by-params')) {
    const resp = decodeCache('app-web-filters-socioeconomical-by-params');
    dispatch({
      type: GET_FILTER_SOCIOECONOMICALS_SOCIOECONOMICAL_BY_PARAMS_SUCCESS,
      payload: resp,
    });
    return;
  }

  if (params.length > 0) {
    const config = {
      method: 'get',
      url: '',
      headers: {
        Authorization: `Bearer ${auth.access_token}`,
      },
    };

    const resp = {
      results: [],
    };

    try {
      dispatch({ type: LOADING_FILTER_SOCIOECONOMICALS_SOCIOECONOMICAL_BY_PARAMS });
      for (let i = 0; i < params.length; i++) {
        config.url = `${cors}${back_end_data}/${params[i]}`;
        resp.results.push({
          name: setName(params[i]),
          id: params[i],
          type: 'socioeconomical',
          data: setFormatAndDepuration(await (await axios(config)).data, params[i]),
        });
      }
      dispatch({
        type: GET_FILTER_SOCIOECONOMICALS_SOCIOECONOMICAL_BY_PARAMS_SUCCESS,
        payload: resp,
      });
      encodeCache('app-web-filters-socioeconomical-by-params', resp);
    } catch (error) {
      dispatch({
        type: GET_FILTER_SOCIOECONOMICALS_SOCIOECONOMICAL_BY_PARAMS_ERROR,
      });
    }
  }
};

const setName = (name) => {
  if (name === 'amai') return 'NSE / AMAI';
  if (name === 'geotarget') return 'geoTarget360';
};

const nseName = (id) => {
  switch (id) {
    case 1:
      return 'A/B';
    case 2:
      return 'C+';
    case 3:
      return 'C';
    case 4:
      return 'C-';
    case 5:
      return 'D+';
    case 6:
      return 'D';
    case 7:
      return 'E';
    default:
      return 'Desconocido';
  }
};

const setFormatAndDepuration = (res, type) => {
  if (type === 'amai') {
    return res.map((item) => ({
      id: item.catNseId, name: nseName(item.catNseId), color: item.color, active: true,
    }));
  }

  if (type === 'geotarget') {
    return res.map((item) => ({
      id: item.tipologyId, name: item.claseTipo, color: item.csColor, active: true,
    }));
  }
};

export const changeFilterCustomStringAction = (id, data) => (dispatch) => {
  const resp = decodeCache('app-web-filters-socioeconomical-by-params');
  const index = resp.results.findIndex((item) => item.id === id);
  resp.results[index].data = data;
  dispatch({
    type: CHANGE_FILTER_SOCIOECONOMICAL_CUSTOM_STRING_SUCCESS,
    payload: resp,
  });

  encodeCache('app-web-filters-socioeconomical-by-params', resp);
};
