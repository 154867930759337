import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import userAuth from './helpers/auth/userAuth';

const PrivacyRoute = ({ component, path, ...rest }) => {
  const notFound = () => <Redirect to="/" />;

  const auth = userAuth();

  if (auth) {
    return <Route component={component} path={path} {...rest} />;
  }
  return notFound();
};

export default PrivacyRoute;
