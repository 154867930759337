import res from '../../helpers/overlayers/overlayers';
import layersByOrganization from '../../helpers/overlayers/organization';
import encodeCache from '../../helpers/cache/encode';
import decodeCache from '../../helpers/cache/decode';
import userAuth from '../../helpers/auth/userAuth';

// consts
const initData = {
  results: [],
  resultsAlt: [],
};

// types
const GET_OVERLAYERS_SUCCESS = 'GET_OVERLAYERS_SUCCESS';
const CHANGE_OVERLAYERS_SUCCESS = 'CHANGE_OVERLAYERS_SUCCESS';

// reducers
export default function overlayersReducer(state = initData, action) {
  switch (action.type) {
    case GET_OVERLAYERS_SUCCESS:
      return { ...state, ...action.payload };
    case CHANGE_OVERLAYERS_SUCCESS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

// actions
export const getOverLayersAction = () => (dispatch) => {
  const auth = userAuth();

  if (!auth) return;

  if (decodeCache('app-web-overlayers')) {
    const layers = decodeCache('app-web-overlayers');
    dispatch({
      type: GET_OVERLAYERS_SUCCESS,
      payload: layers,
    });
    return;
  }

  const user = auth.layers;

  const organization_layers = layersByOrganization(user);
  const default_layers = res();
  const layers = [...organization_layers, ...default_layers];
  const na = layers.map((data) => data.layers);
  const resp = [].concat(...na);
  const result = {
    results: layers,
    resultsAlt: resp,
  };

  dispatch({
    type: GET_OVERLAYERS_SUCCESS,
    payload: result,
  });

  encodeCache('app-web-overlayers', result);
};

export const changeOverLayersAction = (over, keyname, subkeyname, active) => (dispatch) => {
  const auth = userAuth();

  if (!auth) return;
  let resp;
  if (decodeCache('app-web-overlayers')) resp = decodeCache('app-web-overlayers').results;
  else resp = over;
  const index = resp.findIndex((item) => item.name === keyname);
  const subindex = resp[index].layers.findIndex((item) => item.name === subkeyname);
  resp[index].layers[subindex].visible = active;
  const na = resp.map((data) => data.layers);
  const resp_alt = [].concat(...na);

  const result = {
    results: resp,
    resultsAlt: resp_alt,
  };

  dispatch({
    type: CHANGE_OVERLAYERS_SUCCESS,
    payload: result,
  });

  encodeCache('app-web-overlayers', result);
};

export const changeOverLayersByAnalizer = (data) => (dispatch) => {
  const resp = decodeCache('app-web-overlayers').results;

  for (let i = 0; i < resp.length; i++) {
    for (let j = 0; j < resp[i].layers.length; j++) {
      resp[i].layers[j] = changeLayer(resp[i].layers[j], data.filter((item) => item.wiseLayerType.type === resp[i].layers[j].id));
    }
  }

  const na = resp.map((data) => data.layers);
  const resp_alt = [].concat(...na);

  const result = {
    results: resp,
    resultsAlt: resp_alt,
  };

  dispatch({
    type: CHANGE_OVERLAYERS_SUCCESS,
    payload: result,
  });

  encodeCache('app-web-overlayers', result);
};

export const resetOverLayersAction = () => (dispatch) => {
  const auth = userAuth();

  if (!auth) return;

  const user = auth.username;

  const organization_layers = layersByOrganization(user);
  const default_layers = res();
  const layers = [...organization_layers, ...default_layers];
  const na = layers.map((data) => data.layers);
  const resp = [].concat(...na);
  const result = {
    results: layers,
    resultsAlt: resp,
  };

  dispatch({
    type: GET_OVERLAYERS_SUCCESS,
    payload: result,
  });

  encodeCache('app-web-overlayers', result);
};

const changeLayer = (item, data) => {
  if (data.length < 1) return item;

  const result = {
    ...item,
    layer: data[0].geoserverLayer,
    workspace: data[0].geoserverWorkspace,
  };

  return result;
};
