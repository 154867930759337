import React, { useEffect } from 'react'
import './OverlayersComponent.css'
import { Accordion, Card, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { getOverLayersAction, changeOverLayersAction } from '../../../services/ducks/overlayers.duck'

const OverlayersComponent = () => {

    const dispatch = useDispatch()

    const overlayers = useSelector(store => store.overlayers.results, [])

    useEffect(() => {
        const fetchData = () => {
            dispatch(getOverLayersAction())
        }
        fetchData()
    }, [dispatch])

    return (
        <>
            <Accordion className="list-overlayers">
                {
                    overlayers ? (
                        overlayers
                        .sort(function(a, b){
                            if(a.name < b.name) { return -1 }
                            if(a.name > b.name) { return 1 }
                            return 0
                        }).map((item, keyname) => (
                            <Card key={keyname}>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey={item.name}>
                                        {item.name} <i className="fas fa-chevron-down"></i>
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey={item.name}>
                                    <Card.Body>
                                        <ul className="list-layers">
                                            {
                                                item.layers.map((subitem, subkeyname) => (
                                                    <li key={subkeyname}>
                                                        <span>{subitem.name}</span>
                                                        <div className="switch_box box_1">
                                                            <input type="checkbox" className="switch_1" 
                                                            checked={subitem.visible} 
                                                            onChange={() => dispatch(changeOverLayersAction(overlayers, item.name, subitem.name, !subitem.visible))}/>
                                                        </div>
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        ))
                    ): null
                }
            </Accordion>
        </>
    )
}

export default OverlayersComponent
