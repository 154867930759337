/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react'
import { Modal } from 'react-bootstrap'
import {  useSelector } from 'react-redux'
import Viewer, { Worker } from '@phuocng/react-pdf-viewer'
import '@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css';

const PdfModalComponent = (props) => {

    const pdf = useSelector(store => store.pdfviewer.results)

    return pdf ?(
        <>
            <Modal className="ewisemaps-lg-modal" {...props} size="lg" aria-labelledby="PdfModalComponent" centered>
                <Modal.Header closeButton>
                    <Modal.Title id="PdfModalComponent">
                       {pdf.title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js">
                    <Viewer fileUrl={pdf.file} />
                </Worker>
                </Modal.Body>
            </Modal>
        </>   
    ): null
    
}

export default PdfModalComponent