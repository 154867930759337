import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getParamsByOrganizationAction } from '../../../services/ducks/params-by-organization';
import { getFiltersSocioeconomicalByParamsAction } from '../../../services/ducks/filters-socioeconomical-by-params.duck';
import LoadingComponent from '../LoadingComponent/LoadingComponent';
import { setAnalyzerById } from '../../../services/ducks/analizer.duck';
import { getSectorsAction } from '../../../services/ducks/b4b-mexico.duck';
import { getDemographicCategoriesAction } from '../../../services/ducks/demographic-mexico.duck';
import decodeCache from '../../../helpers/cache/decode';

const InitComponent = () => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => {
      dispatch(getParamsByOrganizationAction());
      dispatch(getFiltersSocioeconomicalByParamsAction());
      dispatch(setAnalyzerById(decodeCache('app-web-analisis-id')));
      dispatch(getSectorsAction());
      dispatch(getDemographicCategoriesAction());
      setLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, [dispatch]);

  return (
    <>
      <LoadingComponent data={loading} />
    </>
  );
};

export default InitComponent;
