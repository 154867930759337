/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import './SearchCustomStringComponent.css'
import { Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { getSearchCustomAction } from '../../../services/ducks/search-custom.duck'
import { getCoordinatesAction } from '../../../services/ducks/geocoding.duck'

const SearchCustomStringComponent = () => {

    const dispatch = useDispatch()

    const params = useSelector(store => store.paramsByOrganization.results)
    const search = useSelector(store => store.searchCustom.results)

    const [modalShow, setModalShow] = useState(false)
    const [results, setResults] = useState([])
    const [waiting, setWaiting] = useState(false)
    const [input, setInput] = useState(null)

    const searchFromInput = (column, layer, text) =>  {
        setResults([])
        if(!text || text === '') return
        setWaiting(true)
        dispatch(getSearchCustomAction(column, layer, text))
        let  timer = setTimeout( () => {
            setResults(search)
            setWaiting(false)
        }, 5000)
        return () => clearTimeout(timer)
    }

    const geolocation = (address, lat, lng) => {
        dispatch(getCoordinatesAction(address, lat, lng))
        setModalShow(false)
    }

    return (
        <>
            <li className="activities-item">
                <a onClick={() => setModalShow(true)}><i className="ion-android-search"></i></a>
            </li>
            <Modal className="ewisemaps-lg-modal" show={modalShow} onHide={() => setModalShow(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <i className="ion-android-search"></i> Buscar Información
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="search-info">
                        {
                            params ? (
                                params.map((item, keyname) => (
                                    <div key={keyname}>
                                        <h6>{item.name}</h6>
                                        {
                                            item.params
                                            .sort(function(a, b){
                                                if(a.name < b.name) { return -1 }
                                                if(a.name > b.name) { return 1 }
                                                return 0
                                            }).map((subitem) => (
                                                subitem.id === 'custom_var_one' ? (
                                                    <div key={subitem.id}>
                                                        <input type="text" placeholder={`Buscar ${subitem.name}`} onChange={e => setInput(e.target.value)}/>
                                                        <button className="search-btn" onClick={() => searchFromInput(subitem.id, item.id, input)}>Buscar</button>
                                                        <div className="results">
                                                            {
                                                                results ? (
                                                                    results.map(i => (
                                                                        i.lat !== 0 && (
                                                                            <div className="result" key={i.customVarOne}>
                                                                                <p>{subitem.name}: {i.customVarOne}</p>
                                                                                <button onClick={() => geolocation(i.customVarOne, i.lat, i.lng)}>Ir</button>
                                                                            </div>
                                                                        )
                                                                    ))
                                                                ): null
                                                            }
                                                            {
                                                                waiting && (<h6>Buscando...</h6>)
                                                            }
                                                        </div>
                                                    </div>
                                                ): null
                                            ))
                                        }
                                    </div>
                                ))
                            ): null
                        }
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default SearchCustomStringComponent
