import React from 'react';
import './FeaturesMobileComponent.css';
import { useSelector } from 'react-redux';
import { Accordion, Card, Button } from 'react-bootstrap';
import LayerNseAmaiComponent from '../LayerNseAmaiComponent/LayerNseAmaiComponent';
import LayerGeotargetComponent from '../LayerGeotargetComponent/LayerGeotargetComponent';
import LayerStateMexicoComponent from '../LayerStateMexicoComponent/LayerStateMexicoComponent';
import LayerMunicipalityMexicoComponent from '../LayerMunicipalityMexicoComponent/LayerMunicipalityMexicoComponent';
import LayerColonyMexicoComponent from '../LayerColonyMexicoComponent/LayerColonyMexicoComponent';
import LayerClientsADTComponent from '../LayerClientsADTComponent/LayerClientsADTComponent';
import LayerSectorComponent from '../LayerSectorComponent/LayerSectorComponent';

const FeaturesMobileComponent = () => {
  const features = useSelector((store) => store.featureinfo.results);
  const filtered = features.filter((item) => item.data.length > 0);
  return (
    <>
      <Accordion className="list-features">
        {
                    filtered ? (
                      filtered.map((item, keyname) => (
                        <Card key={keyname}>
                          {
                                    item.data.length > 0 && (
                                    <Card.Header>
                                      <Accordion.Toggle as={Button} variant="link" eventKey={item.name}>
                                        {item.name}
                                        {' '}
                                        <i className="fas fa-plus" />
                                      </Accordion.Toggle>
                                    </Card.Header>
                                    )
                                }
                          <Accordion.Collapse eventKey={item.name}>
                            <Card.Body>
                              {
                                            item.name === 'Clientes' && (
                                            <LayerClientsADTComponent features={item.data} />
                                            )
                                        }
                              {
                                            item.name === 'NSE/AMAI' && (
                                            <LayerNseAmaiComponent features={item.data} />
                                            )
                                        }
                              {
                                            item.name === 'geoTarget360' && (
                                            <LayerGeotargetComponent features={item.data} />
                                            )
                                        }
                              {
                                            item.name === 'Potencial NSE/AMAI' && (
                                            <LayerNseAmaiComponent features={item.data} />
                                            )
                                        }
                              {
                                            item.name === 'Potencial geoTarget360' && (
                                            <LayerGeotargetComponent features={item.data} />
                                            )
                                        }
                              {
                                            item.name === 'Estados de México' && (
                                            <LayerStateMexicoComponent features={item.data} />
                                            )
                                        }
                              {
                                            item.name === 'Municipios de México' && (
                                            <LayerMunicipalityMexicoComponent features={item.data} />
                                            )
                                        }
                              {
                                            item.name === 'Colonias de México' && (
                                            <LayerColonyMexicoComponent features={item.data} />
                                            )
                                        }
                              {
                                            item.name === 'Actividades legislativas, gubernamentales, de impartición de justicia y de organismos internacionales y extraterritoriales'
                                            || item.name === 'Agricultura, cría y explotación de animales, aprovechamiento forestal, pesca y caza'
                                            || item.name === 'Comercio al por mayor'
                                            || item.name === 'Comercio al por menor'
                                            || item.name === 'Construcción'
                                            || item.name === 'Corporativos'
                                            || item.name === 'Generación, transmisión, distribución y comercialización de energía eléctrica, suministro de agua y de gas natural por ductos al consumidor final'
                                            || item.name === 'Industrias manufactureras'
                                            || item.name === 'Información en medios masivos'
                                            || item.name === 'Minería'
                                            || item.name === 'Otros servicios excepto actividades gubernamentales'
                                            || item.name === 'Servicios de alojamiento temporal y de preparación de alimentos y bebidas'
                                            || item.name === 'Servicios de apoyo a los negocios y manejo de residuos, y servicios de remediación'
                                            || item.name === 'Servicios de esparcimiento culturales y deportivos, y otros servicios recreativos'
                                            || item.name === 'Servicios de salud y de asistencia social'
                                            || item.name === 'Servicios educativos'
                                            || item.name === 'Servicios financieros y de seguros'
                                            || item.name === 'Servicios inmobiliarios y de alquiler de bienes muebles e intangibles'
                                            || item.name === 'Servicios profesionales, científicos y técnicos'
                                            || item.name === 'Transportes, correos y almacenamiento' ? (
                                              <LayerSectorComponent features={item.data} />
                                              ) : null
                                        }
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                      ))
                    ) : null
                }
      </Accordion>
    </>
  );
};

export default FeaturesMobileComponent;
