import PDF1 from "../../assets/pdf/1.pdf";
import PDF2 from "../../assets/pdf/2.pdf";
import PDF3 from "../../assets/pdf/3.pdf";
import PDF4 from "../../assets/pdf/4.pdf";
import PDF5 from "../../assets/pdf/5.pdf";
import PDF6 from "../../assets/pdf/6.pdf";
import PDF7 from "../../assets/pdf/7.pdf";
import PDF8 from "../../assets/pdf/8.pdf";
import PDF9 from "../../assets/pdf/9.pdf";
import PDF10 from "../../assets/pdf/10.pdf";
import PDF11 from "../../assets/pdf/11.pdf";
import PDF12 from "../../assets/pdf/12.pdf";
import PDF13 from "../../assets/pdf/13.pdf";
import PDF14 from "../../assets/pdf/14.pdf";
import PDF15 from "../../assets/pdf/15.pdf";
import PDF16 from "../../assets/pdf/16.pdf";
import PDF17 from "../../assets/pdf/17.pdf";

// consts
const initData = {
  results: [],
};

// types
const GET_PDF_VIEWER_SUCCESS = "GET_PDF_VIEWER_SUCCESS";

// reducers
export default function pdfviewerReducer(state = initData, action) {
  switch (action.type) {
    case GET_PDF_VIEWER_SUCCESS:
      return { ...state, results: action.payload };
    default:
      return state;
  }
}

// actions
export const getPDFViewerSelectedAction = (x) => (dispatch) => {
  let pdfFile;
  switch (x.tipology_id) {
    case 1:
      pdfFile = PDF1;
      break;
    case 2:
      pdfFile = PDF2;
      break;
    case 3:
      pdfFile = PDF3;
      break;
    case 4:
      pdfFile = PDF4;
      break;
    case 5:
      pdfFile = PDF5;
      break;
    case 6:
      pdfFile = PDF6;
      break;
    case 7:
      pdfFile = PDF7;
      break;
    case 8:
      pdfFile = PDF8;
      break;
    case 9:
      pdfFile = PDF9;
      break;
    case 10:
      pdfFile = PDF10;
      break;
    case 11:
      pdfFile = PDF11;
      break;
    case 12:
      pdfFile = PDF12;
      break;
    case 13:
      pdfFile = PDF13;
      break;
    case 14:
      pdfFile = PDF14;
      break;
    case 15:
      pdfFile = PDF15;
      break;
    case 16:
      pdfFile = PDF16;
      break;
    case 17:
      pdfFile = PDF17;
      break;
    default:
      pdfFile = PDF1;
      break;
  }

  try {
    dispatch({
      type: GET_PDF_VIEWER_SUCCESS,
      payload: {
        id: x.tipology_id,
        title: x.clase_tipo,
        file: pdfFile,
      },
    });
  } catch (error) {
    console.error(error);
  }
};
