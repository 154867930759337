import axios from 'axios';

// consts
const initData = {
  results: [],
  tab: 'maps',
  loading: false,
};

// types
const GET_FEATUREINFO_SUCCESS = 'GET_FEATUREINFO_SUCCESS';
const GET_FEATUREINFO_EMPTY = 'GET_FEATUREINFO_EMPTY';
const GET_TABS_MAP_PANEL_SUCESS = 'GET_TABS_MAP_PANEL_SUCESS';
const GET_FEATUREINFO_LOADING = 'GET_FEATUREINFO_LOADING';

// reducers
export default function featureinfoReducer(state = initData, action) {
  switch (action.type) {
    case GET_FEATUREINFO_SUCCESS:
      return { ...state, ...action.payload, loading: false };
    case GET_FEATUREINFO_EMPTY:
      return { ...state, results: action.payload };
    case GET_TABS_MAP_PANEL_SUCESS:
      return { ...state, tab: action.payload };
    case GET_FEATUREINFO_LOADING:
      return { ...state, loading: action.payload };
    default:
      return state;
  }
}

// actions
export const emptyFeatureInfoAction = () => (dispatch) => {
  try {
    dispatch({
      type: GET_FEATUREINFO_EMPTY,
      payload: [],
    });
  } catch (error) {
    console.error(error);
  }
};

export const getFeatureInfoAction = (features) => async (dispatch) => {
  if (!features) {
    return;
  }

  try {
    const resp = [];
    let feature = [];
    let filterjson = [];
    const bbox = `${features.bbox._southWest.lng}%2C${features.bbox._southWest.lat}%2C${features.bbox._northEast.lng}%2C${features.bbox._northEast.lat}`;
    const viewparams = (x) => x.replace(/\\,/g, '%5C,');

    for (let i = 0; i < features.layers.length; i++) {
      feature.push(
        await axios.get(
          `${features.layers[i].server}/${features.layers[i].workspace}/${features.layers[i].service}?`
                    + '&service=wms'
                    + `&version=${features.layers[i].version
                    }&request=GetFeatureInfo`
                    + '&info_format=text/javascript'
                    + `&query_layers=${features.layers[i].layer
                    }&styles=${features.layers[i].style
                    }&viewparams=${viewparams(features.layers[i].viewparams)
                    }&layers=${features.layers[i].layer
                    }&x=${Math.round(features.e.containerPoint.x)
                    }&y=${Math.round(features.e.containerPoint.y)
                    }&SRS=${features.layers[i].srs
                    }&WIDTH=${features.e.target._size.x
                    }&HEIGHT=${features.e.target._size.y
                    }&BBOX=${bbox
                    }&feature_count=100`,
        ),
      );
    }

    feature = feature.map((item) => JSON.parse(item.data.substring(14, item.data.length - 1)).features);
    filterjson = feature.filter((item) => item.length > 0);

    if (filterjson.length > 0) {
      for (let i = 0; i < features.layers.length; i++) {
        resp.push({
          name: features.layers[i].name,
          data: feature[i],
        });
      }
      dispatch({
        type: GET_FEATUREINFO_SUCCESS,
        payload: {
          results: resp,
          tab: 'features',
        },
      });
    } else {
      dispatch({
        type: GET_FEATUREINFO_SUCCESS,
        payload: {
          results: [],
          tab: 'layers',
        },
      });
    }
  } catch (error) {
    console.error(error);
  }
};

export const getTabsMapPanelAction = (x) => (dispatch) => {
  dispatch({
    type: GET_TABS_MAP_PANEL_SUCESS,
    payload: x,
  });
};

export const loadingFeatures = () => (dispatch) => {
  dispatch({
    type: GET_FEATUREINFO_LOADING,
    payload: true,
  });
};
