import encodeCache from '../../helpers/cache/encode';
import decodeCache from '../../helpers/cache/decode';

// consts
const mapdefault = {
  lat: 0.0,
  lng: 0.0,
  zoom: 0.0,
  minZoom: 3,
  maxZoom: 19,
  zoomControl: false,
};

const initData = {
  results: mapdefault,
};

// types
const GET_MAP_DEFAULT_SUCESS = 'GET_MAP_DEFAULT_SUCESS';
const CHANGE_MAP_DEFAULT_SUCESS = 'CHANGE_MAP_DEFAULT_SUCESS';

// reducers
export default function mapdefaultReducer(state = initData, action) {
  switch (action.type) {
    case GET_MAP_DEFAULT_SUCESS:
      return { ...state, results: action.payload };
    case CHANGE_MAP_DEFAULT_SUCESS:
      return { ...state, results: action.payload };
    default:
      return state;
  }
}

// actions
export const getMapDefaultAction = (zoom = 3, lat = 0.0, lng = 0.0) => (dispatch) => {
  if (decodeCache('ewisemaps-map-config')) {
    dispatch({
      type: GET_MAP_DEFAULT_SUCESS,
      payload: decodeCache('ewisemaps-map-config'),
    });
    return;
  }

  try {
    mapdefault.lat = lat;
    mapdefault.lng = lng;
    mapdefault.zoom = zoom;
    dispatch({
      type: GET_MAP_DEFAULT_SUCESS,
      payload: mapdefault,
    });
    encodeCache('ewisemaps-map-config', mapdefault);
  } catch (error) {
    console.error(error);
  }
};

export const changeMapDefaultAction = (zoom = 3, lat = 0.0, lng = 0.0) => (dispatch) => {
  try {
    mapdefault.lat = lat;
    mapdefault.lng = lng;
    mapdefault.zoom = zoom;
    dispatch({
      type: CHANGE_MAP_DEFAULT_SUCESS,
      payload: mapdefault,
    });
    encodeCache('ewisemaps-map-config', mapdefault);
  } catch (error) {
    console.error(error);
  }
};
