import axios from 'axios';
import encodeCache from '../../helpers/cache/encode';
import decodeCache from '../../helpers/cache/decode';
import userAuth from '../../helpers/auth/userAuth';

// configuration
const config = {
  method: '',
  url: '',
  data: '',
  headers: { Authorization: '' },
};

// consts
const initData = {
  results: [],
  loading: false,
  success: null,
  id: null,
  dto: null,
  name: null,
};

const cors = process.env.REACT_APP_CORS;
const back_end_analizer = process.env.REACT_APP_BACK_END_ANALIZER;

// types
const SET_ANALIZER_SUCCESS = 'SET_ANALIZER_SUCCESS';
const SET_ANALIZER_WARNING = 'SET_ANALIZER_WARNING';
const SET_ANALIZER_ERROR = 'SET_ANALIZER_ERROR';
const ANALIZER_HIDE_SUCCESS = 'ANALIZER_HIDE_SUCCESS';
const RESET_ANALIZER = 'RESET_ANALIZER';
const LOADING_ANALIZER = 'LOADING_ANALIZER';

// reducers
export default function analizerReducer(state = initData, action) {
  switch (action.type) {
    case SET_ANALIZER_SUCCESS:
      return {
        ...state, ...action.payload, loading: false, success: 1,
      };
    case SET_ANALIZER_WARNING:
      return { ...state, loading: false, success: 2 };
    case SET_ANALIZER_ERROR:
      return { ...state, loading: false, success: 3 };
    case ANALIZER_HIDE_SUCCESS:
      return { ...state, success: null };
    case RESET_ANALIZER:
      return { ...state, results: [] };
    case LOADING_ANALIZER:
      return { ...state, loading: true };
    default:
      return state;
  }
}

// actions
export const setAnalizerAction = (name_analysis) => async (dispatch) => {
  const auth = userAuth();

  if (!auth) return;
  if (!name_analysis || name_analysis === '') return;

  dispatch({ type: LOADING_ANALIZER });

  config.method = 'post';
  config.url = `${cors}${back_end_analizer}/wise-analyzer/analyze`;
  config.headers.Authorization = `Bearer ${auth.access_token}`;

  const filters_by_params = decodeCache('app-web-filters-by-params').results;
  const filters_by_socioeconomicals = decodeCache('app-web-filters-socioeconomical-by-params').results;
  const filters_ids = decodeCache('app-web-filters-mexico-political-entities-ids');

  const result = {
    analysisName: name_analysis,
    layerId: 1,
    general: false,
    username: auth.username,
    organizationSysName: 'adt',
    customVarTwo: getDataString(filters_by_params, 'custom_var_two'),
    customVarThree: getDataString(filters_by_params, 'custom_var_three'),
    customVarFour: getDataString(filters_by_params, 'custom_var_four'),
    customVarFive: getDataString(filters_by_params, 'custom_var_five'),
    customDateOne: getDataDate(filters_by_params, 'custom_date_one'),
    customDateTwo: getDataDate(filters_by_params, 'custom_date_two'),
    tipologyId: getDataSocioeconomical(filters_by_socioeconomicals, 'geotarget'),
    catNseId: getDataSocioeconomical(filters_by_socioeconomicals, 'amai'),
    ...filters_ids,
  };

  Object.keys(result).forEach((key) => {
    if (result[key].length === 0) delete result[key];
  });

  try {
    config.data = result;
    const resp = await (await axios(config)).data;

    if (resp.resultLayersList[0].rows === 0) {
      config.data = '';
      config.method = 'delete';
      config.url = `${cors}${back_end_analizer}/wise-analyzer/analyze/${resp.wiseAnalysisId}`;
      await (await axios(config)).data;
      dispatch({ type: SET_ANALIZER_WARNING });
      return;
    }

    getDataById(resp.wiseAnalysisId).then(
      (success) => {
        dispatch({
          type: SET_ANALIZER_SUCCESS,
          payload: {
            results: success.resultLayersList,
            id: success.wiseAnalysisId,
            dto: success.wiseAnalysisDTO,
            name: success.analysisName,
          },
        });
        encodeCache('app-web-analisis-id', success.wiseAnalysisId);
      }, (error) => {
        console.log(error);
        dispatch({
          type: SET_ANALIZER_ERROR,
        });
      },
    );
  } catch (error) {
    if (error.response.data.code === 'analysis.zero.results') {
      console.log(error.response.data);
      dispatch({ type: SET_ANALIZER_WARNING });
      return;
    }
    dispatch({
      type: SET_ANALIZER_ERROR,
    });
  }
};

export const setAnalyzerById = (id) => async (dispatch) => {
  if (!id || id === 'null') return;

  getDataById(id).then(
    (success) => {
      dispatch({
        type: SET_ANALIZER_SUCCESS,
        payload: {
          results: success.resultLayersList,
          id: success.wiseAnalysisId,
          dto: success.wiseAnalysisDTO,
          name: success.analysisName,
        },
      });
      encodeCache('app-web-analisis-id', success.wiseAnalysisId);
    }, (error) => {
      console.log(error);
      dispatch({
        type: SET_ANALIZER_ERROR,
      });
    },
  );
};

export const hideMessageAnalizerAction = () => (dispatch) => {
  dispatch({
    type: ANALIZER_HIDE_SUCCESS,
  });
};

export const resetAnalizerAction = () => (dispatch) => {
  dispatch({
    type: RESET_ANALIZER,
  });
};

// helpers
const getDataString = (items, id) => {
  const index = items.findIndex((item) => item.id === id);
  const { data } = items[index];
  const valids = data.filter((item) => item.active === true);
  if (data.length === valids.length) return [];
  return valids.map((item) => item.key);
};

const getDataDate = (items, id) => {
  const index = items.findIndex((item) => item.id === id);
  const change = items[index].data[0];
  const original = items[index].data[1];
  const compare = JSON.stringify(change) === JSON.stringify(original);
  if (!compare) return change;
  return [];
};

const getDataSocioeconomical = (items, id) => {
  const index = items.findIndex((item) => item.id === id);
  const { data } = items[index];
  const valids = data.filter((item) => item.active === true);
  if (data.length === valids.length) return [];
  return valids.map((item) => item.id);
};

const getDataById = async (id) => {
  if (!id) return;
  const auth = userAuth();
  if (!auth) return;
  config.method = 'get';
  config.url = `${cors}${back_end_analizer}/wise-analyzer/analysis/${id}`;
  config.headers.Authorization = `Bearer ${auth.access_token}`;
  try {
    const resp = await (await axios(config)).data;
    return resp;
  } catch (error) {
    console.log(error);
  }
};
