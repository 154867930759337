import res from '../../helpers/basemaps/basemaps';
import encodeCache from '../../helpers/cache/encode';
import decodeCache from '../../helpers/cache/decode';

const response = res();

// consts
const initData = {
  results: [],
};

// types
const GET_BASEMAPS_SUCCESS = 'GET_BASEMAPS_SUCCESS';
const CHANGE_BASEMAPS_SUCESS = 'CHANGE_BASEMAPS_SUCESS';

// reducers
export default function basemapsReducer(state = initData, action) {
  switch (action.type) {
    case GET_BASEMAPS_SUCCESS:
      return { ...state, results: action.payload };
    case CHANGE_BASEMAPS_SUCESS:
      return { ...state, basemapSelected: action.payload };
    default:
      return state;
  }
}

// actions
export const getBaseMapsAction = () => (dispatch) => {
  try {
    dispatch({
      type: GET_BASEMAPS_SUCCESS,
      payload: response,
    });
  } catch (error) {
    console.error(error);
  }
};

export const changeBaseMapsAction = (x = 'https://mt3.google.com/vt/lyrs=m&x={x}&y={y}&z={z}') => (dispatch) => {
  if (decodeCache('ewisemaps-map-basemaps')) {
    dispatch({
      type: CHANGE_BASEMAPS_SUCESS,
      payload: decodeCache('ewisemaps-map-basemaps'),
    });
    return;
  }
  try {
    const resp = response.filter((data) => data.url === x);
    dispatch({
      type: CHANGE_BASEMAPS_SUCESS,
      payload: resp[0],
    });
    encodeCache('ewisemaps-map-basemaps', resp[0]);
  } catch (error) {
    console.error(error);
  }
};

export const changeBaseMapsSelectedAction = (x) => (dispatch) => {
  try {
    const resp = response.filter((data) => data.url === x);
    dispatch({
      type: CHANGE_BASEMAPS_SUCESS,
      payload: resp[0],
    });
    encodeCache('ewisemaps-map-basemaps', resp[0]);
  } catch (error) {
    console.error(error);
  }
};
