import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './MenuHorizontalComponent.css';
import { useDispatch, useSelector } from 'react-redux';
import {
  useLocation, withRouter, Link, Route,
} from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import PrintReportComponent from '../PrintReportComponent/PrintReportComponent';
import { logoutUserAction } from '../../../services/ducks/auth.duck';
import GeocodingComponent from '../GeocodingComponent/GeocodingComponent';
import MapPanelMobileComponent from '../MapPanelMobileComponent/MapPanelMobileComponent';
import FilterComponent from '../FilterComponent/FilterComponent';
import HistoricalComponent from '../HistoricalComponent/HistoricalComponent';
import SearchCustomStringComponent from '../SearchCustomStringComponent/SearchCustomStringComponent';

const MenuHorizontalComponent = (props) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const username = useSelector((store) => store.auth.username);

  const [menuMobile, setMenuMobile] = useState(false);

  const logOut = () => {
    dispatch(logoutUserAction());
    props.history.push('/');
  };

  const toProfile = () => {
    props.history.push('/profile');
  };

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      href="#"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </a>
  ));

  return (
    <>
      {menuMobile && (
        <nav id="ewisemaps-nav-mobile">
          <ul className="animate__animated animate__fadeInLeft">
            <Link to="/main">
              <li>
                <i className="ion-map" />
                {' '}
                Mapa inteligente
              </li>
            </Link>
            <Link to="/report">
              <li>
                <i className="ion-stats-bars" />
                Reportes
              </li>
            </Link>
          </ul>
          <button onClick={() => setMenuMobile(!menuMobile)} />
        </nav>
      )}
      <nav id="ewisemaps-nav-horizontal">
        <div className="menu-navbar">
          <a onClick={() => setMenuMobile(!menuMobile)}>
            <i className="ion-navicon-round" />
          </a>
        </div>
        {location.pathname === '/main' && (
          <div className="search">
            <GeocodingComponent />
          </div>
        )}
        <div className="activities">
          <ul className="activities-items">
            {location.pathname === '/main' && (
              <>
                <MapPanelMobileComponent />
                <SearchCustomStringComponent />
              </>
            )}
            {location.pathname === '/report' && <PrintReportComponent />}
            <FilterComponent />
            <HistoricalComponent />
            <li className="menu-user-item">
              <Dropdown alignRight>
                <Dropdown.Toggle as={CustomToggle} id="ewisemaps-user-actions">
                  <div className="menu-user-icon"><i className="far fa-user-circle" /><span>{username}</span></div>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    className="ewisemaps-item-actions"
                    eventKey="profile"
                    onClick={() => toProfile()}
                  >
                    <i className="far fa-user" />
                    <span>Actualizar Contraseña</span>
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="ewisemaps-item-actions"
                    eventKey="logout"
                    onClick={() => logOut()}
                  >
                    <i className="fas fa-sign-out-alt" />
                    <span>Salir</span>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
};

MenuHorizontalComponent.propTypes = {
  history: PropTypes.objectOf(Route).isRequired,
};

export default withRouter(MenuHorizontalComponent);
