import React, { useState } from 'react';
import './FilterCustomDateComponent.css';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { useDispatch } from 'react-redux';
import { changeFilterCustomDateAction } from '../../../services/ducks/filters-by-params.duck';

const FilterCustomDateComponent = (props) => {
  const dispatch = useDispatch();

  const datesTransform = () => {
    if (props.data.data.length === 0) return [];
    const dates = props.data.data[0];
    const start = dates[0];
    const end = dates[1];
    return [new Date(start * 1000), new Date(end * 1000)];
  };
  const [dates, setDates] = useState(datesTransform());

  const onChangeDate = (e) => {
    setDates(e);
    dispatch(changeFilterCustomDateAction(props.data.id, e));
  };

  return (
    <section className="filter-custom-date">
      <h5>{props.data.name}</h5>
      {
                dates.length > 0 ? (
                  <div className="range">
                    <h6>Seleccionar el rango de tiempo</h6>
                    <DateRangePicker
                      onChange={onChangeDate}
                      value={dates}
                    />
                  </div>
                ) : (<h5>No existen registros</h5>)
            }

    </section>
  );
};

export default FilterCustomDateComponent;
