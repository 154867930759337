// consts
const initData = {
  loading: false,
};

// types
const LOADING_SUCESS = 'LOADING_SUCESS';

// reducers
export default function printReportReducer(state = initData, action) {
  switch (action.type) {
    case LOADING_SUCESS:
      return { ...state, loading: action.payload };
    default:
      return state;
  }
}

// actions
export const loadingReport = (x) => (dispatch) => {
  dispatch({
    type: LOADING_SUCESS,
    payload: x,
  });
};
