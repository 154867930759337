import React, { useState } from 'react';
import './FeaturesDesktopComponent.css';
import { useSelector } from 'react-redux';
import {
  Tab, Row, Nav, Col,
} from 'react-bootstrap';
import LayerNseAmaiComponent from '../LayerNseAmaiComponent/LayerNseAmaiComponent';
import LayerGeotargetComponent from '../LayerGeotargetComponent/LayerGeotargetComponent';
import LayerStateMexicoComponent from '../LayerStateMexicoComponent/LayerStateMexicoComponent';
import LayerMunicipalityMexicoComponent from '../LayerMunicipalityMexicoComponent/LayerMunicipalityMexicoComponent';
import LayerColonyMexicoComponent from '../LayerColonyMexicoComponent/LayerColonyMexicoComponent';
import LayerClientsADTComponent from '../LayerClientsADTComponent/LayerClientsADTComponent';
import LayerSectorComponent from '../LayerSectorComponent/LayerSectorComponent';
import LayerCoberturaADTComponent from "../LayerCoberturaADTComponent/LayerCoberturaADTComponent";
import LayerZonasVentaDTComponent from "../LayerZonasVentaDTComponent/LayerZonasVentaDTComponent";
import PotentialADTComponent from "../PotentialADTComponent/PotentialADTComponent";

const FeaturesDesktopComponent = () => {
  const features = useSelector((store) => store.featureinfo.results);
  const filtered = features.filter((item) => item.data.length > 0);
  const [view, setView] = useState(null);
  const [active, setActive] = useState('');

  const getView = (name, data) => {
    switch (name) {
      case 'Clientes':
        setActive(name);
        setView(<LayerClientsADTComponent features={data} />);
        break;
      case 'NSE/AMAI':
        setActive(name);
        setView(<LayerNseAmaiComponent features={data} />);
        break;
      case 'Potencial NSE/AMAI':
        setActive(name);
        setView(<LayerNseAmaiComponent features={data} />);
        break;
      case 'geoTarget360':
        setActive(name);
        setView(<LayerGeotargetComponent features={data} />);
        break;
      case 'Potencial geoTarget360':
        setActive(name);
        setView(<LayerGeotargetComponent features={data} />);
        break;
      case 'Estados de México':
        setActive(name);
        setView(<LayerStateMexicoComponent features={data} />);
        break;
      case 'Municipios de México':
        setActive(name);
        setView(<LayerMunicipalityMexicoComponent features={data} />);
        break;
      case 'Colonias de México':
        setActive(name);
        setView(<LayerColonyMexicoComponent features={data} />);
        break;
      case 'Botón de Emergencia':
        setActive(name);
        setView(<LayerCoberturaADTComponent features={data} />);
        break;
      case 'Zonas de Venta ADT':
        setActive(name);
        setView(<LayerZonasVentaDTComponent features={data} />);
        break;
      case 'Potencial ADT (Mckinsey)':
        setActive(name);
        setView(<PotentialADTComponent features={data} />);
        break;
      default:
        setActive(name);
        setView(<LayerSectorComponent features={data} />);
    }
  };

  return (
    <>
      <Tab.Container defaultActiveKey={active}>
        <Row id="features-desktop-tabs">
          <Col xl={2}>
            {
                            filtered ? (
                              filtered.map((item, keyname) => (
                                <Nav variant="pills" className="flex-column" key={keyname}>
                                  {
                                            item.data.length > 0 && (
                                            <Nav.Item>
                                              <Nav.Link onClick={() => getView(item.name, item.data)} eventKey={item.name}>{item.name}</Nav.Link>
                                            </Nav.Item>
                                            )
                                        }
                                </Nav>
                              ))
                            ) : null
                        }

          </Col>
          <Col xl={10}>
            <Tab.Content id="app-features-desktop-section">
              <Tab.Pane eventKey={active}>
                {
                                    view || getView(filtered[0].name, filtered[0].data)
                                }
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </>
  );
};

export default FeaturesDesktopComponent;
