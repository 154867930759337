import axios from 'axios';
import encodeCache from '../../helpers/cache/encode';
import decodeCache from '../../helpers/cache/decode';
import userAuth from '../../helpers/auth/userAuth';

// configuration
const config = {
  method: '',
  url: '',
  data: '',
  headers: { Authorization: '' },
};

const cors = process.env.REACT_APP_CORS;
const back_end_data = process.env.REACT_APP_BACK_END_DATA;

// consts
const initData = {
  states: [],
  loading: false,
};

// types
const GET_FILTER_STATES_SUCCESS = 'GET_FILTER_STATES_SUCCESS';
const GET_FILTER_STATES_ERROR = 'GET_FILTER_STATES_ERROR';
const SET_ACTIVE_STATE_SUCCESS = 'SET_ACTIVE_STATE_SUCCESS';
const SET_ACTIVE_STATE_ERROR = 'SET_ACTIVE_STATE_ERROR';
const LOADING_POLITICAL_ENTITIES_STATES = 'LOADING_POLITICAL_ENTITIES_STATES';

// reducers
export default function filtersPolicitalEntitiesMexicoStatesReducer(state = initData, action) {
  switch (action.type) {
    case GET_FILTER_STATES_SUCCESS:
      return { ...state, ...action.payload, loading: false };
    case GET_FILTER_STATES_ERROR:
      return { ...initData };
    case SET_ACTIVE_STATE_SUCCESS:
      return { ...state, ...action.payload, loading: false };
    case SET_ACTIVE_STATE_ERROR:
      return { ...initData };

    case LOADING_POLITICAL_ENTITIES_STATES:
      return { ...state, loading: true };
    default:
      return state;
  }
}

// actions
export const getStatesAction = () => async (dispatch) => {
  const auth = userAuth();

  if (!auth) return;

  dispatch({ type: LOADING_POLITICAL_ENTITIES_STATES });

  if (decodeCache('app-web-filters-mexico-political-entities-states')) {
    const resp = decodeCache('app-web-filters-mexico-political-entities-states');
    dispatch({
      type: GET_FILTER_STATES_SUCCESS,
      payload: resp,
    });
    return;
  }

  config.method = 'get';
  config.url = `${cors}${back_end_data}/political/states`;
  config.headers.Authorization = `Bearer ${auth.access_token}`;

  try {
    const res = await (await axios(config)).data;
    const resp = res.map((item) => ({ name: item.name, active: false, id: item.cvegeo }));
    const result = { states: resp };
    dispatch({
      type: GET_FILTER_STATES_SUCCESS,
      payload: result,
    });
    encodeCache('app-web-filters-mexico-political-entities-states', result);
  } catch (error) {
    dispatch({ type: GET_FILTER_STATES_ERROR });
  }
};

export const setActiveStateAction = (id, active) => (dispatch) => {
  dispatch({ type: LOADING_POLITICAL_ENTITIES_STATES });

  try {
    const data = decodeCache('app-web-filters-mexico-political-entities-states').states;
    const index = data.findIndex((item) => item.id === id);
    data[index].active = active;
    const result = { states: data };
    dispatch({
      type: SET_ACTIVE_STATE_SUCCESS,
      payload: result,
    });
    encodeCache('app-web-filters-mexico-political-entities-states', result);
  } catch (error) {
    dispatch({ type: SET_ACTIVE_STATE_ERROR });
  }
};
