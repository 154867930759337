import React from 'react';
import './LayerColonyMexicoComponent.css';

const LayerColonyMexicoComponent = (props) => (
  <div className="colony-mexico-cards">
    {
                props.features.map((item, keyname) => (
                  <div className="colony-mexico-card" key={keyname}>
                    <h5>
                      {' '}
                      {item.properties.sett_name}
                      {' '}
                    </h5>
                    <p>
                      <strong>Municipio :</strong>
                      {' '}
                      {item.properties.mun_name}
                    </p>
                    <p>
                      <strong>Estado: :</strong>
                      {' '}
                      {item.properties.st_name}
                    </p>
                  </div>
                ))
            }
  </div>
);

export default LayerColonyMexicoComponent;
