import { LatLng } from 'leaflet';
// consts
const initData = {
  coordinates: null,
};

// types
const GET_COORDINATES_SUCESS = 'GET_COORDINATES_SUCESS';

// reducers
export default function geocodingReducer(state = initData, action) {
  switch (action.type) {
    case GET_COORDINATES_SUCESS:
      return { ...state, coordinates: action.payload };
    default:
      return state;
  }
}

// actions
export const getCoordinatesAction = (address = null, lat = null, lng = null) => (dispatch) => {
  if (!address) {
    dispatch({
      type: GET_COORDINATES_SUCESS,
      payload: null,
    });
    return;
  }
  const resp = {
    name: address,
    latlng: new LatLng(lat, lng),
  };
  dispatch({
    type: GET_COORDINATES_SUCESS,
    payload: resp,
  });
};
