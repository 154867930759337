import React from 'react';
import MapboxAutocomplete from 'react-mapbox-autocomplete';
import { useDispatch } from 'react-redux';
import { getCoordinatesAction } from '../../../services/ducks/geocoding.duck';

const GeocodingComponent = () => {
  const dispatch = useDispatch();
  const onSelect = (address, lat, lng) => {
    dispatch(getCoordinatesAction(address, lat, lng));
  };

  return (
    <>
      <MapboxAutocomplete
        publicKey={process.env.REACT_APP_MAPBOX_API_ACCESS_TOKEN}
        placeholder="Buscar una dirección"
        onSuggestionSelect={onSelect}
        resetSearch
      />
    </>
  );
};

export default GeocodingComponent;
