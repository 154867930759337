import React, { useState } from 'react';
import './FilterCustomStringComponent.css';
import { useDispatch } from 'react-redux';
import SearchInput, { createFilter } from 'react-search-input';
import { changeFilterCustomStringAction } from '../../../services/ducks/filters-by-params.duck';

const FilterCustomStringComponent = (props) => {
  const dispatch = useDispatch();

  const [items, setItems] = useState(props.data.data);
  const [state, setState] = useState('');
  const [check, setCheck] = useState(false);

  const KEYS_TO_FILTERS = ['key'];

  const searchUpdated = (term) => {
    setState(term);
  };

  const filteredCustomString = items.filter(
    createFilter(state, KEYS_TO_FILTERS),
  );

  const changeSelected = (data, id, key, active) => {
    const timer = setTimeout(() => {
      const index = data.findIndex((item) => item.key === key);
      data[index].active = active;
      setItems([]);
      setItems(data);
      dispatch(changeFilterCustomStringAction(id, items));
    }, 500);
    return () => clearTimeout(timer);
  };

  const checkAll = (id, active) => {
    const timer = setTimeout(() => {
      const resp = items.map((item) => ({ key: item.key, active }));
      setCheck(!active);
      setItems([]);
      setItems(resp);
      dispatch(changeFilterCustomStringAction(id, resp));
    }, 500);
    return () => clearTimeout(timer);
  };

  return (
    <section className="filter-custom-string">
      <h5>{props.data.name}</h5>
      <SearchInput
        className="search-input"
        onChange={searchUpdated}
        placeholder="Buscar"
      />
      <button onClick={() => checkAll(props.data.id, check)}>
        {check ? 'Seleccionar todo' : 'Desactivar todo'}
      </button>
      <ul>
        {filteredCustomString
          .sort((a, b) => {
            if (a.key < b.key) {
              return -1;
            }
            if (a.key > b.key) {
              return 1;
            }
            return 0;
          })
          .map((item, keyname) => (
            <li key={keyname}>
              <a
                onClick={() => changeSelected(items, props.data.id, item.key, !item.active)}
              >
                <span>{item.key}</span>
                <div className="switch_box box_1">
                  <input
                    type="checkbox"
                    className="switch_1"
                    checked={item.active}
                    onChange={() => changeSelected(
                      items,
                      props.data.id,
                      item.key,
                      !item.active,
                    )}
                  />
                </div>
              </a>
            </li>
          ))}
      </ul>
    </section>
  );
};
export default FilterCustomStringComponent;
