// consts
const initData = {
  signin: true,
  signup: false,
  forgot: false,
};

// types
const SIGNIN_SHOW = 'SIGNIN_SHOW';
const SIGNUP_SHOW = 'SIGNUP_SHOW';
const FORGOT_SHOW = 'FORGOT_SHOW';

// reducers
export default function homeActionReducer(state = initData, action) {
  switch (action.type) {
    case SIGNIN_SHOW:
      return {
        ...state, signin: true, signup: false, forgot: false,
      };
    case SIGNUP_SHOW:
      return {
        ...state, signin: false, signup: true, forgot: false,
      };
    case FORGOT_SHOW:
      return {
        ...state, signin: false, signup: false, forgot: true,
      };
    default:
      return state;
  }
}

// actions
export const signinShowAction = () => (dispatch) => {
  dispatch({ type: SIGNIN_SHOW });
};

export const signupShowAction = () => (dispatch) => {
  dispatch({ type: SIGNUP_SHOW });
};

export const forgotShowAction = () => (dispatch) => {
  dispatch({ type: FORGOT_SHOW });
};
