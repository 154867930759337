import {
  createStore, combineReducers, compose, applyMiddleware,
} from 'redux';
import thunk from 'redux-thunk';

import authReducer, { readActiveUserAction } from '../ducks/auth.duck';
import homeActionReducer from '../ducks/home-action.duck';
import forgotPasswordReducer from '../ducks/forgot-password.duck';
import newPasswordReducer from '../ducks/new-password.duck';
import basemapsReducer from '../ducks/basemaps.duck';
import overlayersReducer from '../ducks/overlayers.duck';
import featureinfoReducer from '../ducks/featureinfo.duck';
import mapdeafultReducer from '../ducks/map-default.duck';
import geocodingReducer from '../ducks/geocoding.duck';
import historicalAnalysisReducer from '../ducks/historical-analysis.duck';
import pdfviewerReducer from '../ducks/filter-pdf-viewer.duck';
import paramsByOrganizationReducer from '../ducks/params-by-organization';
import filtersByParamsReducer from '../ducks/filters-by-params.duck';
import filtersSocioeconomicalByParamsReducer from '../ducks/filters-socioeconomical-by-params.duck';
import filtersPolicitalEntitiesMexicoStatesReducer from '../ducks/filters-political-entities-mexico-states.duck';
import filtersPolicitalEntitiesMexicoMunicipalitiesReducer from '../ducks/filters-political-entities-mexico-municipalities.duck';
import filtersPolicitalEntitiesMexicoColoniesReducer from '../ducks/filters-political-entities-mexico-colonies.duck';
import filtersPolicitalEntitiesMexicoLevelReducer from '../ducks/filters-political-entities-mexico-level.duck';
import analizerReducer from '../ducks/analizer.duck';
import printReportReducer from '../ducks/print-report.duck';
import b4bMexicoReducer from '../ducks/b4b-mexico.duck';
import b4bExportReducer from '../ducks/b4b-export.duck';
import demographicMexicoReducer from '../ducks/demographic-mexico.duck';
import searchCustomReducer from '../ducks/search-custom.duck';
import resetPasswordReducer from '../ducks/resetPassword.duck';

const rootReducer = combineReducers({
  auth: authReducer,
  homeAction: homeActionReducer,
  forgotPassword: forgotPasswordReducer,
  newPassword: newPasswordReducer,
  basemaps: basemapsReducer,
  overlayers: overlayersReducer,
  featureinfo: featureinfoReducer,
  mapdefault: mapdeafultReducer,
  geocoding: geocodingReducer,
  pdfviewer: pdfviewerReducer,
  paramsByOrganization: paramsByOrganizationReducer,
  filtersByParams: filtersByParamsReducer,
  filtersSocioeconomicalByParams: filtersSocioeconomicalByParamsReducer,
  filtersPolicitalEntitiesMexicoStates: filtersPolicitalEntitiesMexicoStatesReducer,
  filtersPolicitalEntitiesMexicoMunicipalities: filtersPolicitalEntitiesMexicoMunicipalitiesReducer,
  filtersPolicitalEntitiesMexicoColonies: filtersPolicitalEntitiesMexicoColoniesReducer,
  filtersPolicitalEntitiesMexicoLevel: filtersPolicitalEntitiesMexicoLevelReducer,
  analizer: analizerReducer,
  historicalAnalysis: historicalAnalysisReducer,
  printReport: printReportReducer,
  b4bMexico: b4bMexicoReducer,
  b4bExport: b4bExportReducer,
  demographicMexico: demographicMexicoReducer,
  searchCustom: searchCustomReducer,
  resetPassword: resetPasswordReducer,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function generateStore() {
  const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));
  readActiveUserAction()(store.dispatch);
  return store;
}
