import React from 'react';
import MenuHorizontalComponent from '../MenuHorizontalComponent/MenuHorizontalComponent';
import MenuVerticalComponent from '../MenuVerticalComponent/MenuVerticalComponent';

const MenuComponent = () => (
  <>
    <MenuHorizontalComponent />
    <MenuVerticalComponent />
  </>
);
export default MenuComponent;
