import React from 'react';
import './LayerClientsADTComponent.css';

const LayerClientsADTComponent = (props) => {
  const date_format = (time) => {
    const timestamp = Date.parse(time);
    const event = new Date(timestamp);
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return event.toLocaleDateString('es-MX', options);
  };

  const get_img = (key) => {
    switch (key) {
      case 'A':
        return 'https://dl.dropbox.com/s/vw2nm8q8gmr1f05/adt-active.png?dl=0';
      case 'C':
        return 'https://dl.dropbox.com/s/qyho2em9tf0k03i/adt-cancelled.png?dl=0';
      case 'I':
        return 'https://dl.dropbox.com/s/jslxjo5yom9zb1f/adt-neutral.png?dl=0';
      case 'IP':
        return 'https://dl.dropbox.com/s/jslxjo5yom9zb1f/adt-neutral.png?dl=0';
      case 'JCI':
        return 'https://dl.dropbox.com/s/acpoy9ydwrrlstc/jci.png?dl=0';
      case 'VM':
        return 'https://dl.dropbox.com/s/gfkyegprmrd14ag/adt-vm.png?dl=0';
      default:
        return 'https://dl.dropbox.com/s/vw2nm8q8gmr1f05/adt-active.png?dl=0';
    }
  };

  const set_background = (key) => {
    const style = { borderBottom: '' };
    switch (key) {
      case 'A':
        style.borderBottom = '#0061AA 1px solid';
        break;
      case 'C':
        style.borderBottom = '#AB060F 1px solid';
        break;
      case 'I':
        style.borderBottom = '#FFC600 1px solid';
        break;
      case 'IP':
        style.borderBottom = '#FFC600 1px solid';
        break;
      case 'JCI':
        style.borderBottom = '#7BB707 1px solid';
        break;
      case 'VM':
        style.borderBottom = '#FE00FF 1px solid';
        break;
      default:
        style.borderBottom = '#0061AA 1px solid';
        break;
    }
    return style;
  };

  return (
    <div className="clients-adt-cards">
      {
                props.features.map((item, keyname) => (
                  <div className="clients-adt-card" key={keyname}>
                    <h5 style={set_background(item.properties.custom_var_four)}></h5>
                    <div className="logo">
                      <img src={get_img(item.properties.custom_var_four)} alt={item.properties.custom_var_four} />
                    </div>
                    <div className="side">
                      <h6>Información de contrato</h6>
                      <p>
                        <strong>Estatus: </strong>
                        {' '}
                        {item.properties.custom_var_four}
                      </p>
                      <p>
                        <strong>Tipo de Contrato: </strong>
                        {' '}
                        {item.properties.custom_var_two}
                      </p>
                      <p>
                        <strong>Equipo: </strong>
                        {' '}
                        {item.properties.custom_var_three}
                      </p>
                      <p>
                        <strong>Tipo de Cliente: </strong>
                        {' '}
                        {item.properties.custom_var_five}
                      </p>
                      {
                            item.properties.custom_date_one && (
                            <p>
                              <strong>Fecha de Contratación :</strong>
                              {' '}
                              {date_format(item.properties.custom_date_one)}
                            </p>
                            )
                        }
                      {
                            item.properties.custom_date_two && (
                            <p>
                              <strong>Fecha de Cancelación :</strong>
                              {' '}
                              {date_format(item.properties.custom_date_two)}
                            </p>
                            )
                        }
                    </div>
                    <div className="address">
                      {
                                item.properties.query ? (<p>{item.properties.query}</p>) : (
                                  <p>
                                    {item.properties.numero_exterior && (`${item.properties.numero_exterior}, `)}
                                    {item.properties.calle}
                                    ,
                                    {item.properties.cp}
                                    ,
                                    {item.properties.colonia}
                                    ,
                                    {item.properties.ciudad}
                                    ,
                                    {item.properties.estado}
                                  </p>
                                )
                            }
                      {
                                item.properties.format && (
                                <p>
                                  <strong>Formato georreferenciado: </strong>
                                  <br />
                                  {item.properties.format}
                                </p>
                                )
                            }
                    </div>
                  </div>
                ))
            }
    </div>
  );
};

export default LayerClientsADTComponent;
