import React, { useEffect } from 'react';
import './MainComponent.css';
import { withRouter } from 'react-router-dom';
import MenuComponent from '../../templates/MenuComponent/MenuComponent';
import MapComponent from '../../templates/MapComponent/MapComponent';
import MapPanelComponent from '../../templates/MapPanelComponent/MapPanelComponent';
import FeaturesComponent from '../../templates/FeaturesComponent/FeaturesComponent';
import InitComponent from '../../templates/InitComponent/InitComponent';
import userAuth from '../../../helpers/auth/userAuth';

const MainComponent = (props) => {
  const authentication = userAuth();

  useEffect(() => {
    if (!authentication) props.history.push('/');
  }, [authentication, props, props.history]);

  return (
    <div>
      <InitComponent />
      <MenuComponent />
      <section id="app-map-section">
        <div className="app-cards">
          <div className="app-card-min">
            <MapPanelComponent />
          </div>
          <div className="app-card-max">
            <MapComponent />
          </div>
        </div>
      </section>
      <FeaturesComponent />
    </div>
  );
};

export default withRouter(MainComponent);
