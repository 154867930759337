import React from 'react'
import './LayerStateMexicoComponent.css'

const LayerStateMexicoComponent = (props) => {

    return (
        <div className="state-mexico-cards">
            {
                props.features.map((item, keyname)=> (
                    <div className="state-mexico-card" key={keyname}>
                        <h5> {item.properties.nomgeo} </h5>
                    </div>
                ))
            }
        </div>   
    )
    
}

export default LayerStateMexicoComponent