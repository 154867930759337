import React, { useEffect } from 'react';
import './HomeComponent.css';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ParticleBackground from 'react-particle-backgrounds';
import LoginComponent from '../../templates/LoginComponent/LoginComponent';
import MockupDevicesComponent from '../../templates/MockupDevicesComponent/MockupDevicesComponent';
import ForgotPasswordComponent from '../../templates/ForgotPasswordComponent/ForgotPasswordComponent';
import userAuth from '../../../helpers/auth/userAuth';

const HomeComponent = (props) => {
  const auth = useSelector((store) => store.auth.auth);
  const signin = useSelector((store) => store.homeAction.signin);
  const forgot = useSelector((store) => store.homeAction.forgot);
  const authentication = userAuth();

  useEffect(() => {
    if (auth) props.history.push('/main');
  }, [auth, props, props.history]);

  const settings = {
    particle: {
      particleCount: 100,
      color: '#fff',
      minSize: 1,
      maxSize: 4,
    },
    velocity: {
      minSpeed: 0.2,
      maxSpeed: 0.4,
    },
    opacity: {
      minOpacity: 0,
      maxOpacity: 0.6,
      opacityTransitionTime: 10000,
    },
  };

  return !authentication ? (
    <section id="ewisemaps-home">
      <MockupDevicesComponent />
      <ParticleBackground settings={settings} />
      {
        signin && (<LoginComponent />)
      }
      {
        forgot && (<ForgotPasswordComponent />)
      }
    </section>
  ) : null;
};

export default withRouter(HomeComponent);
