import axios from 'axios';
import getAnalisisGeneral from './getAnalisisGeneral';
import encodeCache from '../cache/encode';

const cors = process.env.REACT_APP_CORS;
const back_end_data = process.env.REACT_APP_BACK_END_DATA;

const config = {
  method: '',
  url: '',
  data: '',
  headers: { Authorization: '' },
};

export default async function getLayers(username, token, role) {
  config.method = 'get';
  config.url = `${cors}${back_end_data}/layers/shared/${username}`;
  config.headers.Authorization = `Bearer ${token}`;

  try {
    let result = await (await axios(config)).data;
    if (result.length === 0) {
      config.url = `${cors}${back_end_data}/layers/${username}`;
      result = await (await axios(config)).data;
      getAnalisisGeneral(token, username, result[0], role).then((id) => encodeCache('app-web-analisis-id', id));
      return result;
    }
    getAnalisisGeneral(token, username, result[0], role).then((id) => encodeCache('app-web-analisis-id', id));
    return result;
  } catch (error) {
    return null;
  }
}
