export default function chartProcessDemographic(res, data) {
  const result = [];
  for (const key in res) {
    if (res.hasOwnProperty(key)) {
      result.push({
        name: getName(key, data),
        total: res[key],
        format: res[key].toLocaleString('es-MX', { maximumFractionDigits: 0 }),
      });
    }
  }

  return result;
}

const getName = (name, data) => {
  const index = data.findIndex((item) => item.columnName === name);
  return data[index].name;
};
