import React from 'react';
import './ChartBarVerticalComponent.css';
import {
  ResponsiveContainer, BarChart, XAxis, YAxis, Tooltip, Bar, Brush, LabelList,
} from 'recharts';

const ChartBarVerticalComponent = (props) => {
  const chart = props.data;
  const setColor = (x) => {
    const style = {
      color: x,
    };
    return style;
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active) {
      return (
        <div className="custom-tooltip">
          <p>{label}</p>
          {
                payload.map((item, keyname) => (
                  <p key={keyname}>
                    <strong style={setColor(item.fill)}>Total: </strong>
                    {item.value.toLocaleString('es-MX', { maximumFractionDigits: 0 })}
                  </p>
                ))
              }
        </div>
      );
    }
    return null;
  };

  return (
    <div className="chart-bar-vertical">
      <ResponsiveContainer>
        <BarChart
          layout="vertical"
          margin={{
            top: 20, right: 20, left: 20, bottom: 20,
          }}
          data={chart}
        >
          <XAxis type="number" />
          <YAxis type="category" dataKey="name" width={50} />
          <Tooltip content={<CustomTooltip />} />
          <Brush dataKey="name" height={30} stroke="#BBBBBB" />
          <Bar dataKey="total" maxBarSize={17} fill={props.color}>
            {
                        props.info && (<LabelList dataKey="format" position="right" />)
                      }
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ChartBarVerticalComponent;
