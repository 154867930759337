import React from 'react';
import './ChartLineComponent.css';
import {
  XAxis, YAxis, Tooltip, LabelList, ResponsiveContainer, Brush, LineChart, Line,
} from 'recharts';

const ChartLineComponent = (props) => {
  const chart = props.data;
  const setColor = (x) => {
    const style = {
      color: x,
    };
    return style;
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active) {
      return (
        <div className="custom-tooltip">
          <p>{label}</p>
          {
                    payload.map((item, keyname) => (
                      <p key={keyname}>
                        <strong style={setColor(item.fill)}>Total: </strong>
                        {item.value.toLocaleString('es-MX', { maximumFractionDigits: 0 })}
                      </p>
                    ))
                }
        </div>
      );
    }
    return null;
  };

  return (
    <div className="chart-line">
      <ResponsiveContainer>
        <LineChart
          margin={{
            top: 20, right: 20, left: 20, bottom: 20,
          }}
          data={chart}
        >
          <XAxis dataKey="name" />
          <YAxis width={30} />
          <Tooltip content={<CustomTooltip />} />
          <Brush dataKey="name" height={30} stroke="#BBBBBB" />
          <Line type="monotone" dataKey="total" stroke={props.color} fill={props.color}>
            {
                            props.info && (<LabelList dataKey="format" position="top" />)
                        }
          </Line>
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ChartLineComponent;
