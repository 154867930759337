import React from 'react';
import './FilterLocationComponent.css';
import { Tabs, Tab } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import FilterStateComponent from '../FilterStateComponent/FilterStateComponent';
import FilterMunicipalityComponent from '../FilterMunicipalityComponent/FilterMunicipalityComponent';
import FilterColonyComponent from '../FilterColonyComponent/FilterColonyComponent';
import { setPoliticalEntitiesMexicoLevel } from '../../../services/ducks/filters-political-entities-mexico-level.duck';

const FilterLocationComponent = () => {
  const dispatch = useDispatch();
  const level = useSelector((store) => store.filtersPolicitalEntitiesMexicoLevel.level);

  return (
    <section id="ewisemaps-filter-location-options">
      <h5>Lugar de Procedencia</h5>
      <Tabs activeKey={level} onSelect={(k) => dispatch(setPoliticalEntitiesMexicoLevel(k))}>
        <Tab eventKey="byState" title="Por Estado">
          {
                        level === 'byState' && (<FilterStateComponent />)
                    }
        </Tab>
        <Tab eventKey="byCity" title="Por Municipio">
          {
                        level === 'byCity' && (<FilterMunicipalityComponent />)
                    }
        </Tab>
        <Tab eventKey="byColony" title="Por Colonia">
          {
                        level === 'byColony' && (<FilterColonyComponent />)
                    }
        </Tab>
      </Tabs>
    </section>
  );
};

export default FilterLocationComponent;
