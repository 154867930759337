import openstreetmaps from '../../assets/images/openstreetmaps.PNG';
import googlesatellite from '../../assets/images/google-satellite.PNG';
import googlemaps from '../../assets/images/google-maps.PNG';
import googlehybrid from '../../assets/images/google-hybrid.PNG';
import cartowhite from '../../assets/images/carto-white.PNG';
import cartoblack from '../../assets/images/carto-black.PNG';

export default function res() {
  const data = [
    {
      url: 'https://tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution: ' OpenStreetMap Mapnick',
      name: 'OpenStreetMap',
      img: openstreetmaps,
    },
    {
      url: 'https://www.google.cn/maps/vt?lyrs=s@189&gl=cn&x={x}&y={y}&z={z}',
      attribution: ' Google Satellite',
      name: 'Google Satellite',
      img: googlesatellite,
    },
    {
      url: 'https://mt3.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',
      attribution: ' Google Maps',
      name: 'Google Maps',
      img: googlemaps,
    },
    {
      url: 'https://mt3.google.com/vt/lyrs=y&x={x}&y={y}&z={z}',
      attribution: ' Google Hybrid',
      name: 'Google Hybrid',
      img: googlehybrid,
    },
    {
      url: 'https://cartodb-basemaps-a.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png',
      attribution: ' CARTO Positron',
      name: 'CARTO Light',
      img: cartowhite,
    },
    {
      url: 'http://a.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}.png',
      attribution: ' CARTO dark',
      name: 'CARTO Dark',
      img: cartoblack,
    },
  ];

  return data;
}
