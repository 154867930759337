import React from 'react';
import './FilterMessageComponent.css';
import { Toast } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { hideMessageAnalizerAction } from '../../../services/ducks/analizer.duck';

const FilterMessageComponent = () => {
  const dispatch = useDispatch();
  const success = useSelector((store) => store.analizer.passwordChangeStatus);
  return (
    <>
      {success ? (
        <Toast
          className={`toast-message-filter message-${success}`}
          onClose={() => dispatch(hideMessageAnalizerAction())}
          delay={3000}
          autohide
        >
          {success === 1 && (
            <>
              <Toast.Header>
                <strong className="mr-auto">¡Excelente! ;)</strong>
              </Toast.Header>
              <Toast.Body>
                Tu búsqueda ha sido exitosa
                {' '}
                <br />
                Revisa el mapa y los reportes
              </Toast.Body>
            </>
          )}
          {success === 2 && (
            <>
              <Toast.Header>
                <strong className="mr-auto">¡Oops! :S</strong>
              </Toast.Header>
              <Toast.Body>
                No se encontró ningún resultado relacionado a lo que buscas
                <br />
                Intenta de otra forma
              </Toast.Body>
            </>
          )}
          {success === 3 && (
            <>
              <Toast.Header>
                <strong className="mr-auto">¡Error! :(</strong>
              </Toast.Header>
              <Toast.Body>
                Hay un problema aquí dos opciones:
                {' '}
                <br />
                1.- Comprueba tu conexión a red
                <br />
                2.- Contacta al administrador
              </Toast.Body>
            </>
          )}
        </Toast>
      ) : null}
    </>
  );
};
export default FilterMessageComponent;
