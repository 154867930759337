/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react'
import './MockupDevicesComponent.css'
import './MockupDesktopComponent.css'
import img1Desktop from '../../../assets/images/screenshot-1.png'
import img2Desktop from '../../../assets/images/screenshot-2.png'


const MockupDevicesComponent = () => {

    const getDesktopWallpaper = () => {
        let data = [img1Desktop, img2Desktop]
        let random = Math.floor(Math.random() * data.length)
        let style = {
            backgroundImage: 'url('+data[random]+')',
            backgroundPosition: 'center',
            backgroundRepeat: 'np-repeat',
            backgroundSize : 'cover'
        }
        return style
    }

    return (
        <>
            <div className="mockup-devices">
                <div className="css-mb with-glare">
                    <div className="mb-display-position">
                        <div className="mb-display">
                        <div className="mb-screen-position">
                            <div className="mb-screen" style={getDesktopWallpaper()}>
                                
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="mb-body"></div>
                    <div className="mb-bottom-cover"></div>
                </div>
            </div>
        </>
    )
}

export default MockupDevicesComponent
