import React from 'react'
import './LayerSectorComponent.css'

const LayerSectorComponent = (props) => {

    const set_background = (key) => {
        let style = {borderBottom: ''}
        switch (key) {
            case 'Actividades legislativas, gubernamentales, de impartición de justicia y de organismos internacionales y extraterritoriales':
                style.borderBottom = '#8F9FA0 1px solid'
                break;
            case 'Agricultura, cría y explotación de animales, aprovechamiento forestal, pesca y caza':
                style.borderBottom = '#C75C5C 1px solid'
                break;
            case 'Comercio al por mayor':
                style.borderBottom = '#4C6AA5 1px solid'
                break;
            case 'Comercio al por menor':
                style.borderBottom = '#FDB039 1px solid'
                break;
            case 'Construcción':
                style.borderBottom = '#E0995E 1px solid'
                break;
            case 'Corporativos':
                style.borderBottom = '#13828E 1px solid'
                break;
            case 'Generación, transmisión, distribución y comercialización de energía eléctrica, suministro de agua y de gas natural por ductos al consumidor final':
                style.borderBottom = '#0093D0 1px solid'
                break;
            case 'Industrias manufactureras':
                style.borderBottom = '#434343 1px solid'
                break;
            case 'Información en medios masivos':
                style.borderBottom = '#A247D6 1px solid'
                break;
            case 'Minería':
                style.borderBottom = '#866857 1px solid'
                break;
            case 'Otros servicios excepto actividades gubernamentales':
                style.borderBottom = '#00CC99 1px solid'
                break;
            case 'Servicios de alojamiento temporal y de preparación de alimentos y bebidas':
                style.borderBottom = '#FFAD9E 1px solid'
                break;
            case 'Servicios de apoyo a los negocios y manejo de residuos, y servicios de remediación':
                style.borderBottom = '#B1C635 1px solid'
                break;
            case 'Servicios de esparcimiento culturales y deportivos, y otros servicios recreativos':
                style.borderBottom = '#5DB946 1px solid'
                break;
            case 'Servicios de salud y de asistencia social':
                style.borderBottom = '#CD4669 1px solid'
                break;
            case 'Servicios educativos':
                style.borderBottom = '#DE4445 1px solid'
                break;
            case 'Servicios financieros y de seguros':
                style.borderBottom = '#019145 1px solid'
                break;
            case 'Servicios inmobiliarios y de alquiler de bienes muebles e intangibles':
                style.borderBottom = '#FBB117 1px solid'
                break;
            case 'Servicios profesionales, científicos y técnicos':
                style.borderBottom = '#4A88DA 1px solid'
                break;
            case 'Transportes, correos y almacenamiento':
                style.borderBottom = '#FF802C 1px solid'
                break;
            default:
                style.borderBottom = '#0061AA 1px solid'
                break;
        }
        return style;
    }

    const get_img = (key) => {
        switch (key) {
            case 'Actividades legislativas, gubernamentales, de impartición de justicia y de organismos internacionales y extraterritoriales':
                return 'https://dl.dropbox.com/s/gcq0n5wz8jv96ve/goverment.png?dl=0'
            case 'Agricultura, cría y explotación de animales, aprovechamiento forestal, pesca y caza':
                return 'https://dl.dropbox.com/s/p49iup8gpgbko64/farming.png?dl=0'
            case 'Comercio al por mayor':
                return 'https://dl.dropbox.com/s/r2mi8i9s620ozg7/store_mayor1.png?dl=0'
            case 'Comercio al por menor':
                return 'https://dl.dropbox.com/s/m54o0wlncj6r8ct/store_menor.png?dl=0'
            case 'Construcción':
                return 'https://dl.dropbox.com/s/7xdlq39i88a8h5a/construction.png?dl=0'
            case 'Corporativos':
                return 'https://dl.dropbox.com/s/la8bjwyct6rf8ns/building.png?dl=0'
            case 'Generación, transmisión, distribución y comercialización de energía eléctrica, suministro de agua y de gas natural por ductos al consumidor final':
                return 'https://dl.dropbox.com/s/562shno7t1cj65f/energy.png?dl=0'
            case 'Industrias manufactureras':
                return 'https://dl.dropbox.com/s/isl0lzn0kym2qq3/industry.png?dl=0'
            case 'Información en medios masivos':
                return 'https://dl.dropbox.com/s/jenqpix4un4eez4/communication.png?dl=0'
            case 'Minería':
                return 'https://dl.dropbox.com/s/20ct62vetrogjxd/minery.png?dl=0'
            case 'Otros servicios excepto actividades gubernamentales':
                return 'https://dl.dropbox.com/s/kcfb2h5hdgri3n5/services.png?dl=0'
            case 'Servicios de alojamiento temporal y de preparación de alimentos y bebidas':
                return 'https://dl.dropbox.com/s/ykl4a163sug1chu/hostelry.png?dl=0'
            case 'Servicios de apoyo a los negocios y manejo de residuos, y servicios de remediación':
                return 'https://dl.dropbox.com/s/bzmbi5jyhynxwkl/recycle.png?dl=0'
            case 'Servicios de esparcimiento culturales y deportivos, y otros servicios recreativos':
                return 'https://dl.dropbox.com/s/1iwumkfjwklgbya/sport.png?dl=0'
            case 'Servicios de salud y de asistencia social':
                return 'https://dl.dropbox.com/s/p8zxkl4t30ar72e/health.png?dl=0'
            case 'Servicios educativos':
                return 'https://dl.dropbox.com/s/5kkh00bmx34eo4s/education.png?dl=0'
            case 'Servicios financieros y de seguros':
                return 'https://dl.dropbox.com/s/oie6rrikm0d0z7a/money.png?dl=0'
            case 'Servicios inmobiliarios y de alquiler de bienes muebles e intangibles':
                return 'https://dl.dropbox.com/s/xqe85sk9631bvyu/real%20estate.png?dl=0'
            case 'Servicios profesionales, científicos y técnicos':
                return 'https://dl.dropbox.com/s/quojkedym442kq6/profesional.png?dl=0'
            case 'Transportes, correos y almacenamiento':
                return 'https://dl.dropbox.com/s/mvq1ht2pxr4gzvy/mail.png?dl=0'
            default:
                return ''
        }
    }


    return (
        <div className="sector-geob4b-cards">
            {
                props.features.map((item, keyname)=> (
                    <div className="sector-geob4b-card" key={keyname}>
                        <h5 style={set_background(item.properties.sector)}>{item.properties.business_name}</h5>
                        <div className="logo">
                            <img src={get_img(item.properties.sector)} alt={item.properties.sector}/>
                        </div>
                        <div className="side">
                        <p><strong>Giro :</strong> {item.properties.activity}</p>
                        <p><strong>Empleados: </strong> {item.properties.size}</p>
                        <p><strong>Correo: </strong> {item.properties.email}</p>
                        <p><strong>Teléfono: </strong> {item.properties.phone_number}</p>
                        <p><strong>Web: </strong> {item.properties.webpage}</p>
                        </div>
                        <div className="address">
                            <p>
                                <strong>Dirección : </strong> 
                                {item.properties.street_name} 
                                {item.properties.street_number}, 
                                {item.properties.postal_code_geo}, 
                                {item.properties.municipio}, 
                                {item.properties.estado}
                            </p>
                        </div>
                    </div>
                ))
            }
        </div> 
    )
}

export default LayerSectorComponent
