export default function chartStadistics(data, period) {
  if (data.length === 0) return;

  let interval = '';

  switch (period) {
    case 'day':
      interval = 'Día';
      break;
    case 'week':
      interval = 'Semana';
      break;
    case 'month':
      interval = 'Mes';
      break;
    case 'year':
      interval = 'Año';
      break;
    default:
      // eslint-disable-next-line no-unused-vars
      interval = '';
      break;
  }

  const result = [
    {
      name: 'Resultado promedio',
      total: getAverage(data),
      format: getAverage(data).toLocaleString('es-MX', { maximumFractionDigits: 0 }),
    },
    {
      name: `Mayores resultados por ${interval}`,
      ...getMax(data),
    },
    {
      name: `Menores resultados por ${interval}`,
      ...getMin(data),
    },
  ];

  return result;
}

const getMax = (elmt) => {
  const max = Math.max.apply(Math, elmt.map((item) => item.total));
  const format = max.toLocaleString('es-MX', { maximumFractionDigits: 0 });
  return { total: max, format };
};

const getMin = (elmt) => {
  const min = Math.min.apply(Math, elmt.map((item) => item.total));
  const format = min.toLocaleString('es-MX', { maximumFractionDigits: 0 });
  return { total: min, format };
};

const getAverage = (elmt) => {
  if (!elmt) return;
  let sum = 0;
  for (let i = 0; i < elmt.length; i++) {
    sum += parseInt(elmt[i].total, 10);
  }
  const avg = sum / elmt.length;
  return parseInt(avg);
};
