import axios from 'axios';
import encodeCache from '../../helpers/cache/encode';
import decodeCache from '../../helpers/cache/decode';
import userAuth from '../../helpers/auth/userAuth';

// configuration
const config = {
  method: '',
  url: '',
  data: '',
  headers: { Authorization: '' },
};

// consts
const initData = {
  level: 'byState',
  ids: null,
  loading: false,
  places: null,
};

const cors = process.env.REACT_APP_CORS;
const back_end_cvegeo = process.env.REACT_APP_BACK_END_CVEGEO;
const back_end_colony = process.env.REACT_APP_BACK_END_COLONY;

// types
const SET_POLITICAL_ENTITIES_LEVEL_MEXICO_SUCCESS = 'SET_POLITICAL_ENTITIES_LEVEL_MEXICO_SUCCESS';
const SET_IDS_LEVEL_SUCCESS = 'SET_IDS_LEVEL_SUCCESS';
const SET_PLACES_LEVEL_SUCCESS = 'SET_PLACES_LEVEL_SUCCESS';
const LOADING_POLITICAL_ENTITIES_LEVEL = 'LOADING_POLITICAL_ENTITIES_LEVEL';

// reducers
export default function filtersPolicitalEntitiesMexicoLevelReducer(state = initData, action) {
  switch (action.type) {
    case SET_POLITICAL_ENTITIES_LEVEL_MEXICO_SUCCESS:
      return { ...state, ...action.payload };
    case SET_IDS_LEVEL_SUCCESS:
      return { ...state, ids: action.payload, loading: false };
    case SET_PLACES_LEVEL_SUCCESS:
      return { ...state, places: action.payload, loading: false };
    case LOADING_POLITICAL_ENTITIES_LEVEL:
      return { ...state, loading: true };
    default:
      return state;
  }
}

// actions
export const setPoliticalEntitiesMexicoLevel = (level) => (dispatch) => {
  const result = { level };
  dispatch({
    type: SET_POLITICAL_ENTITIES_LEVEL_MEXICO_SUCCESS,
    payload: result,
  });
};

export const getIdsByStateAction = () => async (dispatch) => {
  if (!decodeCache('app-web-filters-mexico-political-entities-states')) {
    const result = { cvegeoEstado: [] };
    dispatch({
      type: SET_IDS_LEVEL_SUCCESS,
      payload: result,
    });
    encodeCache('app-web-filters-mexico-political-entities-ids', result);
    return;
  }

  dispatch({ type: LOADING_POLITICAL_ENTITIES_LEVEL });

  const data = decodeCache('app-web-filters-mexico-political-entities-states').states;
  const actives = data.filter((item) => item.active === true);
  const result = { cvegeoEstado: [] };
  result.cvegeoEstado = actives.map((item) => item.id);
  dispatch({
    type: SET_IDS_LEVEL_SUCCESS,
    payload: result,
  });
  encodeCache('app-web-filters-mexico-political-entities-ids', result);
};

export const getIdsByMunicipalityAction = () => async (dispatch) => {
  if (!decodeCache('app-web-filters-mexico-political-entities-municipalities')) {
    const result = { cvegeoMunicipio: [] };
    dispatch({
      type: SET_IDS_LEVEL_SUCCESS,
      payload: result,
    });
    encodeCache('app-web-filters-mexico-political-entities-ids', result);
    return;
  }

  dispatch({ type: LOADING_POLITICAL_ENTITIES_LEVEL });

  const data = decodeCache('app-web-filters-mexico-political-entities-municipalities').municipalities_selected;
  const result = { cvegeoMunicipio: [] };
  result.cvegeoMunicipio = data.map((item) => item.id);
  dispatch({
    type: SET_IDS_LEVEL_SUCCESS,
    payload: result,
  });
  encodeCache('app-web-filters-mexico-political-entities-ids', result);
};

export const getIdsByColoniesAction = () => (dispatch) => {
  if (!decodeCache('app-web-filters-mexico-political-entities-colonies')) {
    const result = { coloniaId: [] };
    dispatch({
      type: SET_IDS_LEVEL_SUCCESS,
      payload: result,
    });
    encodeCache('app-web-filters-mexico-political-entities-ids', result);
    return;
  }

  dispatch({ type: LOADING_POLITICAL_ENTITIES_LEVEL });

  const data = decodeCache('app-web-filters-mexico-political-entities-colonies').colonies_selected;
  const result = { coloniaId: [] };
  result.coloniaId = data.map((item) => item.id);
  dispatch({
    type: SET_IDS_LEVEL_SUCCESS,
    payload: result,
  });
  encodeCache('app-web-filters-mexico-political-entities-ids', result);
};

export const setPlacesPoliticalLevelsAction = (places) => async (dispatch) => {
  const auth = userAuth();

  if (!auth) return;
  if (!places) return;

  config.method = 'get';
  config.headers.Authorization = `Bearer ${auth.access_token}`;

  if (places.cvegeoEstado.length > 0) {
    const pl = [];
    for (let i = 0; i < places.cvegeoEstado.length; i++) {
      config.url = `${cors}${back_end_cvegeo}?cvegeo=${places.cvegeoEstado[i]}`;
      try {
        const data = await (await axios(config)).data;
        pl.push(`${data.type}: ${data.name}`);
      } catch (error) {
        console.log(error.response);
        pl.push(' ');
      }
    }
    dispatch({ type: SET_PLACES_LEVEL_SUCCESS, payload: pl.toString() });
    encodeCache('app-web-places', pl.toString());
    return;
  }

  if (places.cvegeoMunicipio.length > 0) {
    const pl = [];
    for (let i = 0; i < places.cvegeoMunicipio.length; i++) {
      config.url = `${cors}${back_end_cvegeo}?cvegeo=${places.cvegeoMunicipio[i]}`;
      try {
        const data = await (await axios(config)).data;
        pl.push(`${data.type}: ${data.name}`);
      } catch (error) {
        pl.push(' ');
      }
    }
    dispatch({ type: SET_PLACES_LEVEL_SUCCESS, payload: pl.toString() });
    encodeCache('app-web-places', pl.toString());
    return;
  }

  if (places.coloniaId.length > 0) {
    const pl = [];
    for (let i = 0; i < places.coloniaId.length; i++) {
      config.url = `${cors}${back_end_colony}?neighborhoodId=${places.coloniaId[i]}`;
      try {
        const data = await (await axios(config)).data;
        pl.push(`${data.type}: ${data.name}`);
      } catch (error) {
        console.log(error);
        pl.push(' ');
      }
    }
    dispatch({ type: SET_PLACES_LEVEL_SUCCESS, payload: pl.toString() });
    encodeCache('app-web-places', pl.toString());
    return;
  }

  encodeCache('app-web-places', 'Toda la nación');

  dispatch({ type: SET_PLACES_LEVEL_SUCCESS, payload: 'Toda la nación' });
};
