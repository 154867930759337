import React from 'react';
import './ChartBarHorizontalComponent.css';
import {
  ResponsiveContainer, BarChart, LabelList, XAxis, YAxis, Tooltip, Bar, Brush,
} from 'recharts';

const ChartBarHorizontalComponent = (props) => {
  const chart = props.data;

  const setColor = (x) => {
    const style = {
      color: x,
    };
    return style;
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active) {
      return (
        <div className="custom-tooltip">
          <p>{label}</p>
          {
              payload.map((item, keyname) => (
                <p key={keyname}>
                  <strong style={setColor(item.fill)}>Total: </strong>
                  {item.value.toLocaleString('es-MX', { maximumFractionDigits: 0 })}
                </p>
              ))
            }
        </div>
      );
    }
    return null;
  };

  return (
    <div className="chart-bar-horizontal">
      <ResponsiveContainer>
        <BarChart
          margin={{
            top: 20, right: 20, left: 20, bottom: 20,
          }}
          data={chart}
        >
          <XAxis dataKey="name" />
          <YAxis width={30} />
          <Tooltip content={<CustomTooltip />} />
          <Brush dataKey="name" height={30} stroke="#BBBBBB" />
          <Bar dataKey="total" maxBarSize={25} fill={props.color}>
            {
                props.info && (<LabelList dataKey="format" position="top" />)
              }
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ChartBarHorizontalComponent;
