import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Tab, Nav, DropdownButton, Dropdown,
} from 'react-bootstrap';
import FilterLocationComponent from '../FilterLocationComponent/FilterLocationComponent';
import FilterCustomIdComponent from '../FilterCustomIdComponent/FilterCustomIdComponent';
import FilterCustomSocioeconomicalComponent from '../FilterCustomSocioeconomicalComponent/FilterCustomSocioeconomicalComponent';
import FilterCustomDateComponent from '../FilterCustomDateComponent/FilterCustomDateComponent';
import FilterCustomStringComponent from '../FilterCustomStringComponent/FilterCustomStringComponent';
import { getFiltersByParamsAction } from '../../../services/ducks/filters-by-params.duck';

const FilterParamsComponent = () => {
  const dispatch = useDispatch();

  const custom = useSelector((store) => store.filtersByParams.results);
  const socioeconomical = useSelector((store) => store.filtersSocioeconomicalByParams.results);
  const params = useSelector((store) => store.paramsByOrganization.results);
  const auth = useSelector((store) => store.auth.authorities);

  const filters = [...custom, ...socioeconomical];
  const tabs = filters.map((item) => (item.name));

  useEffect(() => {
    dispatch(getFiltersByParamsAction(params, auth));
  }, [auth, dispatch, params]);

  const getFilterCustom = (item) => {
    switch (item.type) {
      case 'string':
        if (item.id !== 'custom_var_one') return <FilterCustomStringComponent data={item} />;
        return <FilterCustomIdComponent data={item} />;
      case 'socioeconomical':
        return <FilterCustomSocioeconomicalComponent data={item} />;
      case 'timestamp':
        return <FilterCustomDateComponent data={item} />;
      default:
        return <></>;
    }
  };

  return (
    <>
      {
                tabs ? (
                  <Tab.Container id="filter-tabs" defaultActiveKey="location">
                    <div className="filter-section-container">
                      <div className="menu-section-options">
                        <Nav variant="pills" className="flex-column">
                          <Nav.Item>
                            <Nav.Link eventKey="location">Ubicación</Nav.Link>
                          </Nav.Item>
                          {
                                        tabs.map((item) => (
                                          <Nav.Item key={item}>
                                            <Nav.Link eventKey={item}>{item}</Nav.Link>
                                          </Nav.Item>
                                        ))
                                    }
                        </Nav>
                        <DropdownButton id="dropdown-filter" title="Filtros">
                          <Dropdown.Item eventKey="location">Ubicación</Dropdown.Item>
                          {
                                        tabs.map((item) => (
                                          <Dropdown.Item key={item} eventKey={item}>{item}</Dropdown.Item>
                                        ))
                                    }
                        </DropdownButton>
                      </div>
                      <div className="filter-section-options">
                        <Tab.Content id="app-filter-section">
                          <Tab.Pane eventKey="location">
                            <FilterLocationComponent />
                          </Tab.Pane>
                          {
                                        filters.map((item) => (
                                          <Tab.Pane key={item.name} eventKey={item.name}>
                                            { getFilterCustom(item)}
                                          </Tab.Pane>
                                        ))
                                    }
                        </Tab.Content>
                      </div>
                    </div>
                  </Tab.Container>
                ) : null
            }
    </>
  );
};

export default FilterParamsComponent;
