import React, { useEffect } from 'react';
import './BasemapsComponent.css';
import { useDispatch, useSelector } from 'react-redux';
import { getBaseMapsAction, changeBaseMapsSelectedAction } from '../../../services/ducks/basemaps.duck';

const BasemapsComponent = () => {
  const dispatch = useDispatch();

  const bases = useSelector((store) => store.basemaps.results);

  useEffect(() => {
    const fetchData = () => {
      dispatch(getBaseMapsAction());
    };
    fetchData();
  }, [dispatch]);

  const setBackground = (x) => {
    const style = {
      backgroundImage: `url(${x})`,
      backgroundPosition: 'center',
      backgroundRepeat: 'np-repeat',
      backgroundSize: 'cover',
    };

    return style;
  };

  const changeBasemap = (url) => {
    dispatch(changeBaseMapsSelectedAction(url));
  };

  return (
    <>
      <ul className="list-basemaps">
        {
                    bases.map((item, keyname) => (
                      <li key={keyname}>
                        <button style={setBackground(item.img)} onClick={() => changeBasemap(item.url)}>
                          <span>
                            {item.name}
                          </span>
                        </button>
                      </li>
                    ))
                }
      </ul>
    </>
  );
};

export default BasemapsComponent;
