export default function permission(username) {
  switch (username) {
    case 'andrz':
      return true;
    case 'amendoza':
      return true;
    case 'jose.sanchez.acosta':
      return true;
    default:
      return false;
  }
}
