import React, { useState } from 'react';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { useDispatch } from 'react-redux';
import { setAnalizerAction } from '../../../services/ducks/analizer.duck';

const FilterActionsComponent = () => {
  const dispatch = useDispatch();
  const [state, setState] = useState(format(new Date(), "'ewisemaps análisis '  dd MMMM yyyy  hh:mm:ss", { locale: es }));

  return (
    <div className="actions">
      <input type="text" value={state} placeholder="Escoge un nombe *" onChange={(e) => setState(e.target.value)} />
      <button onClick={() => dispatch(setAnalizerAction(state))}>Analizar</button>
    </div>
  );
};

export default FilterActionsComponent;
