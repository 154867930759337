export default function removeCache() {
  const keys = [];
  for (let i = 0; i < localStorage.length; i++) keys.push(localStorage.key(i));
  const data = keys.filter((item) => item.includes('chart-') || item.includes('app-web'));
  data.map((item) => remove(item));
}

const remove = (id) => {
  localStorage.removeItem(id);
};
