/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import './FilterStateComponent.css';
import { useDispatch, useSelector } from 'react-redux';
import { getStatesAction, setActiveStateAction } from '../../../services/ducks/filters-political-entities-mexico-states.duck';
import { getIdsByStateAction } from '../../../services/ducks/filters-political-entities-mexico-level.duck';

const FilterStateComponent = () => {
  const dispatch = useDispatch();
  const state = useSelector((store) => store.filtersPolicitalEntitiesMexicoStates.states, []);

  useEffect(() => {
    dispatch(getStatesAction());
    dispatch(getIdsByStateAction());
  }, [dispatch]);

  const setIdColonies = (id, active) => {
    const timer = setTimeout(() => {
      dispatch(setActiveStateAction(id, active));
      dispatch(getIdsByStateAction());
    }, 500);
    return () => clearTimeout(timer);
  };

  return state ? (
    <section className="filter-state">
      <ul>
        {
                    state
                      .sort((a, b) => {
                        if (a.name < b.name) { return -1; }
                        if (a.name > b.name) { return 1; }
                        return 0;
                      })
                      .map((item, keyname) => (
                        <li key={keyname}>
                          <a onClick={() => setIdColonies(item.id, !item.active)}>
                            <span>
                              {item.name}
                            </span>
                            <div className="switch_box box_1">
                              <input
                                type="checkbox"
                                className="switch_1"
                                checked={item.active}
                                onChange={() => setIdColonies(item.id, !item.active)}
                              />
                            </div>
                          </a>
                        </li>
                      ))
                }
      </ul>
    </section>
  ) : null;
};

export default FilterStateComponent;
