import axios from 'axios';
import userAuth from '../../helpers/auth/userAuth';

// configuration
const config = {
  method: '',
  url: '',
  data: '',
  headers: { Authorization: '' },
};

// consts
const initData = {
  results: [],
  error: false,
  loading: false,
};

const cors = process.env.REACT_APP_CORS;
const back_end_data = process.env.REACT_APP_BACK_END_DATA;

// types
const GET_PARAMS_BY_ORGANIZATION_SUCCESS = 'GET_PARAMS_BY_ORGANIZATION_SUCCESS';
const GET_PARAMS_BY_ORGANIZATION_ERROR = 'GET_PARAMS_BY_ORGANIZATION_ERROR';

// reducers
export default function paramsByOrganizationReducer(state = initData, action) {
  switch (action.type) {
    case GET_PARAMS_BY_ORGANIZATION_SUCCESS:
      return {
        ...state, results: action.payload, error: false, loading: false,
      };
    case GET_PARAMS_BY_ORGANIZATION_ERROR:
      return { ...state, error: true, loading: false };
    default:
      return state;
  }
}

// actions
export const getParamsByOrganizationAction = () => async (dispatch) => {
  const auth = userAuth();

  if (!auth) return;

  config.method = 'get';
  config.headers.Authorization = `Bearer ${auth.access_token}`;

  try {
    const { layers } = auth;
    const params = [];

    for (let i = 0; i < layers.length; i++) {
      config.url = `${cors}${back_end_data}/layers/mapping/${layers[i].layerId}`;
      params.push(
        {
          id: layers[i].layerId,
          name: layers[i].name,
          params: setFormatAndDepuration(await (await axios(config)).data),
        },
      );
    }

    dispatch({
      type: GET_PARAMS_BY_ORGANIZATION_SUCCESS,
      payload: params,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: GET_PARAMS_BY_ORGANIZATION_ERROR,
    });
  }
};

const setFormatAndDepuration = (res) => {
  const result = res.map((item) => (
    {
      id: item.columnName,
      name: item.displayName,
      type: item.dataType,
    }
  ));
  return result;
};
