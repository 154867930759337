import axios from 'axios';

const cors = process.env.REACT_APP_CORS;
const back_end_auth = process.env.REACT_APP_BACK_END_AUTH;
const client_id = process.env.REACT_APP_CLIENT_ID;

const config = {
  method: '',
  url: '',
  data: '',
  headers: { Authorization: '' },
};

export default async function updateToken(token) {
  const form = new FormData();
  form.append('grant_type', 'refresh_token');
  form.append('refresh_token', token);

  config.method = 'post';
  config.url = `${cors}${back_end_auth}/token`;
  config.headers.Authorization = `${client_id}`;
  config.data = form;

  try {
    return await (await axios(config)).data;
  } catch (error) {
    console.log(error.response.data);
    return null;
  }
}
