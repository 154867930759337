import React from 'react';
import './ResetPasswordComponent.css';
import ParticleBackground from 'react-particle-backgrounds';
import MockupDevicesComponent from '../../templates/MockupDevicesComponent/MockupDevicesComponent';
import NewPasswordComponent from '../../templates/NewPasswordComponent/NewPasswordComponent';

const ResetPasswordComponent = (props) => {
  const settings = {
    particle: {
      particleCount: 100,
      color: '#fff',
      minSize: 1,
      maxSize: 4,
    },
    velocity: {
      minSpeed: 0.2,
      maxSpeed: 0.4,
    },
    opacity: {
      minOpacity: 0,
      maxOpacity: 0.6,
      opacityTransitionTime: 10000,
    },
  };

  return (
    <section id="ewisemaps-home">
      <MockupDevicesComponent />
      <ParticleBackground settings={settings} />
      <NewPasswordComponent token={props.token} />
    </section>
  );
};

export default ResetPasswordComponent;
