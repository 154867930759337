import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Toast } from 'react-bootstrap';
import MenuComponent from '../../templates/MenuComponent/MenuComponent';
import { changePasswordAction, hideNotificationAction } from '../../../services/ducks/resetPassword.duck';
import './ProfileComponent.css';
import LoadingComponent from '../../templates/LoadingComponent/LoadingComponent';

const ProfileComponent = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const {
    register, handleSubmit, errors, watch,
  } = useForm();
  const password = useRef({});
  password.current = watch('newPassword', '');
  const onSubmit = (data,e) => {
    setLoading(true);
    dispatch(changePasswordAction(data.newPassword));
    setLoading(false);
    e.target.reset();
  };

  const passwordChangeStatus = useSelector(
    (store) => store.resetPassword.passwordChangeStatus,
  );

  const newPasswordError = (
    <div className="error">
      <span className="error">*Ingresa la nueva contraseña</span>
    </div>
  );
  const repeatPasswordError = (
    <div className="error">
      <span className="error">*Las contraseñas deben coincidir</span>
    </div>
  );

  return (
    <div>
      <MenuComponent />
      <LoadingComponent data={loading} />
      {passwordChangeStatus ? (
        <Toast
          className={`toast-message-filter message-${passwordChangeStatus}`}
          delay={3000}
          onClose={() => dispatch(hideNotificationAction())}
          autohide
        >
          {passwordChangeStatus === 1 && (
            <>
              <Toast.Header>
                <strong className="mr-auto">¡Hecho!</strong>
              </Toast.Header>
              <Toast.Body>Tu contraseña ha sido actualizada</Toast.Body>
            </>
          )}
          {passwordChangeStatus === 2 && (
            <>
              <Toast.Header>
                <strong className="mr-auto">¡Error!</strong>
              </Toast.Header>
              <Toast.Body>
                Hubo un error al actualizar tu contraseña, contacta al
                administrador
              </Toast.Body>
            </>
          )}
        </Toast>
      ) : null}
      <section id="app-profile-section">
        <div className="app-cards">
          <div className="app-card-min">
            <i className="far fa-user-circle fa-9x" />
          </div>
          <div className="app-card-max">
            <h1 className="title">Actualizar contraseña</h1>
            <form onSubmit={handleSubmit(onSubmit)}>
              <input
                type="password"
                name="newPassword"
                placeholder="Nuevo password"
                className="inputText"
                ref={register({ required: true })}
              />
              {errors.newPassword && newPasswordError}
              <input
                type="password"
                name="repeatPassword"
                placeholder="Repite el nuevo password"
                className="inputText"
                ref={register({
                  validate: (value) => value === password.current,
                })}
              />
              {errors.repeatPassword && repeatPasswordError}
              <div>
                <input
                  className="button"
                  type="submit"
                  value="Actualizar contraseña"
                />
              </div>
            </form>
          </div>
        </div>
      </section>
    </div>
  );
};
export default withRouter(ProfileComponent);
