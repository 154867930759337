import React from 'react';
import {
  Switch, Route, useLocation, Redirect,
} from 'react-router-dom';
import HomeComponent from './components/open/HomeComponent/HomeComponent';
import MainComponent from './components/secure/MainComponent/MainComponent';
import ReportComponent from './components/secure/ReportComponent/ReportComponent';
import PrivacyRoute from './PrivacyRoute';
import ResetPasswordComponent from './components/open/ResetPasswordComponent/ResetPasswordComponent';
import ProfileComponent from './components/secure/ProfileComponent/ProfileComponent';

const Routes = () => {
  const useQuery = () => new URLSearchParams(useLocation().search);

  const query = useQuery();

  const ResetView = ({ name }) => {
    const location = useLocation();
    if (location.pathname !== '/reset-password') return <Redirect to="/" />;
    if (!name) return <Redirect to="/" />;
    return <ResetPasswordComponent token={name} />;
  };

  return (
    <Switch>
      <Route component={HomeComponent} path="/" exact />
      <PrivacyRoute component={MainComponent} path="/main" exact />
      <PrivacyRoute component={ReportComponent} path="/report" exact />
      <PrivacyRoute component={ProfileComponent} path="/profile" exact />
      <ResetView name={query.get('token')} />
    </Switch>
  );
};

export default Routes;
