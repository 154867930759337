import axios from 'axios';
import encodeCache from '../../helpers/cache/encode';
import decodeCache from '../../helpers/cache/decode';
import userAuth from '../../helpers/auth/userAuth';

// configuration
const config = {
  method: '',
  url: '',
  data: '',
  headers: { Authorization: '' },
};

const cors = process.env.REACT_APP_CORS;
const back_end_data = process.env.REACT_APP_BACK_END_DATA;

// consts
const initData = {
  municipalities: [],
  municipalities_selected: [],
  loading: false,
};

// types
const GET_MUNICIPALITIES_BY_STATE_SUCCESS = 'GET_FILTER_MUNICIPALITIES_SUCCESS';
const GET_MUNICIPALITIES_BY_STATE_ERROR = 'GET_FILTER_MUNICIPALITIES_ERROR';
const GET_SELECTED_MUNICIPALITIES_SUCCESS = 'GET_FILTER_MUNICIPALITIES_SUCCESS';
const GET_SELECTED_MUNICIPALITIES_ERROR = 'GET_FILTER_MUNICIPALITIES_ERROR';
const LOADING_POLITICAL_ENTITIES_MUNICIPALITIES = 'LOADING_POLITICAL_ENTITIES_MUNICIPALITIES';

// reducers
export default function filtersPolicitalEntitiesMexicoMunicipalitiesReducer(state = initData, action) {
  switch (action.type) {
    case GET_MUNICIPALITIES_BY_STATE_SUCCESS:
      return { ...state, ...action.payload, loading: false };
    case GET_MUNICIPALITIES_BY_STATE_ERROR:
      return { ...initData };
    case GET_SELECTED_MUNICIPALITIES_SUCCESS:
      return { ...state, ...action.payload, loading: false };
    case GET_SELECTED_MUNICIPALITIES_ERROR:
      return { ...initData };

    case LOADING_POLITICAL_ENTITIES_MUNICIPALITIES:
      return { ...state, loading: true };
    default:
      return state;
  }
}

// actions
export const getMunicipalitiesByState = (id) => async (dispatch) => {
  const auth = userAuth();

  if (!auth) return;

  config.method = 'get';
  config.url = `${cors}${back_end_data}/political/municipality/${id}`;
  config.headers.Authorization = `Bearer ${auth.access_token}`;

  dispatch({ type: LOADING_POLITICAL_ENTITIES_MUNICIPALITIES });

  try {
    const res = await (await axios(config)).data;
    const result = { municipalities: res };
    dispatch({
      type: GET_MUNICIPALITIES_BY_STATE_SUCCESS,
      payload: result,
    });
  } catch (error) {
    dispatch({ type: GET_MUNICIPALITIES_BY_STATE_ERROR });
  }
};

export const getSelectedMunicipalitiesAction = () => (dispatch) => {
  if (!decodeCache('app-web-filters-mexico-political-entities-municipalities')) return;
  const result = decodeCache('app-web-filters-mexico-political-entities-municipalities');
  dispatch({
    type: GET_SELECTED_MUNICIPALITIES_SUCCESS,
    payload: result,
  });
};

export const removeMunicipalityAction = (id) => (dispatch) => {
  const resp = decodeCache('app-web-filters-mexico-political-entities-municipalities');
  const result = {
    municipalities_selected: resp.municipalities_selected.filter((item) => item.id !== id),
  };
  dispatch({
    type: GET_SELECTED_MUNICIPALITIES_SUCCESS,
    payload: result,
  });
};

export const addMunicipalityAction = (item) => (dispatch) => {
  dispatch({ type: LOADING_POLITICAL_ENTITIES_MUNICIPALITIES });

  if (decodeCache('app-web-filters-mexico-political-entities-municipalities')) {
    const result = decodeCache('app-web-filters-mexico-political-entities-municipalities');
    const index = result.municipalities_selected.findIndex((data) => data.id === item.cvegeo);
    if (index !== -1) return;
    result.municipalities_selected.push({ id: item.cvegeo, name: `${item.nomgeo}` });
    dispatch({
      type: GET_SELECTED_MUNICIPALITIES_SUCCESS,
      payload: result,
    });
    encodeCache('app-web-filters-mexico-political-entities-municipalities', result);
    return;
  }

  const result = { municipalities_selected: [] };
  result.municipalities_selected.push({ id: item.cvegeo, name: `${item.nomgeo}` });
  dispatch({
    type: GET_SELECTED_MUNICIPALITIES_SUCCESS,
    payload: result,
  });
  encodeCache('app-web-filters-mexico-political-entities-municipalities', result);
};
