import axios from 'axios';
import FormData from 'form-data';

// configuration
const config = {
  method: '',
  url: '',
  data: '',
  headers: { Authorization: '' },
};

const cors = process.env.REACT_APP_CORS;
const client_id = process.env.REACT_APP_CLIENT_ID;
const back_end_signup = process.env.REACT_APP_BACK_END_SIGNUP;
const back_end_auth = process.env.REACT_APP_BACK_END_AUTH;

// consts
const initData = {
  error: false,
  success: false,
  message: null,
  loading: false,
};

// types
const SEND_EMAIL_FORGOT_PASSWORD_SUCCESS = 'SEND_EMAIL_FORGOT_PASSWORD_SUCCESS';
const SEND_EMAIL_FORGOT_PASSWORD_ERROR = 'SEND_EMAIL_FORGOT_PASSWORD_ERROR';
const SEND_EMAIL_FORGOT_PASSWORD_LOADING = 'SEND_EMAIL_FORGOT_PASSWORD_LOADING';

// reducers
export default function forgotPasswordReducer(state = initData, action) {
  switch (action.type) {
    case SEND_EMAIL_FORGOT_PASSWORD_SUCCESS:
      return {
        ...state, success: true, error: false, message: action.payload, loading: false,
      };
    case SEND_EMAIL_FORGOT_PASSWORD_ERROR:
      return {
        ...state, error: true, success: false, message: action.payload, loading: false,
      };
    case SEND_EMAIL_FORGOT_PASSWORD_LOADING:
      return { ...state, loading: true, message: null };
    default:
      return state;
  }
}

// actions
export const sendEmailForgotPasswordAction = (email) => (dispatch) => {
  dispatch({ type: SEND_EMAIL_FORGOT_PASSWORD_LOADING });

  getToken().then(
    (success) => {
      if (!success) {
        dispatch({
          type: SEND_EMAIL_FORGOT_PASSWORD_ERROR,
          payload: 'Ha ocurrido un error, recarga la página',
        });
        return;
      }
      sendEmail(email, success.access_token).then(
        (complete) => {
          if (complete.code === 'email.sent.success') {
            dispatch({
              type: SEND_EMAIL_FORGOT_PASSWORD_SUCCESS,
              payload: 'Se ha enviado un correo para restaurar tu contraseña',
            });
          }
        },
        (error) => {
          console.log(error.response.data);
          if (error.response.data.code === 'email.not.found') {
            dispatch({
              type: SEND_EMAIL_FORGOT_PASSWORD_ERROR,
              payload: 'Este correo no existe en la plataforma, intenta con otro',
            });
          }
        },
      );
    },
    (error) => console.log(error.response.data),
  );
};

const sendEmail = async (email, token) => {
  config.method = 'get';
  config.url = `${cors}${back_end_signup}/password/reset?email=${email}`;
  config.headers.Authorization = `Bearer ${token}`;
  return await (await axios(config)).data;
};

const getToken = async () => {
  const form = new FormData();
  form.append('grant_type', 'client_credentials');
  form.append('client_id', 'front');

  config.method = 'post';
  config.url = `${cors}${back_end_auth}/token`;
  config.headers.Authorization = `${client_id}`;
  config.data = form;

  try {
    return await (await axios(config)).data;
  } catch (error) {
    console.log(error.response.data);
    return null;
  }
};
