import axios from 'axios';
import { format } from 'date-fns';
import encodeCache from '../../helpers/cache/encode';
import decodeCache from '../../helpers/cache/decode';
import userAuth from '../../helpers/auth/userAuth';
import filterParamsByPermissions from '../../helpers/params/filter-params-by-permissions';

// consts
const initData = {
  results: [],
  ids: [],
  error: false,
  loading: false,
};

const cors = process.env.REACT_APP_CORS;
const back_end_data = process.env.REACT_APP_BACK_END_DATA;

// types
const GET_FILTERS_BY_PARAMS_SUCCESS = 'GET_FILTERS_BY_PARAMS_SUCCESS';
const GET_FILTERS_BY_PARAMS_ERROR = 'GET_FILTERS_BY_PARAMS_ERROR';
const LOADING_FILTERS_BY_PARAMS = 'LOADING_FILTERS_BY_PARAMS';
const CHANGE_FILTER_CUSTOM_STRING_SUCCESS = 'CHANGE_FILTER_CUSTOM_STRING_SUCCESS';
const CHANGE_FILTER_CUSTOM_DATE_SUCCESS = 'CHANGE_FILTER_CUSTOM_DATE_SUCCESS';

// reducers
export default function filtersByParamsReducer(state = initData, action) {
  switch (action.type) {
    case GET_FILTERS_BY_PARAMS_SUCCESS:
      return {
        ...state, ...action.payload, error: false, loading: false,
      };
    case GET_FILTERS_BY_PARAMS_ERROR:
      return { ...state, error: true, loading: false };
    case LOADING_FILTERS_BY_PARAMS:
      return { ...state, loading: true };
    case CHANGE_FILTER_CUSTOM_STRING_SUCCESS:
      return {
        ...state, ...action.payload, error: false, loading: false,
      };
    case CHANGE_FILTER_CUSTOM_DATE_SUCCESS:
      return {
        ...state, ...action.payload, error: false, loading: false,
      };
    default:
      return state;
  }
}

// actions
export const getFiltersByParamsAction = (params = null, authorities = []) => async (dispatch) => {
  filterParamsByPermissions('custom_var_five', authorities);

  const auth = userAuth();

  if (!auth) return;

  const today = format(new Date(), 'dd-MM-yyyy');
  let filterDay = '';

  if (decodeCache('app-web-filters-today')) filterDay = decodeCache('app-web-filters-today');

  if (today === filterDay && decodeCache('app-web-filters-by-params')) {
    const resp = decodeCache('app-web-filters-by-params');
    dispatch({
      type: GET_FILTERS_BY_PARAMS_SUCCESS,
      payload: resp,
    });
    return;
  }

  if (params.length > 0) {
    const config = {
      method: 'get',
      url: '',
      headers: {
        Authorization: `Bearer ${auth.access_token}`,
      },
    };

    const resp = {
      results: [],
      ids: [],

    };

    try {
      dispatch({ type: LOADING_FILTERS_BY_PARAMS });
      for (let i = 0; i < params.length; i++) {
        for (let j = 0; j < params[i].params.length; j++) {
          if (params[i].params[j].type === 'string') config.url = `${cors}${back_end_data}/data/string-cat/${params[i].id}/${params[i].params[j].id}`;
          if (params[i].params[j].type === 'timestamp') config.url = `${cors}${back_end_data}/data/date-range/${params[i].id}/${params[i].params[j].id}`;
          if (params[i].params[j].id !== 'custom_var_one') {
            resp.results.push({
              name: params[i].params[j].name,
              type: params[i].params[j].type,
              id: params[i].params[j].id,
              data: setFormatAndDepuration(await (await axios(config)).data, params[i].params[j].type, authorities, params[i].params[j].id),
            });
          }
        }
      }
      dispatch({
        type: GET_FILTERS_BY_PARAMS_SUCCESS,
        payload: resp,
      });
      encodeCache('app-web-filters-by-params', { results: resp.results });
      encodeCache('app-web-filters-today', format(new Date(), 'dd-MM-yyyy'));
    } catch (error) {
      dispatch({
        type: GET_FILTERS_BY_PARAMS_ERROR,
      });
    }
  }
};

const setFormatAndDepuration = (res, type, auth, id) => {
  if (type === 'timestamp') {
    if (res[0] === 0) return [];
    return [res, res];
  }

  if (type === 'string') {
    const data = filterParamsByPermissions(id, auth, res);
    return data.map((item) => ({ key: item, active: true }));
  }
};

export const changeFilterCustomStringAction = (id, data) => (dispatch) => {
  const resp = decodeCache('app-web-filters-by-params');
  const index = resp.results.findIndex((item) => item.id === id);
  resp.results[index].data = data;

  dispatch({
    type: CHANGE_FILTER_CUSTOM_STRING_SUCCESS,
    payload: resp,
  });

  encodeCache('app-web-filters-by-params', resp);
};

export const changeFilterCustomDateAction = (id, data) => (dispatch) => {
  const resp = decodeCache('app-web-filters-by-params');
  const index = resp.results.findIndex((item) => item.id === id);
  const epoch = [parseInt((new Date(data[0]).getTime()) / 1000), parseInt((new Date(data[1]).getTime()) / 1000)];
  resp.results[index].data[0] = epoch;

  dispatch({
    type: CHANGE_FILTER_CUSTOM_DATE_SUCCESS,
    payload: resp,
  });

  encodeCache('app-web-filters-by-params', resp);
};
