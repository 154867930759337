/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import './ChartCustomDateComponent.css';
import { useSelector } from 'react-redux';
import Axios from 'axios';
import { Radio, RadioGroup } from '@hastom/react-radio-group';
import Circle from 'react-color/es/Circle';
import ChartAreaComponent from '../ChartAreaComponent/ChartAreaComponent';
import ChartLinecomponent from '../ChartLineComponent/ChartLineComponent';
import materialColors from '../../../helpers/charts/colors';
import chartProccess from '../../../helpers/charts/chartProccess';
import chartStadistics from '../../../helpers/charts/chartStadistics';
import LoadingComponent from '../LoadingComponent/LoadingComponent';
import encodeCache from '../../../helpers/cache/encode';
import userAuth from '../../../helpers/auth/userAuth';

const config = { method: 'get', url: '', headers: { Authorization: '' } };
const cors = process.env.REACT_APP_CORS;
const back_end_stats = process.env.REACT_APP_BACK_END_STATS;

const ChartCustomDateComponent = (props) => {
  const { name } = props.data;
  const { id } = props.data;
  const analisis = useSelector((store) => store.analizer.id);

  const [interval, setInterval] = useState('year');
  const [chart, setChart] = useState([]);
  const [type, setType] = useState('area');
  const [color, setColor] = useState('#FF9800');
  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useState(false);
  const [print, setPrint] = useState(true);
  const auth = userAuth();

  const load = async (x) => {
    setLoading(true);
    setInterval(x);
    config.url = `${cors}${back_end_stats}/wise-stats/date/${id}/${x}/${analisis}`;
    config.headers.Authorization = `Bearer ${auth.access_token}`;
    const res = await (await Axios(config)).data;
    const result = chartProccess(res);
    setChart(result);
    setLoading(false);
    const exporter = {
      id: `chart-2-${id}`, size: 'lg', data: chartStadistics(result, x, name), color, print, name,
    };
    encodeCache(`chart-2-${id}`, exporter);
  };

  useEffect(() => {
    if (!analisis) return;
    load('year');
  }, [analisis]);

  const changeType = (e) => setType(e);

  const changeColor = (e) => {
    setColor(e.hex);
    const exporter = {
      id: `chart-2-${id}`, size: 'lg', data: chartStadistics(chart, interval, name), color: e.hex, print, name,
    };
    encodeCache(`chart-2-${id}`, exporter);
  };

  const changeInterval = (e) => load(e);

  const inSpanish = (key) => {
    switch (key) {
      case 'day':
        return 'en días';
      case 'week':
        return 'en semanas';
      case 'month':
        return 'en meses';
      case 'year':
        return 'en años';
      default:
        return '';
    }
  };

  const getRangeTime = (key) => `${key[0].name} al ${key[key.length - 1].name}`;

  const changePrint = (e) => {
    setPrint(e);
    const exporter = {
      id: `chart-2-${id}`, size: 'lg', data: chart, color: '#2B364A', print: e, name,
    };
    encodeCache(`chart-2-${id}`, exporter);
  };

  return chart.length > 0 ? (
    <section id="chart-custom-date" className="chart-custom-date-size-lg">
      <LoadingComponent data={loading} />
      <div className="actions">
        <div className="interval">
          <RadioGroup selectedValue={interval} onChange={changeInterval}>
            <label>
              <Radio value="day" />
              <i className={interval === 'day' ? 'fas fa-calendar-day active' : 'fas fa-calendar-day'} />
            </label>
            <label>
              <Radio value="week" />
              <i className={interval === 'week' ? 'fas fa-calendar-week active' : 'fas fa-calendar-week'} />
            </label>
            <label>
              <Radio value="month" />
              <i className={interval === 'month' ? 'fas fa-calendar-alt active' : 'fas fa-calendar-alt'} />
            </label>
            <label>
              <Radio value="year" />
              <i className={interval === 'year' ? 'fas fa-calendar active' : 'fas fa-calendar'} />
            </label>
          </RadioGroup>
        </div>
        <div className="print">
          <RadioGroup selectedValue={print} onChange={changePrint}>
            <label>
              <Radio value={!print} />
              <i className={print === true ? 'ion-android-print active' : 'ion-android-print'} />
            </label>
          </RadioGroup>
        </div>
        <div className="type">
          <RadioGroup selectedValue={type} onChange={changeType}>
            <label>
              <Radio value="area" />
              <i className={type === 'area' ? 'fas fa-chart-area active' : 'fas fa-chart-area'} />
            </label>
            <label>
              <Radio value="line" />
              <i className={type === 'line' ? 'fas fa-chart-line active' : 'fas fa-chart-line'} />
            </label>
            <a onClick={() => setInfo(!info)} className={info ? 'view-info active' : 'view-info'}>
              <i className="ion-information-circled" />
            </a>
          </RadioGroup>
        </div>
      </div>
      <div id={`chart-2-${id}`}>
        <h3>
          {name}
          {' '}
          {inSpanish(interval)}
        </h3>
        <small>
          Periodo
          {getRangeTime(chart)}
        </small>
        {
                    type === 'area' && (<ChartAreaComponent data={chart} color={color} info={info} />)
                }
        {
                    type === 'line' && (<ChartLinecomponent data={chart} color={color} info={info} />)
                }
      </div>
      <Circle className="color-selector" colors={materialColors()} circleSize={14} onChangeComplete={changeColor} />
    </section>
  ) : null;
};

export default ChartCustomDateComponent;
